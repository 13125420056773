import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
//import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

import { hotjar } from 'react-hotjar';

import "./index.css";

import Analytics from "./Analytics";
import Config from "./Config";

import AdminDashboard from "./pages/AdminDashboard";
import Vehicles from "./pages/Vehicles";
import Instructions from "./pages/Instructions";
import Tips from "./pages/Tips";
import Support from "./pages/Support";
import Policies from "./pages/Policies";
import Book from "./pages/Book";
import Drivers from "./pages/Drivers";
import About from "./pages/About";
import HowItWorks from "./pages/HowItWorks";
import Confirm from "./pages/Confirm";
import App from "./pages/App";
import OwnerDashboard, { DemoOwnerDashboard } from "./pages/OwnerDashboard";
import ListRv from "./pages/ListRv";

// Default theme: https://material-ui.com/customization/default-theme/
// Chose: aquamarine and pear
// https://i.pinimg.com/originals/d5/95/09/d5950940d071867bcd0e6f118131a456.jpg
//
// https://material-ui.com/style/color/
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },

  palette: {
    primary: green,
    secondary: orange,
    error: red,

    // https://www.rapidtables.com/convert/color/hex-to-rgb.html
    text:  {
      //primary: "rgba(0, 105, 92, 1)",  // #00695C
      secondary: "rgba(120, 120, 120, 1)",
      //disabled: "rgba(255, 0, 0, 0.38)",
      //hint: "rgba(255, 0, 0, 0.38)" ,
    },

    background: {
      medium: "#999",
      white: "#fff",
      standardDouble: "#e0e4e8",
      standard: "#eaecf2",
      standardHalf: "#f4f4f6",
      error: "red",
      info: "#fffff4",
    }
  },
});

Analytics.init();

const hjid = 1129471;
const hjsv = 6;
hotjar.initialize(hjid, hjsv);

// https://tylermcginnis.com/react-router-pass-props-to-components/
// https://medium.com/@pshrmn/a-simple-react-router-v4-tutorial-7f23ff27adf
ReactDOM.render(
  <BrowserRouter>
    <Security issuer={Config.get("OKTA_ISSUER")}
              client_id={Config.get("OKTA_CLIENT_ID")}
              redirect_uri={window.location.origin + "/implicit/callback"}>
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route path="/vehicles" component={Vehicles}/>
          <Route path="/instructions" component={Instructions}/>
          <Route path="/tips" component={Tips}/>
          <Route path="/policies" component={Policies}/>
          <Route path="/support" component={Support}/>
          <Route path="/drivers" component={Drivers}/>
          <Route path="/how-it-works" component={HowItWorks}/>
          <Route path="/about" component={About}/>
          <Route path="/book" component={Book}/>
          <Route path="/confirm" component={Confirm}/>
          <Route path="/list-your-rv" component={ListRv}/>
          <Route path="/dashboard-demo" component={DemoOwnerDashboard}/>
          <SecureRoute path="/dashboard" component={OwnerDashboard}/>}
          <SecureRoute path="/admin" component={AdminDashboard}/>
          <Route path='/implicit/callback' component={ImplicitCallback}/>
          <Route path="/" component={App}/>
        </Switch>
      </MuiThemeProvider>
    </Security>
  </BrowserRouter>, document.getElementById("root"));

// Fuck caching for now
//registerServiceWorker();
