import React, { Component } from "react";

import queryString from "query-string";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import ioClient from "socket.io-client"

import HocUtils from "../hoc/HocUtils";

import Utils from "../common/Utils";

import Api from "../Api";
import Config from "../Config";

import Warning from "../components/Warning";

const styles = theme => ({
  mainApp: {
    maxWidth: 600,
    margin: 10,
  },
});

class Confirm extends Component {
  constructor(props) {
    super(props)

    let params = queryString.parse(this.props.location.search);
    let decodedParams = queryString.parse(Utils.deobfuscateParams(params.val));

    this.state = {
      waiting: decodedParams.error ? false : true,
      needsDrivers: decodedParams.needsDrivers,
      bookingCode: null,
      error: decodedParams.error,
      addMailMessageColor: "primary",
      addMailMessage: "",
      newEmail: "",
      addEmail: false,
      token: decodedParams.token,
      email: decodedParams.email,
    }
  }

  componentDidMount() {
    let io = ioClient(Config.get("SERVER_URL"));
    io.on(
        "checkout-token:" + this.state.token,
        data => {
          Utils.log("GOT DATA: ", data);
          this.setState({ waiting: false, error: data.error, bookingCode: data.bookingCode });
        }
      );
    Api.isPaymentConfirmed(this.state.token)
        .then(data => this.setState({waiting: false, bookingCode: data.bookingCode}))
        .catch(error => Utils.log("Got error: ", error));
  }

  successAddEmail() {
    this.setState({addMailMessageColor: "primary"});
    this.setState({addMailMessage: "Sent email copy to: " + this.state.newEmail});
    this.setState({newEmail: ""});
  };

  errorAddEmail() {
    this.setState({addMailMessageColor: "error"});
    this.setState({addMailMessage: "Server error updating email"});
  };

  handleNewEmailChange(event) {
    this.setState({newEmail: event.target.value});
  }

  handleSubmit(e) {
    let email = this.state.newEmail;
    if (Utils.isValidEmail(email)) {
      Api.addEmail(this.state.bookingCode, email)
          .then(() => {this.successAddEmail()})
          .catch(() => {this.errorAddEmail()});
    } else {
      if (email) {
        this.setState({addMailMessageColor: "error"});
        this.setState({addMailMessage: "Invalid email: " + email});
      }
    }
    e.preventDefault();
  }

  renderAddEmailForm() {
    return (<div>
      <Typography variant="subtitle1">
        Enter another email address
      </Typography>
      <form noValidate autoComplete="off" onSubmit={(e) => {this.handleSubmit(e)}}>
        <TextField style={{margin: 10}} value={this.state.newEmail}
        onChange={(event) => {this.handleNewEmailChange(event)}}>
        </TextField>
        <Button type="submit" variant="contained" style={{margin: 10}}>
          Send
        </Button>
      </form>
      <Typography variant="body1" color={this.state.addMailMessageColor}>
        {this.state.addMailMessage}
      </Typography>
    </div>);
  }

  renderWaiting() {
    return <div>
        <Typography variant="subtitle1">
          Waiting for payment...
        </Typography>
        <CircularProgress style={{margin:10}} />
      </div>;
  }

  renderError() {
    return (
        <Typography variant="subtitle1">
          Payment not successful. Error received during payment: {this.state.error}
        </Typography>);
  }

  renderComplete() {
    return (
        <div align="left">
          <Typography variant="subtitle1">
            Payment confirmed. A booking code was generated for your reservation:
          </Typography>
          <p/>
          <Typography variant="h6" align="center">
            {this.state.bookingCode}
          </Typography>
          <p/>
          <Typography variant="subtitle1">
            A confirmation email with booking details with be sent to:<br/>
          </Typography>
          <Typography variant="subtitle1" align="center">
            <em>{this.state.email}</em><br/>
          </Typography>
          <div align="center">
            <Button variant="outlined" style={{margin: 10}} onClick={() => {this.setState({addEmail: true})}}>
              Add another email
            </Button>
          </div>
          <br/>
          {this.state.addEmail ? this.renderAddEmailForm() : ""}

          {this.state.needsDrivers &&
            <div align="center">
              <Warning>
                <Typography variant="subtitle1" color="error">
                  <b>Additional Driver and Contact information is needed.</b>
                </Typography>

                <br/>

                <Button variant="contained" color="primary" style={{margin: 10}} onClick={() => {this.props.history.push("/book?bookingCode=" + this.state.bookingCode)}}>
                  Next
                </Button>
              </Warning>
            </div>}
        </div>
      );
  }

  render() {
    const { classes } = this.props;

    return <div align="center" style={{width: "100%"}}>
        <div className={classes.mainApp}>
          {this.state.waiting ? this.renderWaiting() : this.state.error ? this.renderError() : this.renderComplete()}
        </div>
      </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Confirm));

