import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

const styles = theme => ({
  warning: {
    border: 1,
    borderRadius: 10,
    borderStyle: "solid",
    borderColor: theme.palette.background.medium,

    display: "inline-block",

    padding: 20,
  },

  warningBackground: {
    backgroundColor: theme.palette.background.info,
  }

});

class Warning extends Component {
  render() {
    const { classes } = this.props;

    return <div className={classes.warning + " " + (this.props.quiet ? "" : classes.warningBackground)} style={{width:"100%"}}>
      {this.props.children}
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Warning));
