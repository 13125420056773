import React, { Component } from "react";

import queryString from "query-string";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";

import HocUtils from "../hoc/HocUtils";

import Order from "../common/Order";
import Booking from "../common/Booking";
import Utils from "../common/Utils";
import Place from "../common/Place";

import Api from "../Api";
import BookHelper from "../BookHelper";
import FrontendUtils from "../FrontendUtils";
import VehicleInfoContents from "../VehicleInfoContents";

import AddressSearch from "../components/AddressSearch";
import Checkout from "../components/Checkout";
import Calendar from "../components/Calendar";
import ClosableModal from "../components/ClosableModal";
import Contact from "../components/Contact";
import Drivers from "../components/Drivers";
import FreeCancel from "../components/FreeCancel";
import FlexibleImage from "../components/FlexibleImage";
import FlexibleGallery from "../components/FlexibleGallery";
import VehicleInfo from "../components/VehicleInfo";
import VehicleGallery from "../components/VehicleGallery";

import "typeface-roboto";

const styles = theme => ({
  container: {
    //background: theme.palette.background.standardHalf,
  },

  bg: {
    backgroundColor: theme.palette.background.standard,
  },

  addressRadioArea: {
    backgroundColor: theme.palette.background.standardHalf,
    //display: "flex",
  },

  leftArea: {
    maxWidth: 600,
    padding: 20,
    margin: Utils.isMobile() ? 0 : 20,
  },

  paymentArea: {
    border: Utils.isMobile() ? null : "1px solid #aaa",
    minWidth: 300,
    maxWidth: 450,
    padding: 20,
    margin: Utils.isMobile() ? 0 : 20,
    marginRight: Utils.isMobile() ? 0 : 50,
    backgroundColor: Utils.isMobile() ? null : theme.palette.background.standardHalf,
  },

  lineItem: {
    //position: "relative",
    alignItems: "start", 
    //alignItems: "center",
    //display: "flex",
    // Override List item padding.
    paddingLeft: 0,
  },

  flex: {
    display: "flex",
    alignItems: Utils.isMobile() ? "flext-start" : "center", 
    flexDirection: Utils.isMobile() ? "column" : "row",
  },

  flexHorizontal: {
    display: "flex",
    alignItems: "center", 
    flexDirection: "row",
  },

  flexFullLine: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  lineItemSwitch: {
    //position: "relative",
    alignItems: "center",
    display: "flex",
  },

  greyedOut: {
    opacity: 0.4,
  },

  cancelButton: {
    color: theme.palette.background.error,
  },

  captionTypography: {
    ...theme.typography.caption,
  },
});

class Book extends Component {
  constructor(props) {
    super(props);

    let queryParams = FrontendUtils.parseProps(props);

    let lookupPromise = null;

    if (queryParams.val) {
      let decodedParams = queryString.parse(Utils.deobfuscateParams(queryParams.val));

      Utils.log("Decoded params: ", decodedParams);
      let booking = new Booking({
        location: decodedParams.location,
        vehicleType: decodedParams.vehicle,
        startDate: decodedParams.start,
        endDate: decodedParams.end,
        info: {
          extras: [],
          drivers: [],
          place: Place.fromMime64(decodedParams.place),
          latitude: decodedParams.latitude,
          longitude: decodedParams.longitude,
        },
      });
      lookupPromise = Promise.resolve({booking, order: null});
    } else {
      lookupPromise = Api.lookupBooking(queryParams.bookingCode);
    }

    this.state = {
      token: null,
      changeVehicle: false,
      calendarFocusedInput: null,
      vehicleInfo: null,
      order: null,
      drivers: [],
      ageChecked: null,
      legalChecked: null,
      contactChecked: null,
      locationRadioValue: "now",
      changeErrorMessage: null,
      vehicleLocations: null,
      changeModal: false,
      isCancelModal: false,
      bookingCode: queryParams.bookingCode || null,

      initialized: false,
    };

    lookupPromise
        .then(result => this.initializeWithBooking(result.booking, result.order, result.drivers, queryParams.val ? null : result.booking, result.order))
        .catch(error => {
          Utils.log("Invalid booking code: ", error);
          this.setState({errorLine: "Invalid booking code: " + queryParams.bookingCode});
        });
  }

  isNewBooking() {
    return Boolean(!this.state.bookingCode);
  }

  featureEnabled(feature) {
    // Features valid for all states!
    switch (feature) {
      case "addUnlimitedMiles":
        // Show checkbox (might be disabled depending on changeXX field).
        return false;

      case "addLiability":
      case "addCdw":
      case "addRoadside":
        // Don't show checkbox -- I'm not allowed to sell insurance yet.
        return false;

      default:
        // Do nothing;
        break;
    }

    if (this.state.booking.isCanceled() || this.state.booking.isFinished() || this.state.booking.isReturned()) {
      // Disallow everything! Booking is no longer active.
      return false;
    }

    // Features only available in CONFIRMED / STARTED.
    switch (feature) {
      case "cancel":
      case "editContact":
      case "editDrivers":
      case "changeStartDate":
      case "changeVehicleType":
      case "changeUnlimitedMiles":
      case "changeRoadside":
      case "changeLiability":
      case "changeCdw":
      case "changeLocation":
        // Only allow when unstarted.
        return this.state.booking.isUpcoming();

      case "changeEndDate":
        // Allow after trip has started.
        return true;

      default:
        throw new Error("Invalid feature name: " + feature);
    }
  }

  showIfEnabled(feature) {
    return this.featureEnabled(feature) ? {"color": "inherit", "fontWeight": "bold"} : {"display": "none"};
    //return this.featureEnabled(feature) ? {} : {"display": "none"};
  }

  initializeWithBooking(booking, order, drivers, previousBooking, previousOrder) {
    // Utils.log("initializeWithBooking: ", booking, order, drivers, previousBooking, previousOrder);

    this.redoApiCalls(booking, order, previousBooking, previousOrder);

    this.setState({
      changeLocation: false,
      originalBooking: booking,
      booking,

      // TODO: Damn... use redux to solve this data update problem?
      // http://jamesknelson.com/which-flux-implementation-should-i-use-with-react/

      // TODO: These are not necessarily state.
      previousBooking,
      previousOrder,

      initialized: true,
      drivers,
    });
  }

  componentDidMount() {
    Utils.log("componentDidMount");
    FrontendUtils.scrollTop();

    Api.getCity().then(city => this.setState({city}));
  }

  componentDidUpdate() {
    Utils.log("componentDidUpdate", this.state.token);

    if (this.state.token) {
      let params = "token=" + this.state.token.id + "&email=" + this.state.token.email;
      if (this.state.tokenError) {
        params += "&error=" + this.state.tokenError;
      }
      if (!this.state.drivers || !this.state.drivers.length) {
        params += "&needsDrivers=true";
      }
      // TODO: DOES NOT REDIRECT Can't call setState (or forceUpdate) on an unmounted component.
      this.props.history.push("/confirm?val=" + Utils.obfuscateParams(params));
    }
  }

  // TODO: Take a booking param (this and searchVehicles too).
  redoApiCalls(booking, order, previousBooking, previousOrder) {
    const { location, vehicleType, startDate, endDate, bookingCode } = booking;

    // Location is null so that we can filter the location dropdown elements.
    Api.searchVehicles(
        null /* location */, vehicleType, startDate, endDate, bookingCode,
        vehicleInfos => {
          Utils.log("Retrieved vehicle infos: ", vehicleInfos);
          let decoratedVehicleInfo = VehicleInfoContents.decorateVehicleInfo(vehicleType, vehicleInfos[location][vehicleType]);
          Utils.log("Decorated vehicle info: ", decoratedVehicleInfo);
          // TODO: Get actual booking as param -- need to create that object!
          // TODO: This looks like a hack... create a real potential order.
          let orderInfo = vehicleInfos[location][vehicleType];
          if (booking.isCanceled()) {
            // Vehicle info might be fake -- use old one.
            orderInfo = order.info;
          }

          this.setState({
            vehicleInfo: decoratedVehicleInfo,
            order: new Order({info: orderInfo}, booking, previousOrder, previousBooking),
            ageChecked: false,
            legalChecked: false,
            contactChecked: false,
            changeErrorMessage: ""});

          let vehicleLocations = new Set();
          for (let location in vehicleInfos) {
            if (vehicleInfos[location].hasOwnProperty(vehicleType)) {
              vehicleLocations.add(location);
            }
          }
          //Utils.log("Locations: ", vehicleLocations);
          this.setState({vehicleLocations});
        });
  }

  rebuildOrder(booking) {
    // TODO: use clone?
    return new Order(this.state.order.deepCopy(), booking, this.state.previousOrder, this.state.previousBooking);
  }

  setBookingState(booking, redoApiCalls) {
    booking = new Booking(booking);
    let order = this.rebuildOrder(booking);
    this.setState({booking, order, ageChecked: false, legalChecked: false, contactChecked: false, changeErrorMessage: ""});
    if (redoApiCalls) {
      this.redoApiCalls(booking, null, this.state.previousBooking, this.state.previousOrder);
    }
  }

  revertChanges() {
    this.setBookingState(this.state.originalBooking, true /* redoApiCalls */);
  }

  handleChange(booking, penalties, stayOnPage) {
    let tokenId = "TOKEN_CHANGE_" + Utils.generateId(10);
    Api.change(booking, penalties, tokenId)
        .catch(error => this.setState({tokenError: "Could not change", changeModal: false}))
        //.then(() => Utils.sleep(300))
        .then(() => {
          if (stayOnPage) {
            window.location.reload();
            return;
          }
          this.setState({token: {id: tokenId, email: this.state.previousBooking.info.email}, changeModal: false});
        });
  }

  handleToggle(switchName) {
    let booking = this.state.booking.deepCopy();
    let extras = booking.info.extras;
    if (extras.includes(switchName)) {
      extras.splice(extras.indexOf(switchName), 1);
    } else {
      extras.push(switchName);
    }
    booking = new Booking(booking);
    this.setBookingState(booking, false /* redoApiCalls */);
  }

  renderCaptions(details, align, color="textSecondary") {
    var rows = [];
    for (let i in details) {
      rows.push(
        <Typography key={i} variant="body2" color={color} noWrap={true}>{details[i]}</Typography>
      );
    }
    return rows;
  }

  renderServiceName(serviceType) {
    let a =
      <Typography color="primary" variant="button">
        <span className={"link"} onClick={() => this.setState({changeVehicle: true})} style={this.showIfEnabled("changeVehicleType")}>Change</span>
      </Typography>;
    if (Utils.vehicleTypes().find(x => x === serviceType)) {
      return <span>{Utils.vehicleName(serviceType)} rental {a}</span>;
    } else {
      return serviceType;
    }
  }

  renderLine(serviceType, details, switchName, switchDetails, price, priceDetailsOff, priceDetailsOn, addFeatureName, changeFeatureName) {
    const { classes } = this.props;

    let switchOn = switchName ? this.state.booking.info.extras.includes(switchName) : true;

    switch (serviceType) {
      case "Bay Area Delivery":
        details = [];
        priceDetailsOff = "";
        break;
      case "$1 Million Liability Insurance":
        details = ["With $0 deductible"];
        priceDetailsOff = "";
        break;
      case "Collision Damage Waiver":
        details = ["With $1500 deductible"];
        priceDetailsOff = "";
        break;
      default:
        break;
    }

    if (addFeatureName && !this.featureEnabled(addFeatureName)) {
      return null;
    }

    return <ListItem className={classes.lineItem} dense={true}>
      <div>
        <div className={classes.lineItemText}>
          <Typography variant="subtitle1">{this.renderServiceName(serviceType)}</Typography>
          {this.renderCaptions(details, "left")}
        </div>
        {switchName && this.featureEnabled(addFeatureName)
          ? <div className={classes.lineItemSwitch} >
              <Checkbox
                color="primary"
                onChange={() => this.handleToggle(switchName)}
                disabled={!this.featureEnabled(changeFeatureName)}
                checked={switchOn}
              />
              <div>
                {this.renderCaptions(switchDetails, "left", "primary")}
              </div>
            </div>
          : null}
      </div>
      <ListItemSecondaryAction>
        <div style={{opacity: switchOn ? 1 : 0.3}}>
          <Typography variant="subtitle1" align="right">{Utils.prettyMoney(switchOn ? price : 0)}</Typography>
          <div align="right">{this.renderCaptions(switchOn ? priceDetailsOn : priceDetailsOff)}</div>
        </div>
      </ListItemSecondaryAction>
    </ListItem>;
  }

  focusStartDate() {
    this.setState({calendarFocusedInput: "startDate"});
  }

  focusEndDate() {
    this.setState({calendarFocusedInput: "endDate"});
  }

  changeDates(startDate, endDate) {
    // DO NOT MUTATE STATE OBJECT DIRECTLY!
    // https://stackoverflow.com/questions/29537299/react-how-do-i-update-state-item1-on-setstate-with-jsfiddle
    // DO 
    let booking = this.state.booking;
    if (startDate) {
      booking = Utils.updateImmutable(booking, {startDate});
    }
    if (endDate) {
      booking = Utils.updateImmutable(booking, {endDate});
    }
    booking = new Booking(booking);
    this.setBookingState(booking, false /* redoApiCalls */);

    if (startDate && endDate) {
      Utils.log("NEW DATES: ", Utils.date2param(startDate), Utils.date2param(endDate));
      this.redoApiCalls(new Booking(booking), null, this.state.previousBooking, this.state.previousOrder);
    }
  }

  changeLocation(location) {
    this.setState({changeLocation: false});
    let booking = Utils.updateImmutable(this.state.booking, {location});
    this.setBookingState(booking, true /* redoApiCalls */);
  }

  changeVehicle(vehicleInfo) {
    this.setState({vehicleInfo, changeVehicle: false});
    let booking = Utils.updateImmutable(this.state.booking, {vehicleType: vehicleInfo.vehicleType});
    this.setBookingState(booking, true /* redoApiCalls */);
  }

  isUnchanged() {
    return Utils.deepEquals(this.state.booking, this.state.originalBooking);
  }

  validate() {
    if (!(this.state.booking.info.place.isDeliverable || this.state.locationRadioValue === "later")) {
      // TODO: I don't need this for now, just using contactChecked.
      //this.setState({changeErrorMessage: "Must enter final delivery address, or select 'Enter it later'"});
      //return false;
    }

    if (!this.state.legalChecked) {
      this.setState({changeErrorMessage: "Must accept terms of service"});
      return false;
    }

    // TODO: I don't need these for now, it stands in the way to purchase.
    /*
    if (!this.state.contactChecked) {
      this.setState({changeErrorMessage: "Must accept contact confirmation"});
      return false;
    }

    if (!this.state.ageChecked) {
      this.setState({changeErrorMessage: "Must accept minimum driver age"});
      return false;
    }
    */

    return true;
  }

  renderCheckboxes() {
    const { classes } = this.props;
    const off = this.state.bookingCode && this.isUnchanged();

    return <div className={off ? classes.greyedOut : null} align="left">

      <div className={classes.flexHorizontal}>
        <Checkbox
            color="primary"
            checked={this.state.legalChecked}
            onChange={event => this.setState({legalChecked: event.target.checked})}
            disabled={off}
          />
        <Typography variant="body2" color="textSecondary">
          I have read and agree to the
          <b><a href="policies" style={{color:"inherit"}} target="_blank" rel="noopener noreferrer"> Booking Policies</a></b>,
          <b><a href="Terms.pdf" style={{color:"inherit"}} target="_blank" rel="noopener noreferrer"> Terms of Use</a></b>, and
          <b><a href="Privacy.pdf" style={{color:"inherit"}} target="_blank" rel="noopener noreferrer"> Privacy Policy</a></b>
        </Typography>
      </div>

      <div className={classes.flexHorizontal} style={{display:"none"}}>
        <Checkbox
            color="primary"
            checked={this.state.contactChecked}
            onChange={event => this.setState({contactChecked: event.target.checked})}
            disabled={off}
          />
        <Typography variant="body2" color="textSecondary">
          I agree to be contacted for Rental Agreement & delivery details.
        </Typography>
      </div>

      <div className={classes.flexHorizontal} style={{display:"none"}}>
        <Checkbox
            color="primary"
            checked={this.state.ageChecked}
            onChange={event => this.setState({ageChecked: event.target.checked})}
            disabled={off}
          />
        <Typography variant="body2" color="textSecondary">
          All drivers will be 25 years or older at start of trip.
        </Typography>
      </div>

    </div>;
  }

  renderLocation() {
    const { classes } = this.props;

    return <div>
            <Typography variant="body1" color="textSecondary" align="left">
              Location
            </Typography>

            <div align="right">
              <Typography variant="body2">
                {this.state.booking.info.place.address ? this.state.booking.info.place.address : "Enter later"}
              </Typography>
              <Typography variant="button" color="primary">
                <span className={"link"} onClick={() => this.setState({changeLocation: !this.state.changeLocation})}
                   style={this.showIfEnabled("changeLocation")}>
                  {this.state.changeLocation ? "Cancel" : "Change"}
                </span>
                {/*<a href={Utils.getLocationMapsUrl(this.state.booking.location)} target="_blank" rel="noopener noreferrer">
                  Open map
                </a>*/}
              </Typography>
            </div>
            <div>
              {(!this.featureEnabled("changeLocation") || this.state.booking.info.place.isDeliverable) 
                ? ""
                : <Typography variant="body1" color="error" align="right">
                    A complete address will be needed. This can be filled later (we will remind you)
                  </Typography>}

              {this.state.changeLocation
                ? <div>
                    <div className={classes.flex + " " + classes.addressRadioArea}>
                      <div className={classes.flexHorizontal}>
                        <Radio
                          disabled={!this.featureEnabled("changeLocation")}
                          color="primary"
                          checked={this.state.locationRadioValue === "now"}
                          onChange={(event) => this.setState({ locationRadioValue: event.target.value })}
                          value="now"
                          aria-label="B"
                        />
                        <Typography>
                          Enter delivery address &nbsp;
                        </Typography>
                      </div>
                      <div className={classes.flexHorizontal} style={{marginLeft:10}}>
                        <AddressSearch
                          isSmall
                          city={this.state.city}
                          disabled={!this.featureEnabled("changeLocation") || this.state.locationRadioValue !== "now"}
                          onAddressChange={(place) => this.setState({newPlace: place})} />
                        <Button style={{margin: 10}} variant="contained" color={"primary"} size="small"
                            disabled={
                              !this.featureEnabled("changeLocation") ||
                              (this.state.locationRadioValue === "now"
                                ? (!this.state.newPlace || this.state.newPlace.address === this.state.booking.info.place.address)
                                : (this.state.booking.info.place && !this.state.booking.info.place.address))}
                            onClick={() => {
                              this.setState({changeLocation: false});
                              this.setPlace(this.state.locationRadioValue === "later" ? {address: null} : this.state.newPlace);
                            }}>
                          Save
                        </Button>
                      </div>
                    </div>
                    <div className={classes.flexHorizontal + " " + classes.addressRadioArea}>
                      <Radio
                        disabled={!this.featureEnabled("changeLocation")}
                        color="primary"
                        checked={this.state.locationRadioValue === "later"}
                        onChange={(event) => this.setState({ locationRadioValue: event.target.value })}
                        value="later"
                        aria-label="B"
                      />
                      <Typography>
                        Enter it later
                      </Typography>
                    </div>
                  </div>
                : null}
            </div>
          </div>;
  }

  renderTitle(title) {
     return <div>
      <Typography variant="h6">
        {title}
      </Typography>
      <hr/>
    </div>;
  }

  renderBookingArea() {
    return <div>
      {this.renderTitle("Booking")}

      {this.state.booking.isCanceled()
        ? <Typography variant="h6" color="error" align="center" syle={{margin:10}}>
            BOOKING CANCELED
          </Typography>
        : null}
      <Typography variant="body1" color="textSecondary" align="left">
        Pick up
      </Typography>
      <div align="right">
        <Typography variant="body2">
          {Utils.prettyDate(this.state.booking.startDate)}, 2pm - 8pm
          <br/>
        </Typography>
        <Typography variant="button" color="primary">
          <span className={"link"} onClick={() => this.focusStartDate()} style={this.showIfEnabled("changeStartDate")}>Change</span>
        </Typography>
      </div>
      <br/>
      <Typography variant="body1" color="textSecondary" align="left">
        Drop off
      </Typography>
      <div align="right">
        <Calendar
            isSingleDay={true}
            isHidden={true}
            booking={this.state.booking}
            focusedInput={this.state.calendarFocusedInput}

            previousBookingCode={this.state.previousBooking ? this.state.previousBooking.bookingCode : null}

            onDatesChange={(startDate, endDate) => { this.changeDates(startDate, endDate) }}
            onFocusChange={calendarFocusedInput => this.setState({calendarFocusedInput})}
            />
        <Typography variant="body2">
          {Utils.prettyDate(this.state.booking.endDate)}, 2pm - 8pm
          <br/>
        </Typography>
        <Typography variant="button" color="primary">
          <span className={"link"} onClick={() => this.focusEndDate()} style={this.showIfEnabled("changeEndDate")}>Change</span>
        </Typography>
      </div>
      <br/>
      {this.renderLocation()}
    </div>;
  }

  renderInfoArea() {
    const { classes } = this.props;

    return <div>
      <br/>
      <VehicleInfo vehicleInfo={this.state.vehicleInfo} noPics={true} />

      <div className={classes.termsArea}>
        <hr />
        <br/>
        {BookHelper.termsAndConditions()}
      </div>
    </div>;
  }

  renderPaymentArea() {
    const penalties = this.computePenalty();

    return <div>
      {this.renderTitle("Payment")}

      <div>
        <ClosableModal
            title="Select Vehicle"
            open={this.state.changeVehicle}
            onClose={() => this.setState({changeVehicle: false})}>
          <VehicleGallery
              booking={this.state.booking}
              selectButtonText="SELECT"
              onSelect={vehicleInfo => this.changeVehicle(vehicleInfo)} />
        </ClosableModal>
        <List>
            {this.renderLine(
                this.state.vehicleInfo.vehicleName,
                this.printVehicleDetails()[0],
                null /* switchName */, null /* switchDetails */,
                this.state.order.vehiclePrice(),
                null, this.printVehicleDetails()[1])}

            {this.renderLine(
                "",
                [],
                //[this.state.order.info.pricing.extrasPrices.dailyFreeMiles + " miles/day & " +
                  //     Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.extraMilePrice) + " / extra mile"],
                "UNLIMITED_MILES",
                ["Add UNLIMITED miles", "for " + Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.dailyPriceUnlimitedMiles) + " per day"],
                this.state.order.computeMilesPrice(),
                [<br/>],
                [
                  Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.dailyPriceUnlimitedMiles) + " x " + this.state.order.numDays() + " days",
                ],
                 "addUnlimitedMiles", "changeUnlimitedMiles")}

            {/* TODO: line details should have switch on / off texts too}
            {this.renderLine(
                "Bay Area Delivery",
                //"Liability insurance",
                ["Basic liability coverage"],
                "LIABILITY",
                "", //["Buy EXTENDED", Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.dailyPriceLiability) + " per day"],
                0,
                "", //["Basic liability"],
                ["Extended liability"], "addLiability", "changeLiability")}
            {this.renderLine(
                "$1 Million Liability Insurance",
                //"Liability insurance",
                ["Basic liability coverage"],
                "LIABILITY",
                "", //["Buy EXTENDED", Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.dailyPriceLiability) + " per day"],
                this.state.order.computeLiabilityPrice(),
                "", //["Basic liability"],
                ["Extended liability"], "addLiability", "changeLiability")}
            {this.renderLine(
                "Collision Damage Waiver",
                //"Collision Damage Waiver",
                ["Basic CDW"],
                "CDW",
                ["Buy EXTENDED", Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.dailyPriceCdw) + " per day"],
                this.state.order.computeCdwPrice(),
                ["Basic CDW"], ["Extended CDW"], "addCdw", "changeCdw")}
            */}
            {this.renderLine(
                "",
                //"24/7 Roadside Assistance",
                //"Collision Damage Waiver",
                [],
                "ROADSIDE",
                ["Add 24/7 Roadside Assistance", "for " + Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.dailyPriceRoadside) + " per day"],
                this.state.order.computeRoadsidePrice(),
                [<br/>],
                [Utils.prettyMoney(this.state.order.info.pricing.extrasPrices.dailyPriceRoadside) + " x " + this.state.order.numDays() + " days"],
                "addRoadside", "changeRoadside")}
            {Book.computePenaltyPrice(penalties) > 0
              ? this.renderLine(
                "Late change penalty", Book.printPenalties(penalties)[0],
                null, null,
                Book.computePenaltyPrice(penalties),
                null, Book.printPenalties(penalties)[1]) : ""}
            {this.renderLine(
                "Service Fee", [Utils.rate2percent(Utils.taxRate(this.state.booking.location)) + " (sales tax included in price)"],
                null, null,
                this.state.order.computeTax(),
                null, null)}
            <hr style={{marginLeft:15}} />
            {this.renderLine(
                "Total", null,
                null, null,
                this.state.order.computeTotal(),
                null, null)}
            {this.state.order.getAlreadyPaid() ? this.renderRefundLines() : ""}
        </List>
      </div>
      <div>
        {this.renderCheckboxes()}
        <div style={{marginLeft:20, marginTop:10}}>
          <Typography variant="body1" color="error">
            {this.state.changeErrorMessage}
          </Typography>
        </div>
      </div>
      <div>
        <div align="center" style={{marginTop:10}}>
          {this.state.order.computeTotal() > this.state.order.getAlreadyPaid()
            ? <div>
                <Checkout
                  name={"Refresh Campers"}
                  description={this.state.vehicleInfo.vehicleName + " for " + this.state.booking.numDays() + " nights"}
                  amount={this.state.order.computeTotal() - this.state.order.getAlreadyPaid()}
                  booking={this.state.booking}
                  penalties = {penalties}
                  validator = {() => { return this.validate()}}
                  disabled = {this.state.bookingCode && this.isUnchanged()}
                  cb={(token) => { this.setState({token})}}
                />
                {this.state.order.getAlreadyPaid() ? null : <FreeCancel startDate={this.state.booking.startDate} />}
              </div>
            : ""}
          {this.state.order.computeTotal() < this.state.order.getAlreadyPaid()
            ? <div>
              {this.renderChangeModal()}
              <Button
                  variant="contained" color="primary" size="large"
                  disabled={this.state.bookingCode && this.isUnchanged()}
                  onClick={() => this.validate() && this.setState({changeModal: true, isCancelModal: false})}>
                Change
              </Button>
            </div>
            : ""}
          {this.state.order.computeTotal() === this.state.order.getAlreadyPaid()
            ? <div>
                <Button
                    variant="contained" color="primary" size="large"
                    disabled={this.state.bookingCode && this.isUnchanged()}
                    onClick={() => this.validate() && this.handleChange(this.state.booking, this.state.order.computeAllPenalties(), true /* stayOnPage */)}>
                  Change
                </Button>
              </div>
            : ""}
          <br/>
          <div>
            {this.state.bookingCode
                ? <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"flex-end"}}>
                    {this.renderCancelModal()}
                    <br/>
                    <br/>
                    <br/>
                    <Button onClick={() => this.revertChanges()} disabled={this.isUnchanged()} size="small">
                      Revert changes
                    </Button>
                    <Button onClick={() => this.setState({changeModal: true, isCancelModal: true})} disabled={!this.featureEnabled("cancel")} size="small">
                      Cancel booking
                    </Button>
                  </div>
                : null}
          </div>
        </div>
      </div>
    </div>;
  }

  setPlace(place) {
    let info = Utils.deepCopyJson(this.state.booking.info);
    info.place = place;
    let booking = Utils.updateImmutable(this.state.booking, {info});
    this.setBookingState(booking, false /* redoApiCalls */);
  }

  setContact(contact) {
    let info = Utils.deepCopyJson(this.state.booking.info);
    info.contact = contact;
    let booking = Utils.updateImmutable(this.state.booking, {info});
    this.setBookingState(booking, false /* redoApiCalls */);
  }

  static printPenalties(penalties) {
    return BookHelper.fillWithNewlines(BookHelper.printPenalties(penalties));
  }

  static computePenaltyPrice(penalties) {
    return penalties ? penalties.price : 0;
  }

  printVehicleDetails() {
    //const extrasPrices = this.state.order.info.pricing.extrasPrices;
    let res = BookHelper.printVehicleDetails(this.state.booking, this.state.order);
    res = BookHelper.fillWithNewlines(
        [res[0].concat([
            "- Unlimited Miles",
            "- Liability, Comprehensive & Collision Insurance",
            "- Free Delivery to your home, hotel, airport, etc.",
            "- Camping & kitchen gear for two",
            //"- " + extrasPrices.dailyFreeMiles + " miles / day (" + Utils.prettyMoney(extrasPrices.extraMilePrice) + " / extra mile)",
          ]),
         res[1]]);
    return [res[0], [...res[1]]];
  }

  computePenalty() {
    return Book.computePenaltyImpl(
         this.state.booking, this.state.order, this.state.previousBooking, this.state.previousOrder, false /* excludePrevious */);
  }

  static computePenaltyImpl(booking, order, previousBooking, previousOrder, excludePrevious) {
    return Order.createPenaltyOrderItem(
         booking, order, previousBooking, previousOrder, excludePrevious);
  }

  renderCancelModal() {
    let booking = this.state.booking.clone();
    //booking.startDate = Utils.param2date("2038-01-01");
    //booking.endDate = Utils.param2date("2038-01-01");
    booking.info.state = "CANCELED";
    let order = this.rebuildOrder(booking);

    return this.renderChangeModalImpl(booking, order, true /* isCancel */);
  }

  renderChangeModal() {
    return this.renderChangeModalImpl(this.state.booking, this.state.order, false /* isCancel */);
  }

  renderFlexFullLine(left, right, typographyVariant, color) {
    const { classes } = this.props;

    return <div className={classes.flexFullLine}>
      <Typography variant={typographyVariant} color={color}>
        {left}
      </Typography>
      <Typography variant={typographyVariant} color={color}>
        {right}
      </Typography>
    </div>;
  }

  renderChangeModalImpl(booking, order, isCancel) {
    const { classes } = this.props;

    let total = order.computeTotal();
    let alreadyPaid = order.getAlreadyPaid();
    let refund = alreadyPaid - total;

    let allPenalties = order.computeAllPenalties();
    let currentPenalties = order.computeCurrentPenalties();
    let currentPenaltiesPrice = order.computeCurrentPenaltiesPrice();
    let currentPenaltiesTax = order.computeCurrentPenaltiesTax();
    let currentPenaltiesTotal = currentPenaltiesPrice + currentPenaltiesTax;

    return <ClosableModal
        title={"Confirm " + (isCancel ? "cancel" : "change")}
        open={this.state.changeModal && this.state.isCancelModal === isCancel}
        onClose={() => this.setState({changeModal: false})}>
      <div style={{padding:25}}>
        {this.renderFlexFullLine("Already paid:", Utils.prettyMoney(alreadyPaid), "subtitle1")}
        {this.renderFlexFullLine("New total:", Utils.prettyMoney(total), "subtitle1")}
        {currentPenaltiesPrice > 0
          ? <div align="left">
              <br/>
              <hr/>
              {this.renderFlexFullLine("Late change penalty:", Utils.prettyMoney(currentPenaltiesTotal), "subtitle1")}
              <br/>
              {this.renderFlexFullLine("Penalties before tax:", Utils.prettyMoney(currentPenaltiesPrice), "body1", "textSecondary")}
              {this.renderCaptions(BookHelper.printPenalties(currentPenalties)[0])}
              {this.renderFlexFullLine("Penalties tax:", Utils.prettyMoney(currentPenaltiesTax), "body1", "textSecondary")}
            </div>
          : ""}
        <br/>
        <hr/>
        {this.renderFlexFullLine("Amount to " + (refund === 0 ? "pay" : "refund") + ":", Utils.prettyMoney(refund), "subtitle1")}
        <br/>
        <Button variant="outlined" style={{margin:10}} onClick={() => this.setState({changeModal: false})}>
          Back
        </Button>
        {isCancel
          ? <Button variant="outlined" color="inherit" style={{margin: 10}} className={classes.cancelButton}
                    onClick={() => this.handleChange(booking, allPenalties)}>Yes,&nbsp;Cancel</Button>
          : <Button variant="outlined" color="primary" style={{margin: 10}}
                    onClick={() => this.handleChange(booking, allPenalties)}>Change</Button>}
      </div>
    </ClosableModal>;
  }

  renderRefundLines() {
    return (
      <div>
        {this.renderLine(
            "Already paid", null,
            null, null,
            this.state.order.getAlreadyPaid(),
            null, null)}
        {this.state.order.computeTotal() >= this.state.order.getAlreadyPaid()
          ? this.renderLine(
                this.state.booking.isCanceled() ? "Total refund" : "Total remaining", null,
                null, null,
                this.state.order.computeTotal() - this.state.order.getAlreadyPaid(),
                null, null)
          : <div>
             {this.renderLine(
                "Total refund", null,
                null, null,
                this.state.order.getAlreadyPaid() - this.state.order.computeTotal(),
                null, null)}
              </div>
        }
      </div>);
  }

  render() {
    const { classes } = this.props;

    if (this.state.errorLine) {
      return <Typography color="error" variant="subtitle1">
        <br/>
        <br/>
        {this.state.errorLine}
        <br/>
        <br/>
      </Typography>;
    }

    if (!this.state.vehicleInfo || !this.state.initialized) {
      return <CircularProgress />;
    }

    //Utils.log("PROPS: ", this.props);
    //Utils.log("STATE: ", this.state);

    return <div className={classes.container} align="center">
          <FlexibleGallery vehicleInfo={this.state.vehicleInfo} bigFirst={this.state.bookingCode || Utils.isMobile() ? false : true} />

          {this.state.bookingCode &&
              <div style={{margin:10, width:Utils.isMobile() ? "calc(100% - 20px)" : 500}} align="center">
                <Drivers bookingCode={this.state.bookingCode} drivers={this.state.drivers} user={this.props.user}
                    onChange={drivers => this.setState({drivers})} editable={this.featureEnabled("editDrivers")} />
                <div style={{margin:10}} />
                <Contact bookingCode={this.state.bookingCode} contact={this.state.booking.info.contact}
                    onChange={contact => this.setState({contact})} editable={this.featureEnabled("editContact")} />
              </div>}

          <div style={{display: "flex", flexDirection: Utils.isMobile() ? "column" : "row", justifyContent: "center"}} align="left">
            <div className={classes.leftArea}>
              {this.renderBookingArea()}
              {Utils.isMobile() ? null : this.renderInfoArea()}
            </div>
            <div>
              <div className={classes.paymentArea}>
                {this.renderPaymentArea()}
              </div>
            </div>
            {Utils.isMobile()
              ? <div className={classes.leftArea + " " + classes.bg}>
                  {this.renderInfoArea()}
                </div>
              : null}
          </div>
          <br/>
          <FlexibleImage desktopImg="/img/halfdome-1920x700.jpg" mobileImg="/img/halfdome-500x450.jpg" alt="Half Dome" />
          {/*<FlexibleGallery vehicleInfo={this.state.vehicleInfo} bigFirst={false} />*/}
      </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Book));

