import Utils from "./Utils";

export default class Driver {
  static fromJson(json) {
    return Utils.deepCopyJson(json);
  }

  toMime64() {
    return Utils.obfuscateParams(JSON.stringify(this.toJson()));
  }

  static fromMime64(str) {
    return new Driver(Driver.fromJson(JSON.parse(Utils.deobfuscateParams(str))));
  }

  toJson() {
    return Utils.deepCopyJson(this);
  }

  constructor(json, bookingCode) {
    // DL and Insurance info.
    Object.assign(this, Driver.fromJson(json));
    this.bookingCode = bookingCode;
  }
};
