import React, { Component } from "react";

import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MapIcon from "@material-ui/icons/Map";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TodayIcon from "@material-ui/icons/Today";
import StarIcon from "@material-ui/icons/Star";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import SendIcon from "@material-ui/icons/Send";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentIcon from "@material-ui/icons/Assignment";
import InfoIcon from "@material-ui/icons/Info";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CloseIcon from "@material-ui/icons/Close";
import RvHookupIcon from "@material-ui/icons/RvHookup";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import Config from "../Config";
import FrontendUtils from "../FrontendUtils";

import LoginButton from "./LoginButton";
import Footer from "./Footer";
import VehicleSelector from "./VehicleSelector";

import "typeface-roboto";

const styles = theme => ({
  app: {
    alignItems: "center",  // Center vertically 
    justifyContent: "center",
    textAlign: "center",
    //maxWidth: 960,
  },

  appBar: {
    background: theme.palette.background.standardHalf,
    //background: "white",
    width: "100%",
    zIndex: "auto",
    position: "relative",
  },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    //textAlign: "right",
    //justifyContent: "right",
  },

  logo: {
    display: "flex",
    alignItems: "center",
    marginRight: Utils.isMobile() ? 0 : 50,
  },

  toolbarItems: {
    display: "flex",
    justifyContent: Utils.isMobile() ? "flex-end" : "space-around",
    alignItems: "center",
    width: "100%",
  },

  toolbarItem: {
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
  },

  selectedToolbarItem: {
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    //backgroundColor: theme.palette.background.standardHalf,
  },

  icon: {
    display: "none", // Remove for now.
    maxWidth: Utils.isMobile() ? 0 : 40,
    maxHeight: Utils.isMobile() ? 0 : 40,
    borderRadius: "50%",
    marginRight: 5,
  },

  titleImg: {
    height: Utils.isMobile() ? 46 : 40,
    marginRight: 15,
    marginLeft: 15,
    marginTop: 10,
    marginBottom: 5,
  },

  drawerFrame: {
    position: "relative",
  },

  drawerHeader: {
    width: "100%",
    marginRight: 20,
    display: "flex",
    //justifyContent: "flex-start",
    justifyContent: "space-between",
    alignItems: "center",
    // Min size in lib: 56 mobile / 64 desktop.
    height: Utils.isMobile() ? 64 : 64,
  },
});

function plate(vehicle) {
  if (!vehicle) {
    return null;
  }
  return vehicle.plate();
}

class Header extends Component {
  constructor(props) {
    super(props);

    this.previousHistoryLength = 0;

    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      selectedVehiclePlate: plate(props.orm ? (props.orm.vehicles.length ? props.orm.vehicles[0] : null) : null),
    };
  }

  toggleDrawer(open) {
    this.setState({ drawerOpen: open });
  }

  renderMenuItem(location, dashColor) {
    if (!location) {
      return null;
    }

    return (
        <ListItem button onClick={() => this.redirect(location)}>
          <ListItemIcon>{this.getIconFromLocation(location)}</ListItemIcon>
              <ListItemText color="secondary" primary={<span style={dashColor ? {color:"#ff9900"} : {color:"#555555"}}>{this.getTitleFromLocation(location)}</span>} />
        </ListItem>);
  }

  componentDidMount() {
    return this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { orm } = this.props;

    if (orm) {
      const queryParams = FrontendUtils.parseProps(this.props);
      if (queryParams.trip) {
        const bookingCode = Utils.unscramble(queryParams.trip);
        const booking = orm.bookings.find(x => x.bookingCode === bookingCode);
        if (plate(booking.info.vehicle) !== this.state.selectedVehiclePlate) {
          this.setState({selectedVehiclePlate: plate(booking.info.vehicle)});
        }
      } else if (queryParams.vehicle) {
        const selectedVehiclePlate = plate(orm.vehicles.find(x => plate(x) === queryParams.vehicle));
        if (selectedVehiclePlate !== this.state.selectedVehiclePlate) {
          this.setState({selectedVehiclePlate});
        }
      } else if (orm.vehicles.length && !this.state.selectedVehiclePlate) {
        this.setState({selectedVehiclePlate: plate(orm.vehicles[0])});
      }
    }
  }

  renderToolbarItem(location, isButton, isHidden) {
    if (!location) {
      return null;
    }

    const isHere = this.isHere(location);
    const isBook = this.props.history.location.pathname === "/";
    return <div style={(isHidden || (isBook && isHere)) ? {visibility:"hidden"} : {}}
                className={isHere ? this.props.classes.selectedToolbarItem : this.props.classes.toolbarItem}>
      {isButton
        ? <Button size="small" variant="outlined" color={this.isDash(location) ? "secondary" : "primary"} disabled={isHere} onClick={() => this.redirect(location)}>
            {this.getTitleFromLocation(location).toUpperCase()}
          </Button>
        : <div style={{display:"flex", alignItems: "center"}}>
            {this.isDash() && !Utils.isMediumScreen() && !Utils.isMobile() &&
                <div style={{marginRight: 10}}>{this.getIconFromLocation(location)}</div>}
            <Typography variant="body2" color={this.isDash(location) ? "secondary" : (isHere ? "primary" : "textSecondary")}>
              <Link to={location}>{this.getTitleFromLocation(location).toUpperCase()}</Link>
            </Typography>
          </div>}
    </div>;
  }

  getIconFromLocation(location) {
    switch (location) {
      case "/":
        return <TodayIcon />;
      case "/how-it-works":
        return <SendIcon/>;
      case "/policies":
        return <AssignmentIcon/>;
      case "/support":
        return <QuestionAnswerIcon/>;
      case "/list-your-rv":
        return <RvHookupIcon/>;
      case "/dashboard":
      case "/dashboard-demo":
        return <DashboardIcon color="secondary" />
      case "/dashboard?type=trip":
      case "/dashboard-demo?type=trip":
        return <MapIcon color="secondary" />;
      case "/dashboard?type=calendar":
      case "/dashboard-demo?type=calendar":
        return <TodayIcon color="secondary" />;

      case "/about":
        return <BusinessIcon/>;
      case "/vehicles":
        return <AirportShuttleIcon/>;
      case "/instructions":
        return <InfoIcon/>;
      case "/tips":
        return <StarIcon/>;

      default:
        return null;
    }
  }

  getTitleFromLocation(location) {
    switch (location) {
      case "/": return "Book Now";
      case "/how-it-works": return "How It Works";
      case "/policies": return "Policies";
      case "/support": return "Support";
      case "/list-your-rv": return "List Your RV";
      case "/dashboard": return "Dashboard";
      case "/dashboard-demo": return "Dashboard";
      case "/dashboard?type=trip": return "Current Trip";
      case "/dashboard-demo?type=trip": return "Current Trip";
      case "/dashboard?type=calendar": return "Calendar";
      case "/dashboard-demo?type=calendar": return "Calendar";

      case "/about": return "About Us";
      case "/vehicles": return "Our Vans";
      case "/instructions": return "Instructions";
      case "/tips": return "Travel Tips";
      default: Utils.error("Location not found: " + location);
    }
  }

  getLocation() {
    return this.props.history.location.pathname;
  }

  isDash(location) {
    if (!location) {
      location = this.getLocation();
    }
    return location.startsWith(this.props.dashboardLink);
  }

  isHere(location) {
    return this.getLocation() === location || this.getLocation() + this.props.history.location.search === location;
  }

  redirect(path, vehicle) {
    this.toggleDrawer(false);
    if (path !== this.props.history.location.pathname) {
      this.props.history.push(path);
    }
  }

  render() {
    const { classes, orm } = this.props;

    // Utils.log("PROPS: ", this.props);

    // https://stackoverflow.com/questions/37712038/material-ui-change-the-height-of-the-drawer
    // https://stackoverflow.com/questions/36651583/dynamically-add-child-components-in-react

    return (
      <div align="center">
        <div className={classes.app}> {/* Center entire app in page */}
          <div>
            <AppBar className={classes.appBar} elevation={this.isDash() ? 1 : 0} style={{background: this.isDash() ? null : "white"}}>
              <Toolbar className={classes.toolbar}>
                <div className={classes.logo}>
                  <IconButton color="inherit" style={{height:48}} aria-label="Menu" onClick={() => this.toggleDrawer(true)}>
                    <MenuIcon color="action" />
                  </IconButton>
                  <Link to={this.isDash() ? this.props.dashboardLink : "/"}>
                    {this.isDash()
                      ? <img src={Utils.isMobile() ? "/img/title-logo-mobile-dash.png" : "/img/title-logo-dash.png"} className={classes.titleImg} alt="Refresh Campers Dash" />
                      : <img src={Utils.isMobile() ? "/img/title-logo-mobile.png" : "/img/title-logo.png"} className={classes.titleImg} alt="Refresh Campers" />}
                  </Link>
                </div>
                {this.renderToolbarItems()}
                <Link to="/">
                  <img src={"/img/favicon3-128x128.png"} className={classes.icon} alt="Refresh Campers Icon"/>
                </Link>
                {orm
                  ? <VehicleSelector selectedVehicle={orm.vehicles.find(x => plate(x) === this.state.selectedVehiclePlate)} autoSelect={true}
                        vehicles={orm.vehicles}
                        onSelect={selectedVehicle => this.setState(
                            {selectedVehiclePlate: plate(selectedVehicle)},
                            () => this.redirect(this.props.dashboardLink + "?vehicle=" + plate(selectedVehicle)))} />
                  : null}
              </Toolbar>
            {(Utils.isMobile() && this.isDash())
              ? <div style={{margin:10, display:"flex", justifyContent:"space-around"}}>
                  {this.renderToolbarItem(this.props.dashboardLink + "?type=trip")}
                  {this.renderToolbarItem(this.props.dashboardLink + "?type=calendar")}
                </div>
              : null}
            </AppBar>
          </div>
          <div className={classes.drawerFrame}>
            <Drawer variant="temporary" open={this.state.drawerOpen}
                    onClose={() => this.toggleDrawer(false)}>
              <Toolbar>
                <div className={classes.drawerHeader}>
                  <IconButton onClick={() => this.toggleDrawer(false)}>
                    {<CloseIcon />}
                  </IconButton>
                  <Typography variant="body2" color="textSecondary" align="right">
                    {this.props.user ? "Hello, " + this.props.user.given_name : null}
                  </Typography>
                </div>
              </Toolbar>
              {this.renderMenuItems()}
            </Drawer>
            {React.cloneElement(this.props.children, {vehiclePlate: this.state.selectedVehiclePlate})}
          </div>
          <Footer />
        </div>
      </div>);
  }

  renderToolbarItems() {
    const { classes } = this.props;

    if (this.isDash()) {
      return <div className={classes.toolbarItems} style={{maxWidth: 500}}>
        {this.renderToolbarItem(Utils.isMobile() ? null : this.props.dashboardLink + "?type=trip")}
        {this.renderToolbarItem(Utils.isMobile() ? null : this.props.dashboardLink + "?type=calendar")}
        {this.renderToolbarItem(this.props.dashboardLink === "/dashboard-demo" ? "/list-your-rv" : null, true)}
      </div>;
    } else {
      return <div className={classes.toolbarItems}>
        {this.renderToolbarItem(Utils.isMobile() ? null : "/")}
        {this.renderToolbarItem(Utils.isMobile() ? null : "/vehicles")}
        {this.renderToolbarItem(Utils.isMobile() ? null : "/how-it-works")}
        {this.renderToolbarItem(this.props.user ? this.props.dashboardLink : Config.get("OWNER_MARKETING_MODE") ? "/list-your-rv" : null, true)}
      </div>;
    }
  }

  renderMenuItems() {
    if (this.props.user) {
      return <div>
        <Divider />
        <List>
          {this.renderMenuItem(this.props.dashboardLink + "?type=trip", true)}
          {this.renderMenuItem(this.props.dashboardLink + "?type=calendar", true)}
          {this.renderMenuItem("/list-your-rv")}
        </List>
        <Divider />
        <List>
          {this.renderMenuItem("/")}
          {this.renderMenuItem("/how-it-works")}
          {this.renderMenuItem("/vehicles")}
        </List>
        <Divider />
        <List>
          {this.renderMenuItem("/policies")}
          {this.renderMenuItem("/about")}
          <LoginButton loginRedirect={this.props.dashboardLink} logoutRedirect={"/"} />
        </List>
      </div>;
    } else {
      return <div>
        <Divider />
        <List>
          {this.renderMenuItem("/")}
          {this.renderMenuItem("/how-it-works")}
          {this.renderMenuItem("/vehicles")}
        </List>
        <Divider />
        <List>
          {this.renderMenuItem("/policies")}
          {this.renderMenuItem("/about")}
          {Config.get("OWNER_MARKETING_MODE") && this.renderMenuItem("/list-your-rv")}
          <LoginButton loginRedirect={this.props.dashboardLink} logoutRedirect={"/"} />
        </List>
      </div>;
    }
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Header));
