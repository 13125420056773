import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import HocUtils from "../hoc/HocUtils";

import VehicleInfoContents from "../VehicleInfoContents";

import VehicleInfo from "../components/VehicleInfo";
import BookNow from "../components/BookNow";

const styles = theme => ({
  mainArea: {
    background: theme.palette.background.standard,
    margin: 0,
  },

  vehicleArea: {
    // https://www.w3schools.com/css/css3_flexbox.asp#flex-wrap
    justifyContent: "center",
    flexDirection: "column",
    //alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
    paddingTop: 30,
  },

  vehiclePaper: {
    //padding: 20,
    //marginTop: 10,
    marginBottom: 20,
    //padding: 10,
    //margin: 10,
  },
});

class Vehicles extends Component {

  constructor(props) {
    super(props);

    this.state = {
      vehicles: null,
      activeStep: 0,
    };
  }

  componentDidMount() {
    this.setState({vehicles: VehicleInfoContents.availableVehicleInfos()});
  }

  handleNext(maxSteps) {
    this.setState({ activeStep: (this.state.activeStep + 1) % maxSteps });
  };

  handleBack(maxSteps) {
    this.setState({ activeStep: (this.state.activeStep + maxSteps - 1) % maxSteps });
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { classes } = this.props;

    return (
        <div align="center" className={classes.mainArea}>
          {/*<FlexibleImage desktopImg={"/img/forest-1920x800.jpg"} mobileImg={"/img/forest-500x500.jpg"} alt="Van in forest" />*/}
          <div className={classes.vehicleArea}>
            {this.state.vehicles ? this.state.vehicles.map((x, i) =>
                <div className={classes.vehiclePaper} key={i}>
                  <VehicleInfo vehicleInfo={x} skipLine bigTitle />
                  <BookNow />
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  <hr/>
                </div>) : ""}
          </div>
        </div>);
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Vehicles));

