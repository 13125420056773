import React from "react";

import { withStyles } from "@material-ui/core/styles";

import { compose, withProps } from "recompose"
import { Polyline, withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import Utils from "../common/Utils";
import FrontendUtils from "../FrontendUtils";

import Config from "../Config";

const MIN_WIDTH_LEFT = 290;

function computeWidth() {
  if (Utils.isMobile()) {
    return window.innerWidth;
  }

  if (window.innerWidth < MIN_WIDTH_LEFT + 40) {
    return 0;
  }

  return window.innerWidth - MIN_WIDTH_LEFT - 40;
}

function aspectRatio() {
  return Utils.isMobile() ? 1 : FrontendUtils.aspectRatio();
}

function style() {
  return {
    width: "100%",
    height: Math.floor(computeWidth() / aspectRatio()),
  };
}

function getCenterAndDiameter(coords) {
  let minLat = 9999;
  let maxLat = -9999;
  let minLng = 9999;
  let maxLng = -9999;

  let bounds = new window.google.maps.LatLngBounds();

  for (let coord of coords) {
    minLat = Math.min(coord.lat, minLat);
    maxLat = Math.max(coord.lat, maxLat);
    minLng = Math.min(coord.lng, minLng);
    maxLng = Math.max(coord.lng, maxLng);
    bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
  }

  const avgLat = (minLat + maxLat) / 2;
  const avgLng = (minLng + maxLng) / 2;
  const deltaLat = maxLat - minLat;
  const deltaLng = maxLng - minLng;

  return [{lat: avgLat, lng: avgLng}, Math.max(deltaLat, deltaLng) / 180, bounds];
}

function radiansToZoom(diameterRadians) {
  // https://stackoverflow.com/questions/9356724/google-map-api-zoom-range
  // const metersPerPixel = 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);

  // The map is a power of 2 starting with the circumference of earth at equator equals something like 300 pixels.
  // +-0/1/2 depending on the pixel size of the map.
  const zoom = Math.round(Math.log(computeWidth()) / Math.log(200) - Math.log(diameterRadians) / Math.log(2));
  return Math.max(Math.min(zoom, 20), 1);
}

function someEventHandler(event) {
}

function drawSegment(coords, strokeColor, strokeWeight) {
  if (!coords.length) {
    return null;
  }

  return <Polyline path={coords} defaultOptions={{strokeColor, strokeWeight}} onClick={event => someEventHandler(event)} />
}

const styles = theme => ({
});

// Needs to be outside component!
// https://github.com/tomchentw/react-google-maps/issues/220#issuecomment-319269122
const MyMapComponent = compose(
  withProps({
    // v 3.33 is the last one without huge, ugly icons: https://issuetracker.google.com/issues/112520084
    googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.33&key=" + Config.get("GOOGLE_MAPS_API_KEY") + "&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    zoom={props.zoom}
    center={props.center}
  >
    {props.isMarkerShown && <Marker position={props.position} onClick={props.onMarkerClick} />}
    {props.segments.map(x => drawSegment(x.coords, x.strokeColor, x.strokeWeight))}
  </GoogleMap>);


export class LocationMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: {
        lat: 37.778519,
        lng: -122.405640,
      },
      selectedPlace: {
        name: "Initial place",
      },
      isMarkerShown: true,
    };
  }

  render() {
    const { coords, segments } = this.props;
    const center = (coords && coords.length) ? getCenterAndDiameter(coords)[0] : this.state.position;
    // Because react-google-maps doesn't have fitBounds, I compute the zoom exponent (actually Map has it but my zoom function has more control).
    // https://github.com/tomchentw/react-google-maps/issues/305
    //const bounds = getCenterAndDiameter(coords)[2];
    const defaultFocus = (coords && coords.length) ? coords[coords.length - 1] : center;
    let zoom = (coords && coords.length) ? radiansToZoom(getCenterAndDiameter(coords)[1]) : 8;
    if (this.props.focusedCoords) {
      zoom += 2;
    }

    // Map class: HAS BOUNDS!
    // https://github.com/fullstackreact/google-maps-react/blob/71d19a5b9b74d4330fb7d1b8e3b8d8ab94647ff7/src/index.js
  return <div style={style()}>
    <MyMapComponent
        center={this.props.focusedCoords ? this.props.focusedCoords : center}
        position={this.props.focusedCoords ? this.props.focusedCoords : defaultFocus}
        zoom={zoom}
        segments={segments}
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
      />
        {/*<InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
        </InfoWindow>*/}
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(LocationMap);

