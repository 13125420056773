import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ClosableModal from "./ClosableModal";
import ContactEditor from "./ContactEditor";
import Warning from "./Warning";

import Api from "../Api";

// import Utils from "../common/Utils";

const styles = theme => ({
});

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: this.props.contact,
      editContact: false,
    };
  }

  handleDone(contact) {
    Api.setBookingContact(this.props.bookingCode, contact);
    this.setState({contact, editContact: false});
  }

  render() {
    const required = !this.state.contact ||
        !this.state.contact.phone ||
        !this.state.contact.destination ||
        !this.state.contact.emergencyName ||
        !this.state.contact.emergencyPhone;

    return <div style={{width:"100%"}}>
      <Warning quiet={!required}>
        {required
          ? <div>
              <Typography variant="subtitle1" color="error">
                <b>Additional contact information is required</b>
              </Typography>
            </div>
          : <div>
              <Typography variant="subtitle1">
                Contact information was set
              </Typography>
            </div>}

        {this.props.editable &&
            <div>
              <br/>
              <Button variant="contained" color="primary" size="small" onClick={() => this.setState({editContact: true})}>
                Update
              </Button>
            </div>}

        <ClosableModal title="Edit Contact" open={this.state.editContact} onClose={() => this.setState({editContact: false})}>
          <ContactEditor
              contact={this.state.contact}
              onDone={contact => this.handleDone(contact)} />
        </ClosableModal>
      </Warning>
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(Contact);
