import React from "react";

import OrmPool from "../common/OrmPool";
import Utils from "../common/Utils";

function generateGpsEvents(bookingCode, firstId, fromMiles, toMiles, fromDate, toDate, coords) {
  return coords.map((x, i) => {
    const deltaMiles = (toMiles - fromMiles) / coords.length;
    const deltaMinutes = toDate.diff(fromDate, "minutes") / coords.length;

    return {
      created: Utils.time2param(fromDate.clone().add(deltaMinutes * i, "minutes")),
      id: firstId + i,
      vehicle_id: 1,
      info: {
        type: "GPS",
        bookingCode,
        odometer: fromMiles + deltaMiles * i,
        coords: x,
      },
    };
  });
}

export default function withOwnerOrm(WrappedComponent) {
  return class extends React.Component {
    render() {
      if (!this.props.user || !this.props.authToken) {
        return null;
      }

      const now = Utils.now();

      const pastBookingStart = now.clone().subtract(12, "days");
      const pastBookingEnd = pastBookingStart.clone().add(7, "days");

      const currentBookingStart = now.clone().subtract(2, "days");
      const currentBookingEnd = currentBookingStart.clone().add(5, "days");

      const blockedStart = now.clone().add(5, "days");
      const blockedEnd = blockedStart.clone().add(5, "days");

      const nextBookingStart = now.clone().add(12, "days");
      const nextBookingEnd = nextBookingStart.clone().add(12, "days");

      const pastBookingCode = "11111111";
      const currentBookingCode = "22222222";
      const nextBookingCode = "33333333";

      const ormJson = {
        bookings: [
          {
            startDate: Utils.date2param(pastBookingStart),
            endDate: Utils.date2param(pastBookingEnd),
            vehicleType: "CLASS_B",
            location: "OAKLAND",
            bookingCode: pastBookingCode,
            info: {
              vehicle_id: 1,
              state: "RETURNED",
              place: {
                address: "San Francisco Airport, San Francisco, CA",
              },
              extras: ["UNLIMITED_MILES"],
              contact: {
                destination: "Yosemite",
              }
            }
          },

          {
            startDate: Utils.date2param(currentBookingStart),
            endDate: Utils.date2param(currentBookingEnd),
            vehicleType: "CLASS_B",
            location: "OAKLAND",
            bookingCode: currentBookingCode,
            info: {
              vehicle_id: 1,
              state: "STARTED",
              place: {
                address: "111 1st St, San Jose, CA",
              },
              extras: [],
              contact: {
                destination: "Big Sur",
              },
            }
          },

          {
            startDate: Utils.date2param(nextBookingStart),
            endDate: Utils.date2param(nextBookingEnd),
            vehicleType: "CLASS_B",
            location: "OAKLAND",
            bookingCode: nextBookingCode,
            info: {
              vehicle_id: 1,
              state: "CONFIRMED",
              place: {
                address: "12345 California St, San Francisco, CA",
              },
              extras: [],
            }
          },
        ],
        orders: [
          {
            bookingCode: pastBookingCode,
            info: {
              pricing: {
                vehiclePrices: [
                  {
                    dates: Utils.range2array(pastBookingStart, pastBookingEnd).map(x => Utils.date2param(x)),
                    price: 18000,
                  },
                ],
                extrasPrices: {
                  dailyFreeMiles: 100,
                  dailyPriceUnlimitedMiles: 4000,
                }
              }
            }
          },

          {
            bookingCode: currentBookingCode,
            info: {
              pricing: {
                vehiclePrices: [
                  {
                    dates: Utils.range2array(currentBookingStart, currentBookingEnd).map(x => Utils.date2param(x)),
                    price: 18000,
                  },
                ],
                extrasPrices: {
                  dailyFreeMiles: 100,
                  dailyPriceUnlimitedMiles: 4000,
                }
              }
            }
          },

          {
            bookingCode: nextBookingCode,
            info: {
              pricing: {
                vehiclePrices: [
                  {
                    dates: Utils.range2array(nextBookingStart, nextBookingEnd).map(x => Utils.date2param(x)),
                    price: 18000,
                  },
                ],
                extrasPrices: {
                  dailyFreeMiles: 100,
                  dailyPriceUnlimitedMiles: 4000,
                }
              }
            }
          }
        ],
        users: [
          {
            id: 1,
            username: "test-user",
            info: {
              cut: 0.6,
            }
          }
        ],
        vehicles: [
          {
            id: 1,
            vehicleType: "CLASS_B",
            owner_id: 1,
            info: {
              place: "100 Van Ness, San Franciso, CA",
              year: "2016",
              make: "Winnebago",
              model: "Travato 59K",
              vin: "123VIN456",
              plate: "DEMO-LP",
              state: "CA",
              unavailablePeriods: [
                [Utils.date2param(blockedStart), Utils.date2param(blockedEnd)],
              ],
            },
          },
        ],
        vehicleEvents: [
          {
            created: Utils.time2param(pastBookingStart),
            id: 1,
            vehicle_id: 1,
            info: {
              type: "OWNER_PICKUP",
              bookingCode: "11111111",
              odometer: 123400,
              coords: [37.839626, -122.275095],
              address: "5501 Adeline St, Oakland CA, 94608",
            }
          },

          {
            created: Utils.time2param(pastBookingStart.clone().add(1, "hours")),
            id: 2,
            vehicle_id: 1,
            info: {
              type: "TRIP_START",
              bookingCode: "11111111",
              odometer: 123450,
              coords: [37.633012, -122.400059],
              address: "San Francisco Airport, San Francisco, CA",
            }
          },

          {
            created: Utils.time2param(currentBookingStart),
            id: 14,
            vehicle_id: 1,
            info: {
              type: "OWNER_PICKUP",
              bookingCode: "22222222",
              odometer: 124500,
              coords: [37.839626, -122.275095],
              address: "5501 Adeline St, Oakland CA, 94608",
            }
          },

          {
            created: Utils.time2param(currentBookingStart.clone().add(1, "hours")),
            id: 15,
            vehicle_id: 1,
            info: {
              type: "TRIP_START",
              bookingCode: "22222222",
              odometer: 124550,
              coords: [37.340866, -121.893830],
              address: "111 1st St, San Jose, CA",
            }
          },

          {
            created: Utils.time2param(now.clone().subtract(15, "days")),
            id: 19,
            vehicle_id: 1,
            info: { type: "REPAIRS", details: "Front brake Pads Replacement", odometer: 110000, expenseAmount: 18400 }
          },

          {
            created: Utils.time2param(now.clone().subtract(5, "days")),
            id: 20,
            vehicle_id: 1,
            info: { type: "MAINTENANCE", details: "Oil Change", odometer: 120000, expenseAmount: 4000 }
          },
        ],
      };

      ormJson.vehicleEvents = ormJson.vehicleEvents.concat(
          generateGpsEvents(pastBookingCode, 1000, 123400, 123450, pastBookingStart, pastBookingStart.clone().add(1, "hours"), [
            [37.841558, -122.274475],
            [37.841463, -122.274375],
            [37.844275, -122.275300],
            [37.840403, -122.284150],
            [37.837875, -122.296764],
            [37.831065, -122.294115],
            [37.827626, -122.295678],
            [37.818356, -122.352914],
            [37.813532, -122.362271],
            [37.774782, -122.406372],
            [37.751569, -122.403007],
            [37.738507, -122.408693],
            [37.712309, -122.396329],
            [37.677381, -122.388109],
            [37.660361, -122.404296],
          ]));

      ormJson.vehicleEvents = ormJson.vehicleEvents.concat(
          generateGpsEvents(pastBookingCode, 1500, 123450, 124450, pastBookingStart.clone().add(1, "hours"), pastBookingEnd, [
            [37.633012, -122.400059],
            [37.781625, -122.396614],
            [37.831390, -122.297854],
            [37.969501, -122.315881],
            [38.329640, -122.029196],
            [38.558975, -121.699922],
            [38.648543, -121.373144],
            [39.139951, -120.926749],
            [39.327092, -120.578822],
            [39.346895, -120.152910],
            [39.376469, -120.023298],
            [39.500859, -119.997606],
            [39.546763, -119.784020],
            [39.367704, -119.754005],
            [39.311417, -119.825015],
            [39.211834, -119.806073],
            [39.181836, -119.741975],
            [38.962978, -119.777052],
            [38.721677, -119.550331],
            [38.502242, -119.441104],
            [38.350474, -119.442202],
            [38.246557, -119.219800],
            [37.946774, -119.107057],
            [37.930831, -119.176823],
            [37.950117, -119.220826],
            [37.925772, -119.258369],
            [37.877018, -119.284678],
            [37.870356, -119.427850],
            [37.809784, -119.484100],
            [37.815716, -119.582349],
            [37.850461, -119.574872],
            [37.854859, -119.651648],
            [37.751960, -119.803093],
            [37.786122, -119.876101],
            [37.817968, -119.874170],
            [37.846173, -120.219836],
            [37.816362, -120.318325],
            [37.889055, -120.489236],
            [37.840068, -120.511960],
            [37.846195, -120.619541],
            [37.710379, -121.075838],
            [37.801698, -121.223670],
            [37.741698, -121.369490],
            [37.734388, -121.633872],
            [37.701372, -121.748205],
            [37.705449, -122.005481],
            [37.691031, -122.099801],
            [37.665412, -122.081526],
            [37.635724, -122.099827],
            [37.590760, -122.242705],
            [37.550659, -122.291995],
            [37.586725, -122.333702],
            [37.587855, -122.356726],
            [37.612547, -122.394892],
            [37.633012, -122.400059],
          ]));

      ormJson.vehicleEvents.push({
          created: Utils.time2param(pastBookingEnd),
          id: 12,
          vehicle_id: 1,
          info: {
            type: "TRIP_FINISH",
            bookingCode: "11111111",
            odometer: 124450,
            coords: [37.633012, -122.400059],
            address: "San Francisco Airport, San Francisco, CA",
          }
        });

      ormJson.vehicleEvents.push({
          created: Utils.time2param(pastBookingEnd.clone().add(1, "hours")),
          id: 13,
          vehicle_id: 1,
          info: {
            type: "OWNER_DROPOFF",
            bookingCode: "11111111",
            odometer: 124500,
            coords: [37.839626, -122.275095],
            address: "5501 Adeline St, Oakland CA, 94608",
          }
        });

      ormJson.vehicleEvents = ormJson.vehicleEvents.concat(
          generateGpsEvents(currentBookingCode, 2000, 124500, 124550, currentBookingStart, currentBookingStart.clone().add(1, "hours"), [
            [37.841558, -122.274475],
            [37.841463, -122.274375],
            [37.844275, -122.275300],
            [37.840403, -122.284150],
            [37.837875, -122.296764],
            [37.831065, -122.294115],
            [37.827626, -122.295678],
            [37.818356, -122.352914],
            [37.813532, -122.362271],
            [37.774782, -122.406372],
            [37.751569, -122.403007],
            [37.738507, -122.408693],
            [37.712309, -122.396329],
            [37.677381, -122.388109],
            [37.660361, -122.404296],
            [37.614552, -122.399362],
            [37.586710, -122.355609],
            [37.585414, -122.331495],
            [37.498119, -122.238614],
            [37.449601, -122.123306],
            [37.413311, -122.081561],
            [37.364220, -121.902368],
            [37.354186, -121.911438],
            [37.337431, -121.896967],
          ]));

      ormJson.vehicleEvents = ormJson.vehicleEvents.concat(
          generateGpsEvents(currentBookingCode, 2500, 124550, 124800, currentBookingStart.clone().add(1, "hours"), currentBookingEnd, [
            [37.337692, -121.895738],
            [37.329515, -121.896184],
            [37.300010, -121.875690],
            [37.266121, -121.858945],
            [37.256581, -121.859520],
            [37.254972, -121.846099],
            [37.243800, -121.821157],
            [37.241968, -121.770046],
            [37.138025, -121.638525],
            [37.001390, -121.555889],
            [36.983009, -121.558442],
            [36.925470, -121.546317],
            [36.859422, -121.587879],
            [36.859994, -121.616783],
            [36.853022, -121.636287],
            [36.822362, -121.627600],
            [36.822362, -121.627600],
            [36.688978, -121.808947],
            [36.645877, -121.812996],
            [36.574740, -121.912320],
          ]));


      const orm = OrmPool.create(ormJson);

      //console.log("DEMO ORM: ", orm, ormJson);

      return <WrappedComponent {...this.props} orm={orm} refreshOrm={() => null} username={this.props.user.preferred_username} dashboardLink={"/dashboard-demo"} />;
    }
  };
}

