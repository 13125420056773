import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Vehicle from "../common/Vehicle";
import Utils from "../common/Utils";
import Api from "../Api";

import Button from "@material-ui/core/Button";

import AlertDialog from "./AlertDialog";
import VehicleCalendar from "./VehicleCalendar";

const styles = theme => ({
  calendarButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});


// Policy to set unavailable dates:
// Started trips cannot be changed.

// Owner cancellation discouragement.
// When a new booking is made, is it staged for scheduling.
// Once it is scheduled, the owner has a grace period of 24 hours to cancel (needs to pay $100 to cancel afterwards).
// Next cancellation costs $100 within 24 hours and $200 afterwards.
// After 3 successful bookings, the cancellation fee resets.

// What to do in a last minute cancellation:
// Always have an extra vehicle ready.

// [1, 3] touches [4, 6].
function touches(a, b) {
  let a0 = a[0].clone().subtract(1, "day");
  let b0 = b[0].clone().subtract(1, "day");
  let a1 = a[1].clone().add(1, "day");
  let b1 = b[1].clone().add(1, "day");

  return (a0 > b0 && a0 < b1) ||
         (a1 > b0 && a1 < b1) ||
         (b0 > a0 && b0 < a1) ||
         (b1 > a0 && b1 < a1);
}

class OwnerCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarFocusedInput: null,
      isSettingAvailability: false,
      availabilityChanged: false,
    };
  }

  setAvailable(startDate, endDate, vehicle) {
    let newUnavailablePeriods = []
    for (let interval of vehicle.info.unavailablePeriods) {
      if (Utils.overlaps(interval, [startDate, endDate])) {
        if (interval[0] < startDate) {
          newUnavailablePeriods.push([interval[0], startDate.clone().subtract(1, "day")]);
        }
        if (interval[1] > endDate) {
          newUnavailablePeriods.push([endDate.clone().add(1, "day"), interval[1]]);
        }
      } else {
        newUnavailablePeriods.push(interval);
      }
    }

    if (this.props.username === "test-user") {
      vehicle.info.unavailablePeriods = newUnavailablePeriods;
    } else {
      let newVehicle = new Vehicle(vehicle.toJson());
      newVehicle.info.unavailablePeriods = newUnavailablePeriods;
      Api.changeVehicle(this.props.authToken, this.props.username, newVehicle).then(() => this.props.refreshOrm());
    }
    this.setState({availabilityChanged: true});
  }

  setUnavailable(startDate, endDate, vehicle) {
    let candidate = [startDate, endDate];

    let newUnavailablePeriods = []
    for (let interval of vehicle.info.unavailablePeriods) {
      if (touches(candidate, interval)) {
        candidate = [
          (candidate[0] < interval[0] ? candidate[0] : interval[0]).clone(),
          (candidate[1] > interval[1] ? candidate[1] : interval[1]).clone()
        ];
      } else {
        newUnavailablePeriods.push(interval);
      }
    }
    newUnavailablePeriods.push(candidate);


    if (this.props.username === "test-user") {
      vehicle.info.unavailablePeriods = newUnavailablePeriods;
    } else {
      let newVehicle = new Vehicle(vehicle.toJson());
      newVehicle.info.unavailablePeriods = newUnavailablePeriods;
      Api.changeVehicle(this.props.authToken, this.props.username, newVehicle).then(() => this.props.refreshOrm());
    }
    this.setState({availabilityChanged: true});
  }

  render() {
    const { classes } = this.props;

    const isVertical = Utils.isMobile() || Utils.isMediumScreen();

    return <div>
      <AlertDialog open={this.state.availabilityChanged}
                   onClose={() => this.setState({availabilityChanged: false})}
                   text={"Availability period updated"} />
      <VehicleCalendar
        vehicleEvents={this.props.vehicleEvents}
        orders = {this.props.orders.filter(x => !x.booking.isCanceled())}
        focusedInput={this.state.calendarFocusedInput}

        isSettingAvailablility={this.state.isSettingAvailablility}
        unavailablePeriods={this.props.vehicle.info.unavailablePeriods}

        onDatesChange={(startDate, endDate) => {
            this.setState({startDate: null, endDate: null});
            if (!startDate || !endDate) {
              return;
            }

            // Without this, the function always binds in the old 'vehicle', and previous updates will be erased.
            let lastVehicle = this.props.vehicle;// this.props.orm.vehicles.find(x => x.id === this.props.selectedVehicleId);

            if (this.state.isSettingAvailablility) {
              this.setAvailable(startDate, endDate, lastVehicle);
            } else {
              this.setUnavailable(startDate, endDate, lastVehicle);
            }
          }
        }
        onFocusChange={calendarFocusedInput => this.setState({calendarFocusedInput})}
      />
      <div style={{margin: 10}} />

      <div className={classes.calendarButtons} style={{flexDirection: isVertical ? "column" : "row"}}>

        <Button style={{margin: 10, color:"red", background: "white", width:200}} variant="contained" color="inherit" size="small"
                onClick={() => this.setState({isSettingAvailablility: false, calendarFocusedInput: "startDate"})}>
          Set Unavailable Dates
        </Button>

        <div style={{margin: isVertical ? 0 : 10}} />

        <Button style={{margin: 10, width:200, background: "white"}} variant="contained" size="small"
                onClick={() => this.setState({isSettingAvailablility: true, calendarFocusedInput: "startDate"})}>
          Clear Unavailable Dates
        </Button>
      </div>
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(OwnerCalendar);
