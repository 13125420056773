import React from "react";

import Typography from "@material-ui/core/Typography";

import Utils from "./common/Utils";

// Stand-alone methods to be used in the Book component.
export default class BookHelper {

  static fillWithNewlines(pair) {
    while (pair[0].length < pair[1].length) {
      pair[0].push(<br/>);
    }
    while (pair[1].length < pair[0].length) {
      pair[1].push(<br/>);
    }
    return pair;
  }

  static printPenalties(orderItem) {
    let rateCounts = {};

    for (let dailyPenalty of orderItem.dailyPenalties) {
      let rateStr = Utils.rate2percent(dailyPenalty.rate);
      let oldPrice = dailyPenalty.old.price;
      let newPrice = dailyPenalty.new ? dailyPenalty.new.price : 0;
      let priceStr = Utils.prettyMoney(oldPrice - newPrice);
      let str = rateStr + " of " + priceStr;

      if (!(str in rateCounts)) {
        rateCounts[str] = {
          rate: dailyPenalty.rate,
          price: oldPrice - newPrice,
          days: 0,
        };
      }
      rateCounts[str].days++;
    }

    let rateCountsTotal = {
      totalBeforeRate: 0,
      total: 0,
      days: 0
    }

    let rateCountsArr = [];
    for (let str in rateCounts) {
      let rc = rateCounts[str];
      rc.total = Math.floor(rc.rate * rc.price * rc.days);
      rateCountsArr.push(rc);

      rateCountsTotal.total += rc.total;
      rateCountsTotal.totalBeforeRate += rc.total / rc.rate;
      rateCountsTotal.days += rc.days;
    }
    rateCountsTotal.rate = rateCountsTotal.total / rateCountsTotal.totalBeforeRate;
    rateCountsTotal.price = Math.floor(rateCountsTotal.totalBeforeRate / rateCountsTotal.days);

    // Sort descending by rate (break tie by total).
    rateCountsArr.sort((a, b) => {
      if (a.rate === b.rate) {
        return b.total - a.total;
      } else {
        return b.rate - a.rate;
      }
    });

    let toStr = (rc, aprox) => {
      return  Utils.rate2percent(rc.rate) + " of " + Utils.prettyMoneyAndDays(rc.price, rc.days)
      //return  Utils.rate2percent(rc.rate) + " of " + Utils.prettyNumDays(rc.days) + " @ " + Utils.prettyMoney(rc.price)
      + (rateCountsArr.length > 0 ? (" = " + Utils.prettyMoney(rc.total)) : "");
    };

    /*
    let toStr2 = (rc) => {
      return [
          "About " + Utils.rate2percent(rc.rate) + " of ",
          "about " + Utils.prettyMoneyAndDays(rc.price, rc.days),
          // TODO: include this line back sometimes, to make sure it all matches.
          " = " + Utils.prettyMoney(rc.total),
          ];
    };*/

    rateCountsArr = rateCountsArr.map(x => toStr(x, false));

    //let aggr = toStr2(rateCountsTotal);

    //Utils.log("PrintPenalties: ", "color:blue", aggr, rateCountsArr);

    //return [rateCountsArr, aggr];
    return [rateCountsArr, []];
  }

  static printVehicleDetails(booking, order) {
    let aggr = [];
    let pricings = [];
    //Utils.log("BEF: ", booking, order);
    for (let pricing of order.info.pricing.vehiclePrices) {
      //pricings.push(Utils.prettyNumDays(pricing.dates.length) + " @ " + Utils.prettyMoney(pricing.price) + " per day");
      pricings.push(Utils.prettyMoneyAndDays(pricing.price, pricing.dates.length));
    }

    //aggr.push(Utils.prettyNumDays(order.numDays()) + " @ " + Utils.prettyMoney(order.vehiclePrice()) + " per day");
    const isApprox =
        Number(Utils.withTwoDecimals(order.vehiclePrice() / order.numDays()) * order.numDays()) !== Number(Utils.withTwoDecimals(order.vehiclePrice()));
    aggr.push((isApprox ? "About " : "") + Utils.prettyMoneyAndDays(order.vehiclePrice() / order.numDays(), order.numDays()));

    return [pricings.length === 1 ? [] : pricings, aggr];
  }

  static termsAndConditions() {
    return <div>
      <Typography variant="body1" color="textSecondary">
         Policy details can be found on the <b><a href="policies" style={{color:"inherit"}}>Policies</a></b> page. Selected shortcut links:
      </Typography>
      <ul>
        <li><Typography variant="body1" color="textSecondary"><b><a style={{color:"inherit"}} href="policies#insurance">Insurance policy</a></b></Typography></li>
        <li><Typography variant="body1" color="textSecondary"><b><a style={{color:"inherit"}} href="policies#cancel">Change & cancelation policy</a></b></Typography></li>
        <li><Typography variant="body1" color="textSecondary"><b><a style={{color:"inherit"}} href="policies#grace">Grace period policy</a></b></Typography></li>
        <li><Typography variant="body1" color="textSecondary"><b><a style={{color:"inherit"}} href="policies#extension">Trip extension policy</a></b></Typography></li>
      </ul>
    </div>;
  }
}
