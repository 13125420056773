import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Dropzone from "react-dropzone";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Api from "../Api";
import Config from "../Config";
import FrontendUtils from "../FrontendUtils";

import AlertDialog from "./AlertDialog";
import ClosableModal from "./ClosableModal";
import FlexibleImage from "./FlexibleImage";

const styles = theme => ({
});

function getPreview(file) {
  if (!file.serverFile) {
    return null;
  }

  return Config.get("SERVER_URL") + "/uploads/" + file.serverFile.filename;
}

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: props.file,
      viewOpen: false,
    };
  }

  redirect(path) {
    if (path === this.props.history.location.pathname) {
      FrontendUtils.scrollTop();
    } else {
      this.props.history.push("/");
    }
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length) {
      if (rejectedFiles[0].size > Config.get("UPLOAD_MAX_IMG_SIZE")) {
        this.setState({error: "File too big. Max size is 10MB."});
      } else {
        this.setState({error: "Invalid image file."});
      }
    } else if (acceptedFiles.length) {
      const file = Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) });
      Api.uploadFile(file)
          .then(response => {
            file.serverFile = response;
            this.setState({file}, this.props.onUpload && this.props.onUpload(file));
          })
    }
  }

  render() {
    return <div>
      <Typography variant="subtitle1" style={{margin:10, opacity: this.props.disabled ? 0.4 : 1}}>
        Please upload <b>{this.props.title}</b>
      </Typography>
      <AlertDialog open={Boolean(this.state.error)} text={this.state.error} onClose={() => this.setState({error: null})} />
      <div style={{display:"flex", justifyContent:"center"}}>
        <div style={{cursor:this.props.disabled ? null : "pointer"}}>
          <Dropzone
              disabled={this.props.disabled}
              accept="image/*"
              multiple={false}
              maxSize={Config.get("UPLOAD_MAX_IMG_SIZE")}
              onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height:"100%"}}>
              {this.state.file && !this.props.disabled &&
                  <img src={getPreview(this.state.file)} style={{maxWidth: "95%", maxHeight: "95%"}} alt="Upoladed file" />}
            </div>
          </Dropzone>
        </div>
      </div>
      <div style={{display:"flex", justifyContent:"center"}}>
        <Button style={{display:this.state.file ? null : "none"}} onClick={() => this.setState({viewOpen: true})}>
          View
        </Button>
        <div style={{margin:10}} />
        <Button style={{display:this.state.file ? null : "none"}} onClick={() => this.setState({file: null}, this.props.onUpload && this.props.onUpload(null))}>
          Delete
        </Button>
        {this.state.file &&
            <ClosableModal title="Uploaded Image" open={this.state.viewOpen} onClose={() => this.setState({viewOpen: false})}>
              <FlexibleImage mobileImg={getPreview(this.state.file)} desktopImg={getPreview(this.state.file)} alt="Uploaded Image" />
            </ClosableModal>}
      </div>
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(FileUploader);

