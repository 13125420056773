// Must have the same name between backend and frontend.
export default class UtilsBase {
  static isFrontend() {
    return true;
  }

  static isBackend() {
    return false;
  }
}

