import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Api from "../Api";

import Utils from "../common/Utils";

import ClosableModal from "./ClosableModal";
import DriverEditor from "./DriverEditor";
import Warning from "./Warning";

const styles = theme => ({
});

class Drivers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drivers: this.props.drivers ? this.props.drivers : [],
      editDriverIndex: -1,
    };
  }

  renderDriver(driver, i) {
    return <ListItem key={i} style={{cursor:this.props.editable ? "pointer" : null}} onClick={() => this.props.editable && this.setState({editDriverIndex: i})}>
      <div style={{display:"flex"}}>
      <Typography>
        {driver.isMainDriver
          ? <b>{driver.firstName} {driver.lastName}</b>
          : <span>{driver.firstName} {driver.lastName}</span>}
      </Typography>
      <Typography style={{marginRight:20}} color={driver.isApproved ? "primary" : "error"}>
        <b>&nbsp;({driver.isApproved ? "approved" : "not approved"})</b>
      </Typography>
      </div>
      <ListItemSecondaryAction>
      <IconButton style={{display:this.props.editable ? null : "none"}} onClick={() => this.setState({deleting: i})}>
        <CloseIcon />
      </IconButton>
      <ClosableModal
          title={"Confirm Delete"}
          open={this.state.deleting === i}
          onClose={() => this.setState({deleting: false})}>
        <Typography variant="subtitle1">
          Delete driver {driver.firstName} {driver.lastName}?
        </Typography>
        <div style={{width:200}} />
        <Button onClick={() => this.setState({deleting: false})}>
          NO
        </Button>
        <Button onClick={() => this.handleDeleteDriver(i)}>
          YES
        </Button>
      </ClosableModal>
      </ListItemSecondaryAction>
    </ListItem>;
  }

  handleDeleteDriver(i) {
    // TODO: Props instead of state.
    let drivers = this.state.drivers;
    drivers.splice(i, 1);

    if (drivers.length && !drivers.find(x => x.isMainDriver)) {
      drivers[0].isMainDriver = true;
    }
    Api.setDrivers(this.props.bookingCode, drivers);
    this.setState({drivers, deleting: false});
  }

  handleDoneEditDriver(driver) {
    driver.bookingCode = this.props.bookingCode;

    const i = this.state.editDriverIndex;
    const drivers = this.state.drivers;

    Utils.assert(i > -1);

    if (driver.isMainDriver) {
      for (let d of drivers) {
        d.isMainDriver = false;
      }
    }

    if (i === drivers.length) {
      drivers.push(driver);
    } else {
      drivers[i] = driver;
    }

    if (drivers.length && !drivers.find(x => x.isMainDriver)) {
      drivers[0].isMainDriver = true;
    }
    Api.setDrivers(this.props.bookingCode, drivers);
    this.setState({drivers, editDriverIndex: -1});
  }

  render() {
    return <div style={{width:"100%"}}>
      <Warning quiet={this.state.drivers.length}>
        {this.state.drivers.length
          ? <div>
              Drivers
              <br/>
              <List>
                {this.state.drivers.map((x, i) => this.renderDriver(x, i))}
              </List>
            </div>
          : <div>
              <Typography variant="subtitle1" color="error">
                <b>Your trip has no authorized drivers</b>
              </Typography>
              <br/>

              <Typography variant="subtitle1">
                <b>Driver information is required for background check before a vehicle can be delivered to you.</b>
              </Typography>
            </div>}

        {this.props.editable &&
            <div>
              <br/>
              <Button variant="contained" size="small" color="primary"
                  onClick={() => this.setState({editDriverIndex: this.state.drivers.length})} disabled={this.state.drivers.length >= 3}>
                Add Driver
              </Button>
            </div>}

        <ClosableModal title="Add Driver" open={this.state.editDriverIndex > -1} onClose={() => this.setState({editDriverIndex: -1})}>
          <DriverEditor
              user={this.props.user}
              driver={this.state.editDriverIndex > -1 ? this.state.drivers[this.state.editDriverIndex] : null}
              onDone={driver => this.handleDoneEditDriver(driver)} />
        </ClosableModal>
      </Warning>
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(Drivers);
