import React from "react";

import OrmPool from "../common/OrmPool";

import Api from "../Api";

export default function withAdminOrm(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        orm: null,
      };

      this.refresh();
    }

    componentDidUpdate() {
      if (!this.state.orm) {
        this.refresh();
      }
    }

    refresh() {
      if (this.props.user && this.props.authToken) {
        Api.loadAdminDashboard(this.props.authToken).then(jsonPool => {
          let orm = OrmPool.create(jsonPool);
          this.setState({orm});
        });
      }
    }

    render() {
      if (!this.state.orm) {
        return null;
      }

      return <WrappedComponent {...this.props} orm={this.state.orm} refreshOrm={() => this.refresh()} isAdmin={true} />;
    }
  };
}
