import React from "react";

import Analytics from "../Analytics";
import Header from "../components/Header";

// https://reactjs.org/docs/higher-order-components.html
export default function withHeader(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: null,
      };
    }

    componentDidMount() {
      Analytics.pageview();
    }

    render() {
      return <Header {...this.props} >
        <WrappedComponent {...this.props} />
      </Header>;
    }
  };
}
