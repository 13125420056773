import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  title: {
    paddingTop: 30,
    paddingBottom: 10,
    paddingLeft: 50,
    //paddingLeft: 30,
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  panel: {
    backgroundColor: theme.palette.background.standardHalf,
    margin: 15,
  },

  details: {
    backgroundColor: theme.palette.background.white,
  }
});

class ExpPanel extends Component {
  constructor(props) {
    super(props)

    this.state = { contents: this.props.contents };
  }

  expand(i, expanded) {
    let tmp = this.state.contents;
    tmp[i][2] = expanded;
    this.setState({contents: tmp});
  }

  renderItem(i) {
    const title = this.state.contents[i][0];
    const text = this.state.contents[i][1]
    const expanded = this.state.contents[i][2];

    const { classes } = this.props;

    return <ExpansionPanel elevation={0} className={classes.panel} expanded={expanded} onChange={(event, expanded) => this.expand(i, expanded)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <Typography color="textSecondary">
             {text}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>;
  }

  render() {
    const { classes } = this.props;

    return (
        <div align="left">
          <Typography variant="h6" color="default" className={classes.title}>
            {this.props.title}
          </Typography>
          {this.props.contents.map((x, i) =>
            <div key={i}>
              {this.renderItem(i)}
            </div>)}
        </div>);
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(ExpPanel));

