import Utils from "./Utils";

// https://www.freemaptools.com/find-zip-codes-inside-user-defined-area.htm
const bayAreaVertices = [
  [37.119707, -121.917480],
  [37.250609, -121.556677],
  [37.719413, -121.660545],
  [38.015416, -121.993169],
  [38.207300, -122.230332],
  [37.827685, -122.858198],
];

// https://stackoverflow.com/questions/22521982/check-if-point-inside-a-polygon
// https://github.com/substack/point-in-polygon/blob/master/index.js
function isInsidePolygon(lat, lng) {
  let point = [lat, lng];
  const vs = bayAreaVertices;
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  let x = point[0], y = point[1];

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0], yi = vs[i][1];
      let xj = vs[j][0], yj = vs[j][1];

      let intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }

  return inside;
};

function isDeliverableAddress(searchResult) {
  if (searchResult.place_id === "ChIJY0qG2giFj4ARtshexOmLHO4") {
    // Exception for OAK airport, which for some reason is of type 'route' only (and has no specified except for street address).
    return true;
  }

  if (!searchResult.types.includes("street_address") &&
      !searchResult.types.includes("establishment") &&
      !searchResult.types.includes("premise") &&
      !searchResult.types.includes("subpremise") &&
      !searchResult.types.includes("airport")) {
    return false;
  }

  /*
  // Min length 5: Must have something in addition to street name.
  // Length of 1: USA
  // Length of 2: CA
  // Length of 3: San Francisco (localities, administrative areas, natural features)
  // Length of 4: Market Street (streets)
  // Length of 5: street numbers, establishments (1051, Gap)
  if (searchResult.address_components.length < 5) {
    return false;
  }
  if (searchResult.terms[searchResult.terms.length - 1] !== "USA") {
    return false;
  }
  if (searchResult.terms[searchResult.terms.length - 2] !== "CA") {
    return false;
  }*/

  return true;
}

export default class Place {

  static fromSearchResult(searchResult, lat, lng) {
    //Utils.log("fromSearchResult: ", searchResult);
    return new Place({
      address: searchResult.formatted_address,
      latitude: lat,
      longitude: lng,
      isCovered: isInsidePolygon(lat, lng),
      isDeliverable: isDeliverableAddress(searchResult),
    });
  }

  static fromJson(json) {
    return Utils.deepCopyJson(json);
  }

  toMime64() {
    return Utils.obfuscateParams(JSON.stringify(this.toJson()));
  }

  static fromMime64(str) {
    return new Place(Place.fromJson(JSON.parse(Utils.deobfuscateParams(str))));
  }

  toJson() {
    return Utils.deepCopyJson(this);
  }

  constructor(json) {
    Object.assign(this, Place.fromJson(json));
    //Utils.log("Place: ", this);
  }

  static isInsidePolygon(lat, lng) {
    return isInsidePolygon(lat, lng);
  }
};

