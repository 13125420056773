import React, { Component } from "react";

import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import Utils from "../common/Utils";

import HocUtils from "../hoc/HocUtils";

import VehicleReport from "../components/VehicleReport";
import VehicleReportTotals from "../components/VehicleReportTotals";

const styles = theme => ({
  mainArea: {
    //backgroundColor: theme.palette.background.standard,
    padding: 30,
  },
});

class AdminDashboard extends Component {
  render() {
    const { orm } = this.props;

    // Utils.log("PROPS: ", this.props);

    if (!orm) {
      return <CircularProgress />
    }

    // TODO: Really do this after you go through a couple of bookings to figure out what you need.
    // More and more stuff will be added here as you will want to automate.

    // Overview:

    // P0 (mini campervans only):
    // - List of all current & future bookings.
    // - List of current + future maintenance & repairs.
    // - History view: previous bookings + past events.
    // - Reminders / alerts / events to send paperwork to sign / verify driver's license / figure out drop off address.

    // Fleet view:
    // Vehicle calendars w/ annotations, per vehicle type & multiple shades of red / blue (full or empty days).
    // More calendar annotations: pick up green arrow, drop off yellow arrow.

    // Admin only actions:
    // - Assign booking to vehicle (do it earlier usually, to make sure that owner doesn't go to Outdoorsy instead)
    // - Set booking state to running / finished.
    // - Add vehicle event.

    // P1:
    // - Add Outdoorsy / RVShare booking.
    // - Create / reset vehicle opening code via GPS.
    return <div align="center" className={this.props.classes.mainArea}>
      <Typography color="textSecondary" variant="h6">
        Admin Dashboard
      </Typography>

      <div>
        Impersonate users on dashboard:
        {orm.users.map((x, i) => <div key={i}><Link to={"/dashboard?user=" + x.username}>{x.username}</Link></div>)}
      </div>

      <div style={{display:"flex", flexDirection: Utils.isMobile() ? "column" : "row"}}>
        <div>
          <VehicleReportTotals orders={orm.orders} vehicleEvents={orm.vehicleEvents} isAdminView type="bookings"/>
          <VehicleReport
              orders={orm.orders}
              vehicleEvents={orm.vehicleEvents}
              isAdminView={true}
              orm={orm}
              type="bookings"
              authToken={this.props.authToken}
              onUpdate={() => this.props.refreshOrm()} />
        </div>

        <div>
          <VehicleReportTotals orders={orm.orders} vehicleEvents={orm.vehicleEvents} isAdminView type="expenses"/>
          <VehicleReport
              orders={orm.orders}
              vehicleEvents={orm.vehicleEvents}
              isAdminView={true}
              orm={orm}
              type="expenses"
              authToken={this.props.authToken}
              onUpdate={() => this.props.refreshOrm()} />
        </div>
      </div>
    </div>;
  }
}

export default HocUtils.withAdmin(withStyles(styles, { withTheme: true })(AdminDashboard));

