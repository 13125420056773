import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Utils from "../common/Utils";

import ThumbImage from "./ThumbImage";
import ClosableModal from "./ClosableModal";
import PhotoGallery from "./PhotoGallery";

const styles = theme => ({
});

const THUMB_WIDTH = 2 + ThumbImage.width();
const BIG_THUMB_RATIO = ThumbImage.bigThumbRatio();
const WIDTH = window.innerWidth; // Never invoke innerWidth in render, as it can change when autozooming on iPhone.

// Mobile pics: 500x375. 2 x small pic = large pic. All small pics under large pic.
// Desktop pics: 800x500. 3 x small pic = large pic. Small pics to the right of large pic, if there is space for it.
class FlexibleGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoClick: -1,
    };
  }

  renderModal() {
    return <ClosableModal
        title={this.props.vehicleInfo.vehicleName}
        noMargin={true}
        onClose={() => this.setState({photoClick: -1})}
        open={this.state.photoClick !== -1}>
      <PhotoGallery photos={Utils.isMobile() ? this.props.vehicleInfo.photosMobile : this.props.vehicleInfo.photos} activeStep={this.state.photoClick} />
    </ClosableModal>;
  }

  renderThumb(i) {
    let photos = this.props.vehicleInfo.photos;
    let photosMobile = this.props.vehicleInfo.photosMobile;
    let { bigFirst } = this.props;

    return <div key={i} onClick={() => this.setState({photoClick: i})}>
      <ThumbImage photo={photos[i]} photoMobile={photosMobile[i]} big={bigFirst && i === 0} />
    </div>;
  }

  render() {
    let photos = this.props.vehicleInfo.photos;
    let { bigFirst } = this.props;

    // All row with big image.
    let row0 = [];
    let i = 0;
    if (bigFirst) {
      const numRightColumns = Math.floor((WIDTH - THUMB_WIDTH * BIG_THUMB_RATIO) / THUMB_WIDTH);
      row0.push(this.renderThumb(0));
      for (i = 1; i < 1 + numRightColumns * BIG_THUMB_RATIO && i < photos.length; i++) {
        if (i % BIG_THUMB_RATIO === 1) {
          row0.push([]);
        }
        row0[row0.length - 1].push(this.renderThumb(i));
      }
    }
    row0 = row0.map((x, i) => <div key={i}>{x}</div>);

    // Add all other rows.
    const numColumns = Math.floor(WIDTH / THUMB_WIDTH);
    let rows = [row0];
    for (let k = i; k < photos.length; k++) {
      if ((k - i) % numColumns === 0) {
        rows.push([]);
      }
      rows[rows.length - 1].push(this.renderThumb(k));
    }
    rows = rows.map((x, i) => <div key={i} style={{display:"flex"}}>{x}</div>);

    // NOTE: If you want flex to wrap, put it in a center row flex div!
    return  <div style={{display: "flex", justifyContent: "center"}}>
      {this.renderModal()}
      <div align="left">
        {rows}
      </div>
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(FlexibleGallery));

