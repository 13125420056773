import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Script from "react-load-script";
//import StripeCheckout from 'react-stripe-checkout';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import Analytics from "../Analytics";
import Api from "../Api";
import Conversion from "../Conversion";
import Config from "../Config";

import ClosableModal from "./ClosableModal";
import TextFieldValidated from "./TextFieldValidated";

const CURRENCY = "USD";

const styles = theme => ({
});

// I need this delay because Book component for some reason remounts right before confirm page redirection (maybe because of modal).
// So I have to wait for it so I don't get unmount during set state...
// I get this error
// https://stackoverflow.com/questions/50428842/cant-call-setstate-or-forceupdate-on-an-unmounted-component
async function callWithDelay(cb) {
  //Utils.log("Start await");
  await Utils.sleep(300);
  //Utils.log("Await done");
  cb();
}

const successPayment = (data) => {
  //alert("Payment success: " + data);
};

const errorPayment = (err) => {
  //alert("Payment error: " + err);
};

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sciptLoaded: false,
      scriptError: false,
      handler: null,
    };
  }

  renderLoadScript() {
    return <Script
        url="https://checkout.stripe.com/checkout.js"
        onCreate={() => this.setState({scriptLoading: true, scriptLoaded: false, scriptError: false})}
        onError={() => this.setState({scriptLoading: false, scriptError: true})}
        onLoad={() => { this.setState({scriptLoading: false, scriptLoaded: true}); this.onScriptLoad(); }}
      />; 
  }

  onToken(token) {
    const { amount, description, booking, penalties, cb } = this.props;

    booking.info.email = token.email;
    booking.info.contact = {
      phone: this.state.phone,
      destination: this.state.destination,
    };

    let stripeChargeReq;
    if (amount > 0) {
      stripeChargeReq = {
        amount: amount,
        currency: CURRENCY,
        description,
        source: token.id,
        receipt_email: token.email,
      };
    }

    Api.change(booking, penalties, token.id, stripeChargeReq)
      .then(successPayment)
      .catch(errorPayment);
    // Go to confirm page and wait.
    callWithDelay(() => cb(token));
  }

  onScriptLoad() {
    /*global StripeCheckout */
    let handler = StripeCheckout.configure({
      key: Config.get("STRIPE_PUBLISHABLE_KEY"),
      image: "/img/favicon2-128x128.png",
      locale: "auto",
      billingAddress: true,
      currency: CURRENCY,
      token: token => this.onToken(token)});
    this.setState({handler});
  }

  open(event) {
    Analytics.pageview("/pay?amount=" + this.props.amount);
    Conversion.reportConversion(this.props.amount);

    if (Utils.isProd() && Config.get("MARKETING_MODE")) {
      this.setState({error: "Payment system is under maintenance, please return later"});
    } else {
      const { name, description, amount } = this.props;
      this.state.handler.open({name, description, amount});
      event.preventDefault();
    }
  }

  render() {
    const skipContact = this.props.booking.info.contact && this.props.booking.info.contact.phone && this.props.booking.info.contact.destination;

    // https://stripe.com/docs/checkout#integration-custom
    // TODO: Proceed to Payment is fine for new bookings. Rename to Pay with Card for changes
    return <div>
      {this.renderLoadScript()}
      <ClosableModal
          title="Error"
          open={Boolean(this.state.error)}
          onClose={() => this.setState({error: false})}>
        <Typography variant="subtitle1" color="error">
          {this.state.error}
        </Typography>
      </ClosableModal>
      <ClosableModal
          title="Contact Information"
          open={Boolean(this.state.contactModal)}
          onClose={() => this.setState({contactModal: false})}>
        <Typography>
          Please provide your trip destination and phone number.
        </Typography>
        <TextFieldValidated
            id={"destination"} label={"Furthest Destination"} value={this.state.destination}
            isValid={() => Boolean(this.state.destination)} onChange={destination => this.setState({destination})} />
        <TextFieldValidated
            id={"phone"} label={"Phone Number"} value={this.state.phone}
            isValid={() => Utils.isValidPhone(this.state.phone)} onChange={phone => this.setState({phone})} />
        <br/>
        <Button variant="contained" color="primary" style={{margin:10}}
            disabled={!this.state.phone || !this.state.destination}
            onClick={event => this.setState({contactModal: false}, () => this.open(event))}>
          Continue
        </Button>
      </ClosableModal>
      <Button
          disabled={this.props.disabled || !this.state.scriptLoaded}
          variant="contained" color="primary" style={{margin:10}}
          size="large"
          onClick={event => this.props.validator() && (skipContact ? this.open(event) : this.setState({contactModal: true}))}>
        Proceed To Payment
      </Button>
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Checkout));
