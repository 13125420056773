import Utils from "./common/Utils";

const getVars = isProd => {
  return {
    "SERVER_URL": (isProd ? "https://api.refreshcampers.com" : "http://localhost:8080"),

    "GA_TRACKING_ID": (isProd ? "UA-74114894-3" : "UA-74114894-2"),

    "STRIPE_PUBLISHABLE_KEY": (isProd ? "pk_live_sPZaEiP4gA0NexzQNiBeYNZO" : "pk_test_twFrNPkjlV4s3XjawosQlL91"),

    "OKTA_ISSUER": (isProd ? "https://login.refreshcampers.com/oauth2/aushshqhfnllzXP2T0h7" : "https://dev-903217.oktapreview.com/oauth2/default"),
    "OKTA_CLIENT_ID": (isProd ? "0oahrnkhzxdrCowPA0h7" : "0oahr79vd6Nhrb2D30h7"),

    "GOOGLE_MAPS_API_KEY": "AIzaSyACxBzR2aP2dRzbn-gqorCzDi7w_s78LwQ",

    "UPLOAD_MAX_IMG_SIZE": 10 * 1024 * 1024,

    "MARKETING_MODE": true,
    "OWNER_MARKETING_MODE": true,
  };
};

export default class Config {
  static get(key) {
    // TODO: Allow sitevars DB pushes.
    const vars = getVars(Utils.isProd());

    if (key in vars) {
      return vars[key];
    }

    throw new Error("Invalid config key: ", key);
  }
}
