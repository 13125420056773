import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Typography from "@material-ui/core/Typography";

import FlexibleGallery from "./FlexibleGallery";

const styles = theme => ({
  vehicleInfoArea: {
    padding: 20,
    maxWidth: 800,
  },

  vehicleDetailTable: {
    maxWidth: 350,
    overflow: "hidden",
    width: "100%",
  },
});

class VehicleInfo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      vehicleInfo: props.vehicleInfo,
    };
  }

  renderVehicleDetailRow(left, right) {
    const { classes } = this.props;

    if (!right) {
      return null;
    }

    return (
        <tr className={classes.vehicleDetailRow}>
          <td>
            <Typography variant="subtitle1" align="left">
              {left}
            </Typography>
            </td>
            <td>
            <Typography variant="subtitle1" color="textSecondary" align="right">
              {Array.isArray(right)
               ? right.map((x, i) => <div key={i}>{x}<br/></div>)
               : right}
            </Typography>
          </td>
        </tr>);
  }

  renderVehicleTop() {}

  render() {
    const vehicleInfo = this.state.vehicleInfo;
    const { classes } = this.props;

    return (
        <div align="center">
          {this.props.bigTitle &&
                <Typography variant="h5" color="default" style={{paddingLeft:20, paddingRight:20}}>
                  {vehicleInfo.vehicleName}
                  <br/>
                  <br/>
                </Typography>}
          {this.props.noPics
            ? null
            : <div style={{paddingBottom:20}}><FlexibleGallery vehicleInfo={this.state.vehicleInfo} bigFirst={true} /></div>}
          <div className={classes.vehicleInfoArea}>
          {this.props.insideModal
            ? <br/>
            : <div align="left">
                <Typography variant="h6" color="default">
                  {this.props.noPics ? "RV Details" : (vehicleInfo.vehicleName + " - Details")}
                </Typography>
                {this.props.skipLine ? null : <hr/>}
                <br/>
              </div>
          }
          {vehicleInfo.description.map(
              (x, i) =>
              <div key={i}>
                <Typography variant="subtitle1" color="textSecondary" align="left">
                  {x}
                </Typography>
                <br/>
              </div>
          )}
          <table className={classes.vehicleDetailTable}>
            <tbody>
              {this.renderVehicleDetailRow("Model", vehicleInfo.vehicleModels)}
              {this.renderVehicleDetailRow("Chassis", vehicleInfo.chassisModels)}
              {this.renderVehicleDetailRow("Year", vehicleInfo.vehicleYear)}
              {this.renderVehicleDetailRow("Seats", vehicleInfo.numPassengers + " w/ seatbelts")}
              {this.renderVehicleDetailRow("Sleeps", vehicleInfo.numSleeps)}
              {this.renderVehicleDetailRow("Length", vehicleInfo.vehicleLength)}
              {this.renderVehicleDetailRow("Engine", vehicleInfo.engine)}
              {this.renderVehicleDetailRow("Mpg", vehicleInfo.mpg)}
            </tbody>
          </table>
          <br/>
          <Typography variant="subtitle1" align="left">
            Included gear
          </Typography>
          <ul>
            {vehicleInfo.features.map(
                (x, i) =>
              <li key={i}>
                <Typography variant="subtitle1" color="textSecondary" align="left">
                  {x}
                </Typography>
              </li>
            )}
          </ul>
        </div>
      </div>);
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(VehicleInfo));

