import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import FrontendUtils from "../FrontendUtils";

import Utils from "../common/Utils";

import HocUtils from "../hoc/HocUtils";

import FlexibleImage from "../components/FlexibleImage";
import ListVehicleForm from "../components/ListVehicleForm";
import Squares from "../components/Squares";
import Warning from "../components/Warning";

const styles = theme => ({
  mainArea: {
    backgroundColor: theme.palette.background.standard,
    padding: Utils.isMobile() ? 15 : 30,
  },

  primary: {
    color: theme.palette.primary.dark,
  },

  secondary: {
    //color: theme.palette.secondary.light,
    color: "#4d89e9", //"#3c78d8" + #111111
  },
});

class ListRv extends Component {
  // TODO: Owners howto.

  render() {
    //const { classes } = this.props;

    return <div>
      <FlexibleImage desktopImg={"/img/chill-1920x450.jpg"} mobileImg={"/img/chill-800x800.jpg"} alt="Chill People" />
      <div align="center" className={this.props.classes.mainArea}>
        <Warning>
          <Typography color="textSecondary" variant="subtitle1">
            <ListVehicleForm />
            <br/>
          </Typography>
        </Warning>
        <div align="center">
          <br/>
          <br/>
          <br/>
          <Typography variant="h6" color="default">
            Why Refresh Campers?
          </Typography>
          <br/>
          {Utils.isMobile() ? null : <br/>}
          <Squares>
            <FlexibleImage desktopImg={"/img/listrv-relax-800x800.jpg"} mobileImg={"/img/listrv-relax-800x800.jpg"} mobileText="COMPLETE FLEXIBILITY" alt="Relax" />
            <div>
              {Utils.isMobile() || <Typography color="textPrimary" variant="h6" align="center">
                Complete Flexibility
                <br/>
                <br/>
              </Typography>}
              <Typography color="textSecondary" variant="h6">
                We deliver the RV back to you whenever you wish to use it for personal use.
                <br/>
                <br/>
                You can still use other self-managed platforms like Outdoorsy or RVshare while listed with Refresh Campers.
                <br/>
                <br/>
              </Typography>
            </div>

            <FlexibleImage desktopImg={"/img/listrv-beach-800x800.jpg"} mobileImg={"/img/listrv-beach-800x800.jpg"} mobileText="ZERO WORK NEEDED" alt="Relax" />
            <div>
              {Utils.isMobile() || <Typography color="textPrimary" variant="h6" align="center">
                Zero Work Needed
                <br/>
                <br/>
              </Typography>}
              <Typography color="textSecondary" variant="h6">
                RV management takes time, but your time is expensive.
                <br/>
                <br/>
                Work, family, travel -- we let you focus on more important things in life
                <br/>
                <br/>
              </Typography>
            </div>


            <FlexibleImage desktopImg={"/img/listrv-shield-800x800.jpg"} mobileImg={"/img/listrv-shield-800x800.jpg"} mobileText="INSURANCE & BACKGROUND CHECKS" alt="Instructions" />
            <div>
              {Utils.isMobile() || <Typography color="textPrimary" variant="h6" align="center">
                Insurance & Background Checks
                <br/>
                <br/>
              </Typography>}
              <Typography color="textSecondary" variant="h6">
                Our first priority is making sure your RV is in good hands. We check the renter's DMV records before the trip starts.
                <br/>
                <br/>
                Also, all our rentals include required liability and Comprehensive & Collision coverage.
                <br/>
                <br/>
              </Typography>
            </div>

            <FlexibleImage desktopImg={"/img/listrv-gps-800x800.jpg"} mobileImg={"/img/listrv-gps-800x800.jpg"} mobileText="LIVE GPS TRACKING" alt="Instructions" />
            <div>
              {Utils.isMobile() || <Typography color="textPrimary" variant="h6" align="center">
                Live GPS tracking
                <br/>
                <br/>
              </Typography>}
              <Typography color="textSecondary" variant="h6">
                We install GPS trackers in all our vehicles, and you can follow them in real time from your Refresh Campers Dashboard.
                <br/>
                <br/>
              </Typography>

              <div align="center">
                <Button variant="contained" color="secondary" onClick={() => {
                  // https://stackoverflow.com/questions/47789347/open-link-in-new-tab-in-react-router-programmatically
                  const url = "/dashboard-demo";
                  var win = window.open(url, '_blank');
                  win.focus();
                }}>
                  Check out Owners Dashboard
                </Button>
                <br/>
                <br/>
              </div>
            </div>

            <FlexibleImage desktopImg={"/img/listrv-checklist-800x800.jpg"} mobileImg={"/img/listrv-checklist-800x800.jpg"} mobileText="BEFORE & AFTER CHECKS" alt="Instructions" />
            <div>
              {Utils.isMobile() || <Typography color="textPrimary" variant="h6" align="center">
                Before & After Checks
                <br/>
                <br/>
              </Typography>}
              <Typography color="textSecondary" variant="h6">
                We run thorough routine checklists before and after each rental:
                so you don't have to
                <br/>
                - RV cleaning, empty & refill tanks
                <br/>
                - Safety check: fluids, tires, lights
                <br/>
                - Take photos & fill agreements
                <br/>
                - Introductory tour for each renter
                <br/>
                <br/>
            </Typography>
            </div>

            <FlexibleImage desktopImg={"/img/listrv-mechanic-800x800.jpg"} mobileImg={"/img/listrv-mechanic-800x800.jpg"} mobileText="MAINTENANCE & INSPECTION" alt="Instructions" />
            <div>
              {Utils.isMobile() || <Typography color="textPrimary" variant="h6" align="center">
                Maintenance & Inspection
                <br/>
                <br/>
              </Typography>}
              <Typography color="textSecondary" variant="h6">
                We perform routine maintenance if it's due before or after a rental trip.
                <br/>
                <br/>
                We also take the RV for a Multi Point Inspection every 90 days, and forward the recommendations back to you.
              </Typography>
            </div>
          </Squares>
          <br/>
          <br/>
          <br/>
          <Button color="primary" variant="contained" size="large" onClick={() => {
            FrontendUtils.scrollTop(0, 0);
          }}>
            Back To Top
          </Button>
          <br/>
          <br/>
        </div>
      </div>
    </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(ListRv));

