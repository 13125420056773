import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = theme => ({
});

class AlertDialog extends Component {

  render() {
    return <Dialog
            open={this.props.open} onClose={() => this.props.onClose()}>
          <DialogContent>
            <DialogContentText>
              {this.props.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={() => this.props.onClose()}
                color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(AlertDialog));
