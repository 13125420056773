import ReactGA from "react-ga";

import Config from "./Config";
import Utils from "./common/Utils";

export default class Analytics {
  static init() {
    ReactGA.initialize(Config.get("GA_TRACKING_ID"), {
      debug: Utils.isDev(),
    });
  }

  static pageview(path) {
    if (!path) {
      path = window.location.pathname + window.location.search;
    }
    //Utils.log("Analytics pageview: ", path);
    ReactGA.pageview(path);
    ReactGA.event({category: "Pageview", action: "Changed", label: path});
  }

  static modalview(modalName) {
    ReactGA.modalview(modalName);
    ReactGA.event({category: "Modal", action: "Changed", label: modalName});
  }

  static event(json) {
    ReactGA.event(json);
  }
}

