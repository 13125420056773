import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

const styles = theme => ({
});

class VehicleEventInfo extends Component {

  render() {
    const { darken, vehicleEvent } = this.props;
    const vehicle = vehicleEvent.vehicle;

    return <div align="left">
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <Typography variant="body2" color="textSecondary" style={{opacity: darken ? 1 : 0.7}}>
          {Utils.prettyTime(vehicleEvent.created)}
        </Typography>
        <span style={{margin:10}} />
        <Typography variant="body2" color="textSecondary" style={{opacity: darken ? 1 : 0.7}}>
          {vehicleEvent.info.odometer ? Utils.prettyMiles(vehicleEvent.info.odometer) : null}
        </Typography>
      </div>
      <div style={{display:"flex", justifyContent:"space-between", textDecoration: "none"}} className={this.props.isClickable ? "bluelink" : ""}>
        <Typography variant="body2" color={this.props.isClickable ? "inherit" : "textSecondary"}>
          <span>{this.props.isClickable ? vehicleEvent.prettyType() : <b>{vehicleEvent.prettyType()}</b>}</span>
        </Typography>
        <span style={{margin:10}} />
        <Typography variant="body2" color="textSecondary">
          {vehicleEvent.info.expenseAmount && <span>{Utils.prettyMoney(vehicleEvent.info.expenseAmount)}</span>}
        </Typography>
      </div>
      <div>
        <Typography variant="body2" color="textSecondary" style={{opacity: darken ? 1 : 0.7}}>
          {vehicleEvent.info.address}
        </Typography>
        {vehicleEvent.info.details &&
          <Typography variant="body2" color="textSecondary" style={{opacity: darken ? 1 : 0.7}}>
            Details: {vehicleEvent.info.details}
          </Typography>}
        {this.props.isAdminView &&
          <Typography color="textSecondary">
            {vehicle
              ? <span>
                  Vehicle Type: {Utils.vehicleName(vehicle.vehicleType)}
                  <br/>
                  Model: {vehicle.info.year} {vehicle.info.make} {vehicle.info.model}
                  <br/>
                  VIN: {vehicle.info.vin}
                  <br/>
                  License Plate: {vehicle.info.plate} {vehicle.info.state}
                  <br/>
                </span>
              : "Invalid vehicle id: " + vehicleEvent.vehicle_id}
          </Typography>}
      </div>
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(VehicleEventInfo));
