import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { DateRangePicker, DayPickerRangeController } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import OrderInfo from "./OrderInfo";
import VehicleEventInfo from "./VehicleEventInfo";
import OutsidePopper from "./OutsidePopper";


const styles = theme => ({
  legend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  }
});

const MIN_NIGHTS = 0;
const MAX_NIGHTS = 365;

function isGoodRange(startDate, endDate, busyDays, isSettingAvailability) {
  if (!startDate || !endDate) {
    throw new Error("Both start and end must be defined");
  }

  //Utils.log("isGoodDate: ", startDate, endDate, endDate.clone()subtract(MIN_NIGHTS, "days"));

  // PAY ATTENTION: MUTATE THE END DATE!
  if (endDate < startDate.clone().add(MIN_NIGHTS, "days")) {
    return false;
  }
  if (endDate > startDate.clone().add(MAX_NIGHTS, "days")) {
    return false;
  }

  if (isSettingAvailability) {
    // Override blocked dates.
    return true;
  }

  let start = Utils.date2param(startDate);
  let end = Utils.date2param(endDate);
  for (let date in busyDays) {
    if (date >= start && date <= end) {
      return false;
    }
  }
  return true;
}

function computeBusyDays(orders) {
  let busyDays = {};
  for (let order of orders) {
    let days = Utils.interval2array(order.booking.startDate, order.booking.endDate);
    for (let day of days) {
      busyDays[Utils.date2param(day)] = {order};
    }
    busyDays[Utils.date2param(order.booking.startDate)].isStart = true;
    busyDays[Utils.date2param(order.booking.endDate)].isEnd = true;
  }
  return busyDays;
}

function computeVehicleEventDays(vehicleEvents) {
  let vehicleEventDays = {};
  for (let vehicleEvent of vehicleEvents.filter(x => ["MAINTENANCE", "REPAIRS"].includes(x.info.type))) {
    let date = Utils.date2param(vehicleEvent.created);
    if (!vehicleEventDays[date]) {
      vehicleEventDays[date] = [];
    }
    vehicleEventDays[date].push({vehicleEvent});
  }
  return vehicleEventDays;
}

function isTooSoon(date) {
  date.startOf("day");
  const today = Utils.now().startOf("day");

  if (date.diff(today, "days") < 0) { // TODAY is within range.
    return true;
  }

  return false;
}

function isTooLate(date) {
  date.startOf("day");
  const today = Utils.now().startOf("day");

  if (date.diff(today, "days") > 365) {
    return true;
  }

  return false;
}

class VehicleCalendar extends Component {
  constructor(props) {
    super(props);

    this.previousHistoryLength = 0;

    this.open = false;

    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: props.focusedInput,
      onDatesChange: props.onDatesChange,
      onFocusChange: props.onFocusChange,
      busyDays: computeBusyDays(props.orders),
      vehicleEventDays: computeVehicleEventDays(props.vehicleEvents),
    };

    //Utils.log("State: ", this.state);
  }


  generateInfoText() {
    if (this.state.focusedInput === "startDate") {
      if (this.state.endDate) {
        return "Select pick up date";
      } else {
        return "Select pick up & drop off";
      }
    } else if (this.state.focusedInput === "endDate") {
      if (this.state.startDate) {
        return "Select drop off date";
      } else {
        return "Select pick up & drop off";
      }
    }
  }

  isGoodRange(start, end) {
    return isGoodRange(start, end, this.state.busyDays, this.props.isSettingAvailability);
  }

  isUnavailable(date) {
    for (let interval of this.props.unavailablePeriods) {
      if (date >= interval[0] && date <= interval[1]) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    Utils.log("componentDidMount");
  }

  componentWillReceiveProps(props) {
    this.setState({
      focusedInput: props.focusedInput,
      busyDays: computeBusyDays(props.orders),
      vehicleEventDays: computeVehicleEventDays(props.vehicleEvents),
    });
  }

  isDayBlocked(date) {
    date.startOf("day");

    if (this.isOutsideRange(date)) {
      return false;
    }

    return this.state.busyDays.hasOwnProperty(Utils.date2param(date));
  }

  isOutsideRange(date) {
    return isTooSoon(date) || isTooLate(date);
  }

  renderDayContents(date) {
    date.startOf("day");

    const dateParam = Utils.date2param(date);

    let className = "calendar-date";
    let className2 = "calendar-date";

    let classNameInfo = "";
    if (this.state.vehicleEventDays.hasOwnProperty(dateParam)) {
      classNameInfo = "calendar-date-info";
      className += " calendar-date-info0 " + (this.props.focusedInput ? null : " pointerOnHover");
    }

    if (this.state.busyDays.hasOwnProperty(dateParam)) {
      className += " calendar-blocked-date " + (this.props.focusedInput ? null : " pointerOnHover");
      className2 += " calendar-blocked-date-partial";
      
      if (this.state.busyDays[dateParam].isStart) {
        className2 += " calendar-blocked-date-start";

        // Info flag.
        classNameInfo = "calendar-date-order-info";
        className += " calendar-date-info0 " + (this.props.focusedInput ? null : " pointerOnHover");
      }
      if (this.state.busyDays[dateParam].isEnd) {
        className2 += " calendar-blocked-date-end";
      }
    }

    // See whether it is compatible with other date.
    if (this.state.focusedInput === "endDate" &&
        this.state.startDate &&
        this.state.startDate !== date &&
        (!this.state.endDate || this.state.endDate < date) &&
        !this.isOutsideRange(date) &&
        this.isGoodRange(this.state.startDate, date)) {
      className += " vehicle-calendar-compatible";
    }
    if (this.props.isSingleDay &&
        this.state.focusedInput === "startDate" &&
        this.state.endDate &&
        this.state.endDate !== date &&
        (!this.state.startDate || this.state.startDate > date) &&
        !this.isOutsideRange(date) &&
        this.isGoodRange(date, this.state.endDate)) {
      className += " vehicle-calendar-compatible";
    }

    // Set unavailable periods (must be after compatible).
    if (this.isUnavailable(date) && this.state.startDate !== date) {
      className += (this.state.focusedInput ? " vehicle-calendar-unavailable" : " vehicle-calendar-unavailable-nohover");
    }

    // Make outside dates darker in color.
    if (!this.isOutsideRange(date) && !this.state.focusedInput) {
      className += " calendar-regular-text";
    }

    const popperDate = this.state.prevPopperDate === dateParam ? null : dateParam;

    return <div className={className} onClick={(event) => this.props.focusedInput || this.setState({popperDate, prevPopperDate: null, popperAnchor: event.currentTarget})}>
      <div className={className2}>
        <div className={classNameInfo} />
        {date.format("DD")}
      </div>
    </div>;
  }

  onDatesChange(startDate, endDate) {
    // Dates are set to noon. Instead, set then to midnight... otherwise comparisons with things coming from param2date will be fucked.
    if (startDate) {
      startDate.startOf("day");
    }
    if (endDate) {
      endDate.startOf("day");
    }

    //Utils.log("onDatesChangeParam: ", Utils.date2param(startDate) + " to " + Utils.date2param(endDate), this.state.focusedInput);
    //Utils.log("onDatesChangeState: ", Utils.date2param(this.state.startDate) + " to " + Utils.date2param(this.state.endDate), this.state.focusedInput);

    // NOTE:
    // We also do the onFocusChange work in this method -- since we can't get the correct dates in
    // onFocusChange, we ignore it when focus switches to null (otherwise we must close the calendar before
    // we know the dates are valid).

    // Don't reopen calendar on clear dates button.
    if (!startDate && !endDate) {
      this.setState({startDate: null, endDate: null, focusedInput: null});
      this.state.onDatesChange(null, null);
      this.state.onFocusChange(null);
      return;
    }

    // If no end date is selected, switch to selecting end date.
    if (!endDate) {
      this.setState({startDate, endDate: null, focusedInput: "endDate"});
      //this.state.onDatesChange(startDate, null);
      this.state.onFocusChange("endDate");
      return;
    }

    // If only the end date is selected, switch to selecting start date.
    if (!startDate) {
      this.setState({startDate: null, endDate: endDate, focusedInput: "startDate"});
      //this.state.onDatesChange(startDate, null);
      this.state.onFocusChange("startDate");
      return;
    }

    if (!this.isGoodRange(startDate, endDate)) {

      //Utils.log("Range not good...");

      // Hey... if this range is not good but switching from start to end helps, do it!
      if (this.state.focusedInput === "startDate" &&
          this.state.startDate &&
          !this.props.isSingleDay &&
          this.isGoodRange(this.state.startDate, startDate)) {
        //Utils.log("Switching from start ", this.state.startDate, startDate);
        this.setState({endDate: startDate, focusedInput: null});
        this.state.onDatesChange(this.state.startDate, startDate);
        this.state.onFocusChange(null);
        this.open = false;
        return;
      }
      if (this.state.focusedInput === "endDate" &&
          this.state.endDate &&
          !this.props.isSingleDay &&
          this.isGoodRange(endDate, this.state.endDate)) {
        //Utils.log("Switching from end ", endDate, this.state.endDate);
        this.setState({startDate: endDate, focusedInput: null});
        this.state.onDatesChange(endDate, this.state.endDate);
        this.state.onFocusChange(null);
        this.open = false;
        return;
      }

      // If this is a start date, try removing the end date.
      // Example: 13-16 is selected, 5-8 is canceled. Select 1 should remove the end date.
      if (this.state.focusedInput === "startDate") {
        //Utils.log("Clearing end...");
        this.setState({startDate, endDate: null, focusedInput: "endDate"});
        //this.state.onDatesChange(startDate, null);
        this.state.onFocusChange("endDate");
        return;
      }

      // If this is an end date, select it as start date and try again with end date.
      // Example: 1-4 is selected, 5-8 is canceled. Select 1 again, and clicking 9 should reselect start date.
      if (this.state.focusedInput === "endDate") {
        //Utils.log("Position to start again...");
        this.setState({startDate: endDate, endDate: null, focusedInput: "endDate"});
        //this.state.onDatesChange(endDate, null);
        this.state.onFocusChange("endDate");
        return;
      }

      throw new Error("Unreachable");
    }

    // If the range is good make sure end date is also selected.
    // Unless this calendar only picks the start / end date.
    if (this.state.focusedInput === "startDate" && !this.props.isSingleDay) {
      this.setState({startDate, endDate: null, focusedInput: "endDate"});
      //this.state.onDatesChange(startDate, null);
      this.state.onFocusChange("endDate");
      return;
    }

    //Utils.log("Good range selected!");

    //let newFocus = this.state.focusedInput === "startDate" ? "endDate" : null;
    this.setState({startDate: null, endDate: null, focusedInput: null, initialVisibleMonth: startDate});
    this.state.onDatesChange(startDate, endDate);
    this.state.onFocusChange(null);

    this.open = false;
  }

  onFocusChange(focusedInput) {
    //Utils.log("onFocusChange: ", focusedInput, this.state.focusedInput, Utils.date2param(this.state.startDate), Utils.date2param(this.state.endDate), this.open);

    // Fuck this method, as we don't know the current start and end dates. Swtich focusing in onDatesChange instead.
  }

  // NOTE: This seems to fire way too often when a blocked date was selected!
  // So make sure that dates are not set to a value that will be blocked!
  onClose() {
    //Utils.log("onClose: ", this.state.focusedInput, Utils.date2param(this.originalStartDate), Utils.date2param(this.originalEndDate));

    // TODO: Not exactly right but fix this! It fires only on end date selection and fucks everything up!
    this.open = false;

    // Revert to original params.
    this.setState({startDate: this.originalStartDate, endDate: this.originalEndDate, focusedInput: null});
    // No need to fire any date changes.
    //this.state.onDatesChange(this.originalStartDate, this.originalEndDate);
    this.state.onFocusChange(null);
  }

  renderCalendarInfo() {
    if (Utils.isMobile()) {
      // Not enough space. Don't render anything.
      return null;
    }

    return <div align="center">
      <div style={{paddingTop:20, display: "flex", justifyContent: "center"}}>
        <div align="left" style={{marginRight: 20}}>
          <div className={this.props.classes.legend}>
            <div style={{width: 10, height: 10, marginRight: 10}} className={"calendar-blocked-date-partial"} />
            <Typography color="textSecondary"> Booked</Typography>
          </div>
          <div className={this.props.classes.legend}>
            <div style={{width: 10, height: 10, marginRight: 10}} className={"vehicle-calendar-unavailable"} />
            <Typography color="textSecondary"> Unavailable</Typography>
          </div>
        </div>
        <div align="left">
          <div className={this.props.classes.legend}>
            <div style={{width: 10, height: 10, marginRight: 10, position: "relative", borderTop: "12px solid #4d89e9"}} className={"calendar-date-info"} />
            <Typography color="textSecondary"> Booking Info</Typography>
          </div>
          <div className={this.props.classes.legend}>
            <div style={{width: 10, height: 10, marginRight: 10, position: "relative"}} className={"calendar-date-info"} />
            <Typography color="textSecondary"> Expense Info</Typography>
          </div>
        </div>
      </div>
      {this.state.focusedInput ? <Typography color="error"><br/>{this.generateInfoText()}</Typography> : null}
    </div>;
  }

  renderPopper() {
    if (!this.state.popperDate) {
      return null;
    }

    if (this.props.focusedInput) {
      return null; // No Popper on modal calendar.
    }

    const date = Utils.param2date(this.state.popperDate);

    let text = null;
    const vehicleEvents = this.props.vehicleEvents.filter(x =>
        Utils.date2param(x.created) === this.state.popperDate &&
        ["MAINTENANCE", "REPAIRS"].includes(x.info.type));
    const order = this.props.orders.find(x =>
        this.state.popperDate >= Utils.date2param(x.booking.startDate) &&
        this.state.popperDate <= Utils.date2param(x.booking.endDate));

    if (this.isUnavailable(date)) {
      text = Utils.prettyDateShort(date) + " is unavailable";
    } else {
      if (!vehicleEvents.length && !order) {
        return null; // Nothing to draw.
      }
    }

    return <OutsidePopper
      id="simple-popper"
      open={Boolean(this.state.popperDate)}
      anchorEl={this.state.popperAnchor}
      onClose={() => this.setState({popperDate: null, prevPopperDate: this.state.popperDate, popperAnchor: null})}
    >
      {vehicleEvents.map(vehicleEvent => <div key={vehicleEvent.id} style={{padding:10}}><VehicleEventInfo vehicleEvent={vehicleEvent} /></div>)}
      {order && <div style={{padding:10}}><OrderInfo order={order} link={true} /></div>}
      {<div><Typography color="textSecondary">{text}</Typography></div>}
    </OutsidePopper>;
  }

  render() {
    // https://github.com/airbnb/react-dates
    // 
    // See examples at airbnb storybook:
    // http://airbnb.io/react-dates
    // 
    // Code example:
    // https://github.com/airbnb/react-dates/blob/d9660019c8043349359f1fcf849f23cddbd083e3/stories/DateRangePicker_day.js
    // 
    // CSS:
    // https://github.com/airbnb/react-dates/blob/d9660019c8043349359f1fcf849f23cddbd083e3/css/storybook.scss
    return <div>
        <Paper style={{width: "100%"}}>
        {this.props.focusedInput
          ? <DateRangePicker
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.onFocusChange(focusedInput)}
              onClose={() => this.onClose()}

              isDayBlocked={date => this.isDayBlocked(date)}
              isOutsideRange={date => this.isOutsideRange(date)}
              renderDayContents={date => this.renderDayContents(date)}

              calendarInfoPosition="top"
              renderCalendarInfo={() => this.renderCalendarInfo()}
              hideKeyboardShortcutsPanel

              numberOfMonths={2}

              /* Without portal is basically broken with position: relative and opacify elements underneath */
              withPortal={!Utils.isMobile()}
              withFullScreenPortal={Utils.isMobile()}
              orientation={Utils.isMobile() ? "vertical" : "horizontal"}

              minimumNights={MIN_NIGHTS}

              startDateId="startDate"
              endDateId="endDate"
            />
          : <DayPickerRangeController
              isDayBlocked={date => this.isDayBlocked(date)}
              isOutsideRange={date => true}//this.isOutsideRange(date)}
              renderDayContents={date => this.renderDayContents(date)}

              /* https://github.com/airbnb/react-dates/issues/282 */
              focusedInput={"startDate"}
              /* Moves initial visible month to available / unavailable selection. */
              initialVisibleMonth={() => this.state.initialVisibleMonth ? this.state.initialVisibleMonth : Utils.now()}

              calendarInfoPosition="top"
              renderCalendarInfo={() => this.renderCalendarInfo()}
              hideKeyboardShortcutsPanel

              numberOfMonths={(Utils.isMobile() || Utils.isMediumScreen()) ? 1 : 2}
            />}
        </Paper>
        {this.renderPopper()}
      </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(VehicleCalendar));
