import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";

import HocUtils from "../hoc/HocUtils";

import Utils from "../common/Utils";
import Booking from "../common/Booking";
import Place from "../common/Place";

import Api from "../Api";
import Analytics from "../Analytics";
import FrontendUtils from "../FrontendUtils";

import Calendar from "../components/Calendar";
import VehicleGallery from "../components/VehicleGallery";
import Squares from "../components/Squares";
import AddressSearch from "../components/AddressSearch";
import FlexibleImage from "../components/FlexibleImage";

//import PhotoGallery from "../components/PhotoGallery";

// https://www.w3schools.com/howto/howto_css_image_text.asp
const styles = theme => ({
  photoGallery: {
    //paddingLeft: 10,
    //paddingRight: 10,
    position: "relative",
    backgroundColor: theme.palette.background.standard,
  },

  headerGrey: {
    padding: Utils.isMobile() ? 30 : 60,
    paddingTop: 20,
    paddingBottom: 30,
    borderRadius: 30,
    backgroundColor: Utils.isMobile() ? "rgba(0, 0, 0, 0.35)" : "rgba(0, 0, 0, 0.2)",

    minWidth: Utils.isMobile() ? 300 : 900,

    //minWidth: "100%",
    position: "absolute",
    top: Utils.isMobile() ? "52%" : "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  headerGreyCollapsed: {
    top: "50%",
  },

  flex: {
    display: "flex",
    alignItems: "center",
    flexDirection: Utils.isMobile() ? "column" : "row",
  },

  calendarArea: {
    borderRadius: 25,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    marginBottom: 8,
    marginTop: 20,
    marginLeft: Utils.isMobile() ? 20 : 0,
    marginRight: Utils.isMobile() ? 20 : 0,
    paddingBottom: 20,

    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
  },

  selectDates: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: 10,
    paddingTop: 10,
    paddingBottom: 0,
  },

  redArrowImg: {
    height: 60,
  },

  vehicleArea: {
    paddingTop: Utils.isMobile() ? 20 : 0,
    backgroundColor: theme.palette.background.standard,
  },

  // https://stackoverflow.com/questions/3047337/does-overflowhidden-applied-to-body-work-on-iphone-safari
  // Annoying though that it scrolls back up.
  mainAppModalOpen: {
    //position: "fixed",
    //overflow: "hidden",
  },

  advertisment: {
    backgroundColor: theme.palette.background.standard,
    paddingTop: 20,
    paddingBottom: 20,
    padding: 10,
  },

  reviews: {
    paddingTop: 20,
    paddingBottom: 20,
    padding: 10,
  },

  testimonials: {
    display: "flex",
    flexDirection: (Utils.isMobile() || Utils.isMediumScreen()) ? "column" : "row",
    justifyContent: "space-between",
  },

  testimonial: {
    textAlign: "left",
    marginLeft: 30,
    marginRight: 30,
  },
});

/*
const topPhotosDesktop =
    //["/img/tahoe-900x400.jpg", "/img/sierra-900x400.jpg", "/img/halfdome-900x400.jpg", "/img/bigsur-900x400.jpg", "/img/sequoia-900x400.jpg"];
    ["/img/sierra-1920x800.jpg"];
const topPhotosMobile =
    //["/img/tahoe-400x400.jpg", "/img/sierra-400x400.jpg", "/img/halfdome-400x400.jpg", "/img/bigsur-400x400.jpg", "/img/sequoia-400x400.jpg"];
    ["/img/sierra-400x400.jpg"];
*/

class App extends Component {
  constructor(props) {
    super(props);

    this.previousHistoryLength = 0;

    let queryParams = FrontendUtils.parseProps(props);
    let startDate = (queryParams.startDate ? Utils.param2date(queryParams.startDate) : null);
    let endDate = (queryParams.endDate ? Utils.param2date(queryParams.endDate) : null);
    let place = (queryParams.place ? Place.fromMime64(queryParams.place) : null);

    this.state = {
      startDate,
      endDate,
      place,
      calendarFocusedInput: null,
      selectedVehicle: null,
      location: "OAKLAND",
    };

    // https://css-tricks.com/working-with-refs-in-react/
    this.addressSearchRef = React.createRef();
    this.vehicleAreaRef = React.createRef();
  }

  componentDidMount() {
    // https://stackoverflow.com/questions/33188994/scroll-to-the-top-of-the-page-after-render-in-react-js
    FrontendUtils.scrollTop();

    Api.getCity().then(city => this.setState({city}));

    let queryParams = FrontendUtils.parseProps(this.props);
    let startDate = (queryParams.startDate ? Utils.param2date(queryParams.startDate) : null);
    let endDate = (queryParams.endDate ? Utils.param2date(queryParams.endDate) : null);
    let place = (queryParams.place ? Place.fromMime64(queryParams.place) : null);
    this.setState({ startDate, endDate, place });
  }

  syncState2Params() {
    let newParams = "?";
    if (this.state.startDate) {
      newParams += "startDate=" + Utils.date2param(this.state.startDate) + "&";
    }
    if (this.state.endDate) {
      newParams += "endDate=" + Utils.date2param(this.state.endDate) + "&";
    }
    if (this.state.place) {
      newParams += "place=" + this.state.place.toMime64() + "&";
    }

    this.props.history.push(newParams);
    Analytics.pageview();
  }

  renderStar() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
    </svg>;
  }

  refocus(from) {
    this.syncState2Params();

    switch (from) {
      case "place":
        Analytics.event({
          category: 'User',
          action: 'Selected a Place',
          label: (this.state.place ? this.state.place.address : "Null"),
        });
        break;

      case "date":
        Analytics.event({
          category: 'User',
          action: 'Selected a Date',
          label: Utils.date2param(this.state.startDate) + " to " + Utils.date2param(this.state.endDate),
        });
        break;

      default:
        throw new Error("Invalid refocus source: ", from);
    }

    // Disable autofocus completely, for testing.
    //return;

    // Nothing is set. Fuck it.
    if (!this.state.place && !this.state.startDate && !this.state.endDate) {
      return;
    }

    // Place is not set. focus there.
    if (!this.state.place) {
      // We just reset place. Ignore.
      if (from !== "place") {
        Utils.callWithDelay(400, () => this.addressSearchRef.focus());
      }
      return;
    }

    // Date is not set, focus there.
    if (!this.state.startDate || !this.state.endDate) {
      // We just reset date. Ignore.
      if (from !== "date") {
        // TODO: Add back? Commented out. Disable autofocus to calendar, it confuses the user.
        //Utils.callWithDelay(50, () => this.setState({calendarFocusedInput: "startDate"}));
      }
      return;
    }

    // TODO: This is not focusing on vehicles after selection :(
    //Utils.callWithDelay(1250, () => this.vehicleAreaRef.focus());
  }

  componentDidUpdate() {
    Utils.log("componentDidUpdate");

    //var paramsToState = false;
    // TODO: If is back / forward, set to true!
    //if (this.synchronizeParamsToState(paramsToState)) {
      //return;
    //}
  }

  handleLocationChange(location) {
    this.setState({ location });
  }

  render() {
    const { classes } = this.props;

    let booking = new Booking({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      vehicleType: null,
      location: this.state.location,
    });

    let addressSearch =
        <AddressSearch
            address={this.state.place ? this.state.place.address : null}
            city={this.state.city}
            setInputRef={(elem) => this.addressSearchRef = elem}
            isSmall={Utils.isMobile()}
            onAddressChange={(place) => this.setState({place}, () => this.refocus("place"))} />;

    let calendar =
        <Calendar
          booking={booking}
          focusedInput={this.state.calendarFocusedInput}
          hideBlockedDates={false}

          onDatesChange={(startDate, endDate) => this.setState({startDate, endDate}, () => this.refocus("date"))}
          onFocusChange={calendarFocusedInput => this.setState({calendarFocusedInput})}
        />;

    let headerText =
        <div>
          <Typography variant={Utils.isMobile() ? "h5" : "h4"} color="textSecondary" style={{marginBottom:10}}>
            <font color="white" face="roboto">
              Renting and RV has never been easier
            </font>
          </Typography>
          <Typography variant={Utils.isMobile() ? "subtitle1" : "subtitle1"} color="textSecondary" style={{margin:10}}>
            <font color="white" face="roboto">
              <span style={{marginRight: 20}}>Free&nbsp;Delivery</span>
              <span style={{marginRight: 20}}>Unlimited&nbsp;Miles</span>
              <span> </span>
              <span>Insurance&nbsp;Included</span>
            </font>
          </Typography>
        </div>;

    let reviews =
      <div className={classes.reviews}>
        <br/>
        <Typography variant="h6" color="textPrimary">
          Become one of our happy renters
        </Typography>
        <br/>
        <div className={classes.testimonials}>
          <Typography variant="subtitle1" color="textSecondary" className={classes.testimonial}>
            <div align="center" style={{fill:"#FFBB22"}}>
              Yarden
              <br/>
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
            </div>
            <i>
            "Traian provided the best service, the car was very clean and well equipped.
            I'm looking forward to renting this car again for a camping trip. I would highly recommend!"
            </i>
          </Typography>
            <br/>

          <Typography variant="subtitle1" color="textSecondary" className={classes.testimonial}>
            <div align="center" style={{fill:"#FFBB22"}}>
              Joseph
              <br/>
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
            </div>
            <i>"This custom built camper van should be the envy of any roadtripping adventurer and I'd do it again in a heartbeat!"</i>
          </Typography>
            <br/>

          <Typography variant="subtitle1" color="textSecondary" className={classes.testimonial}>
            <div align="center" style={{fill:"#FFBB22"}}>
              Luigi
              <br/>
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
              {this.renderStar()}
            </div>
            <i>"My wife and I had a perfect week in CA with the van. We had an awesome trip in the mountains between Bishop and Lone Pine.
            The van is perfect for a couple that like to travel light and free."</i>
          </Typography>
            <br/>
            <br/>

        </div>
        <Typography variant="caption" color="textSecondary" align="right" style={Utils.isMobile() ? {marginRight:30} : {marginRight:50}}>
          {Utils.isMobile() ? null : <br/>}
          <a style={{color:"inherit"}} href="https://www.outdoorsy.com/users/274173">More reviews</a>
          {Utils.isMobile() ? <div><br/><br/></div> : null}
        </Typography>
      </div>;

    let advertisment =
      <div className={classes.advertisment} align="center">
          <div align="center">
            <br/>
            <Typography variant="h6" color="default">
              Why Refresh Campers?
            </Typography>
            <br/>
            <Typography variant="subtitle1" color="default">
              <b>Unparalleled service</b> is our goal.
              {Utils.isMobile() ? <br/> : null}
              <br/>
              We are the <b>only</b> rental company that provides all inclusive treatment to <b>all</b> renters.
              <br/>
              {Utils.isMobile() ? null : <br/>}
            </Typography>
            <br/>
          </div>

        <Squares>
          <FlexibleImage desktopImg={"/img/landing-door-800x800.jpg"} mobileImg={"/img/landing-door-800x800.jpg"} mobileText="FREE VEHICLE DELIVERY" alt="Door" />
          <div style={{margin:20, marginTop:Utils.isMobile() ? 0 : 20}} align="center">
            {Utils.isMobile() || <Typography variant="h6" color="textPrimary">
              Free Vehicle Delivery
              <br/>
              <br/>
            </Typography>}
            <Typography variant="h6" color="textSecondary">
              We deliver to Bay Area airports, hotels, or homes. Pack your stuff, walk out, and drive away!
            </Typography>
          </div>

          <FlexibleImage desktopImg={"/img/landing-contract-800x800.jpg"} mobileImg={"/img/landing-contract-800x800.jpg"} mobileText="FREE FULL INSURANCE" alt="Contract" />
          <div style={{margin:20, marginTop:Utils.isMobile() ? 0 : 20}} align="center">
            {Utils.isMobile() || <Typography variant="h6" color="textPrimary">
              Free Full Insurance
              <br/>
              <br/>
            </Typography>}
            <Typography variant="h6" color="textSecondary">
              We include liability, collision and comprehensive insurance to all our vehicles. You are legal to drive in all 50 states with us.
            </Typography>
          </div>

          <FlexibleImage desktopImg={"/img/landing-trip-800x800.jpg"} mobileImg={"/img/landing-trip-800x800.jpg"} mobileText="UNLIMITED MILES" alt="Road" />
          <div style={{margin:20, marginTop:Utils.isMobile() ? 0 : 20}} align="center">
            {Utils.isMobile() || <Typography variant="h6" color="textPrimary">
              Unlimited Miles
              <br/>
              <br/>
            </Typography>}
            <Typography variant="h6" color="textSecondary">
              Wander freely with unlimited miles included in your trip!
            </Typography>
          </div>

          <FlexibleImage desktopImg={"/img/landing-gear-800x800.jpg"} mobileImg={"/img/landing-gear-800x800.jpg"} mobileText="EVERYTHING INCLUDED" alt="Gear" />
          <div style={{margin:20, marginTop:Utils.isMobile() ? 0 : 20}} align="center">
            {Utils.isMobile() || <Typography variant="h6" color="textPrimary">
              Everything Included
              <br/>
              <br/>
            </Typography>}
            <Typography variant="h6" color="textSecondary">
              Our vans come fully equipped with everything you need for camping.
              No overpriced add-on items -- simple, transparent pricing.
            </Typography>
          </div>

          {/*<FlexibleImage desktopImg={"/img/landing-tire-800x800.jpg"} mobileImg={"/img/landing-tire-800x800.jpg"} mobileText="24/7 ROADSIDE ASSISTANCE" alt="Road" />
          <div style={{margin:20, marginBottom:Utils.isMobile() ? 0 : 20}} align="center">
            <Typography variant="h6" color="textSecondary">
            24/7 roadside assistance
            </Typography>
            <br/>
            <Typography variant="subtitle1" color="textSecondary">
            Conveniently priced -- we have your back wherever you go, whenever you need us.
            </Typography>
          </div>*/}
        </Squares>
        <br/>
        <br/>
        <br/>
        <Typography variant="caption" color="textSecondary">
          Insurance provided by
        </Typography>
        <div style={{width: 220}}>
          <FlexibleImage desktopImg={"/img/arrc.png"} mobileImg={"/img/arrc.png"} alt="ARRC logo" />
        </div>
        <br/>
        <br/>
      </div>;

    let isCollapsed = this.state.startDate && this.state.endDate && this.state.place;

    let header = (
        <div>
          <div className={classes.photoGallery}>
            <Collapse in={!(this.state.startDate && this.state.endDate && this.state.place)} collapsedHeight="300px">
              <FlexibleImage desktopImg="/img/sierra-1920x800.jpg" mobileImg="/img/sierra-400x600.jpg" alt="Sierra Mountains"
                             h={isCollapsed ? 300 : 0} />
            </Collapse>

            {/*
            <PhotoGallery photos={Utils.isMobile() ? topPhotosMobile : topPhotosDesktop} fullSize={true} autoNextMs={4000} />
            */}
            <div className={classes.headerGrey + " " + (isCollapsed ? classes.headerGreyCollapsed : "")}>
              {headerText}
              <div className={classes.flex} style={{justifyContent: "center", marginTop: 20}}>
                {addressSearch}
                <div style={{margin: 10}} />
                {calendar}
              </div>
            <Paper className={classes.calendarArea} elevation={this.state.startDate && this.state.endDate ? 4 : 1}>
            </Paper>
            </div>
          </div>
        </div>);

    return (
         <div className={this.state.selectedVehicle ? classes.mainAppModalOpen : ""}
              ref={(elem) => this.vehicleAreaRef = elem}>
            {header}
            {this.state.startDate && this.state.endDate && this.state.place
              ? <div className={classes.vehicleArea}>
                  <br/>
                  {this.renderVehicleGallery(booking)}
                </div>
              : null}
            {advertisment}
            <FlexibleImage desktopImg={"/img/autumn-1920x700.jpg"} mobileImg={"/img/autumn-500x334.jpg"} alt="Van in forest during fall" />
            {reviews}
            <FlexibleImage desktopImg="/img/inside-1920x800.jpg" mobileImg="/img/inside-500x357.jpg" alt="Camper van interior" isBookNow={true} />
          </div>);
  }

  selectVehicle(vehicleInfo) {
    let bookPath =
        "/book?val=" + Utils.obfuscateParams(this.createCheckoutParams(vehicleInfo));
    this.props.history.push(bookPath, {canGoBack: true});
  }

  renderVehicleGallery(booking) {
    // TODO: Transition: https://material-ui.com/utils/transitions/
    // or collapse https://material-ui.com/demos/steppers/#vertical-stepper
    return <VehicleGallery
            booking={booking}
            selectButtonText="SELECT"
            focusAfterLoad={false}
            //addComingSoon={true}
            onSelect={vehicleInfo => this.selectVehicle(vehicleInfo)}
          />;
  }

  maybeSelectVehicle(vehicleType) {
    if (this.state.selectedVehicle === vehicleType) {
      this.setState({ selectedVehicle:null });
    } else {
      this.setState({ selectedVehicle:vehicleType });
    }
  };

  createCheckoutParams(vehicleInfo) {
    return "start=" + Utils.date2param(this.state.startDate) +
           "&end=" + Utils.date2param(this.state.endDate) +
           "&location=" + this.state.location +
           (this.state.place ? ("&place=" + this.state.place.toMime64()) : "") +
           "&city=" + this.state.city +
           "&vehicle=" + vehicleInfo.vehicleType;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(App));
