import Booking from "./Booking";
import Order from "./Order";
import User from "./User";
import Vehicle from "./Vehicle";
import VehicleEvent from "./VehicleEvent";

export default class OrmPool {

  static create(jsonPool) {
    // Utils.log("jsonPool: ", jsonPool);

    let bookings = jsonPool.bookings.map(x => new Booking(x));
    let orders = jsonPool.orders.map(x => new Order(x));
    let users = jsonPool.users.map(x => new User(x));
    let vehicles = jsonPool.vehicles.map(x => new Vehicle(x));
    let vehicleEvents = jsonPool.vehicleEvents.map(x => new VehicleEvent(x));

    // Booking -> renter, vehicle.
    for (let x of bookings) {
      x.info.renter = users.find(y => y.id === x.info.renter_id);
      x.info.vehicle = vehicles.find(y => y.id === x.info.vehicle_id);
    }
    // Order -> booking
    for (let x of orders) {
      x.booking = bookings.find(y => y.bookingCode === x.bookingCode);
    }
    // Vehicle -> owner
    for (let x of vehicles) {
      x.owner = users.find(y => y.id === x.owner_id);
    }
    // VehicleEvent -> vehicle
    for (let x of vehicleEvents) {
      x.vehicle = vehicles.find(y => y.id === x.vehicle_id);
    }

    return { bookings, orders, users, vehicles, vehicleEvents };
  }
}

