import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Utils from "../common/Utils";

import BookNow from "./BookNow";

const styles = theme => ({
  background: {
    position: "relative",
    backgroundColor: theme.palette.background.standard,
    width: "100%",
    height: "100%",
  },

  greyOverlay: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "white",

    //minWidth: 250,
    minWidth: "100%",

    position: "absolute",
    //top: "38px",
    //top: Utils.isMobile() ? "68px" : "98px", // 38 + 30 / 60
    top: "50%",
    //calc(100% - 50px)",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  mobilePic: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    maxHeight: 600,
  },

  desktopPic: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    maxHeight: 600,
  },
});

class FlexibleImage extends Component {

  render() {
    const { classes } = this.props;

    // Inspiration websites:
    // outdoorsy.com
    // rvshare.com
    // turo.com -- main pic 600 high no matter what, other pics 375 high (square on Iphone 6)

    // Small version: aspect ratio 1:1 (for mobile)
    // Large version: aspect ratio 2.5:1 (1366x550)

    // Most common desktop resolutions in USA:
    // http://gs.statcounter.com/screen-resolution-stats/desktop/united-states-of-america
    // 1920 x 1080 (aspect ratio 1.77)
    // 1366 x 768 (aspect ratio 1.77)
    // Mac OS: 2560 x 1600 (aspect ratio 1.6)

    // Most common mobile resolutions in USA:
    // http://gs.statcounter.com/screen-resolution-stats/mobile/united-states-of-america
    // 375 x 667 (iphone 6/7/8)
    // 360 x 640 (Galaxy S5
    // 414 x 736 (iphone 6/7/8 Plus)

    // verticalAlign: "bottom" explanation:
    // https://stackoverflow.com/questions/8828215/css-a-2-pixel-line-appears-below-image-img-element

    return <div className={classes.background}>
        <img
            style={this.props.h ? {height: this.props.h, verticalAlign: "bottom"} : {verticalAlign: "bottom"}}
            src={Utils.isMobile() ? (this.props.mobileImg ? this.props.mobileImg : this.props.src)
                                  : (this.props.desktopImg ? this.props.desktopImg : this.props.src)}
            className={Utils.isMobile() ? classes.mobilePic : classes.desktopPic}
            alt={this.props.alt} />
        {this.props.mobileText && Utils.isMobile() && <div className={classes.greyOverlay} align="center">{this.props.mobileText}</div>}
        {this.props.isBookNow
          ? <div className={classes.greyOverlay}>
              <BookNow />
            </div>
          : ""}
      </div>
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(FlexibleImage));

