import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import HocUtils from "../hoc/HocUtils";

//import ExpPanel from "./ExpPanel";

const styles = theme => ({
  mainArea: {
    backgroundColor: theme.palette.background.standard,
    padding: 30,
  },
});

  // Make bed
  // Roll curtains
  // Extra car key
  // Use sink
  // 110V AC power
  // Storage: ceiling, glove box, under bed
  // Stove
  // Spare tire
  // Dead battery
  // Lights
  // Doors and windows
  // Extras: camping gear, bluetooth

/*
const instructions = [
  [
    "Items included in the minivan rental",
    "abc"
  ],
  [
    "How to make the bed",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse",
  ],
  [
    "How to use the curtains",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, "
  ],
  [
    "Extra car key",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, "
  ],
];
*/

class Instructions extends Component {
  render() {
    return <div align="center" className={this.props.classes.mainArea}>
      <Typography color="textSecondary" variant="h6">
        Coming soon
      </Typography>
    </div>;
    //return <ExpPanel contents={instructions} />;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Instructions));

