import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

// Note: Text should be over 16px...
// https://stackoverflow.com/questions/6483425/prevent-iphone-from-zooming-in-on-select-in-web-app

const styles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    maxHeight: "100%",
    padding: 10,
  },

  modalInner: {
    backgroundColor: theme.palette.background.standard,
    maxHeight: "calc(100vh - 40px)",
    overflowY: "scroll",
  },

  modalHeader: {
    position: "relative",
    height: 48,
    display: "flex",
  },

  modalHeaderClose: {
    position: "absolute",
    top: 0,
    right: 0,
  },

  modalHeaderTitle: {
    margin: "auto",
  },
});

class ClosableModal extends Component {
  constructor(props) {
    super(props);

    // Share state between parent and child
    // https://www.javascriptstuff.com/component-communication/#4-event-bubblin
    this.state = {
      open: props.open,
      onClose: props.onClose,
    };
  }

  // Update child:
  // https://developmentarc.gitbooks.io/react-indepth/content/life_cycle/update/component_will_receive_props.html
  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
      onClose: nextProps.onClose,
    });
  }

  close() {
    this.state.onClose();
  }

  render() {
    const { classes } = this.props;

    // Disable scroll
    //document.documentElement.style.overflow = this.state.open ? "hidden" : "";
    // To disable modal scrolling on desktop only (mobile won't get disabled), redo this:
    // https://github.com/straian/refresh-campers-frontend/commit/511c89c9ef6b347145960a701eb28006c18ec5f0

    return (
      <Modal
          // https://material-ui.com/api/backdrop/#css-api
          BackdropProps={{classes: {root: "modalBackdrop"}}}
          className={classes.modal}
          aria-labelledby="closable-modal-title"
          aria-describedby="closable-modal-description"
          onClose={() => this.close()}
          open={this.state.open}>
        <Paper className={classes.modalInner}>
          <div className={classes.modalHeader}>
            <div align="center" className={classes.modalHeaderTitle}>
              <Typography variant="subtitle1">
                {this.props.title}
              </Typography>
            </div>
            <div className={classes.modalHeaderClose}>
              <IconButton onClick={() => this.close()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div align="center" style={this.props.noMargin ? {marginBottom:10, paddingBottom:10} : {margin:10, padding:10}}>
            {this.props.children}
          </div>
        </Paper>
      </Modal>);
  }
}

export default withStyles(styles, { withTheme: true })(ClosableModal);
