import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withRouter } from "react-router-dom";

import Utils from "../common/Utils";
import Order from "../common/Order";

import Analytics from "../Analytics";
import Api from "../Api";
import FrontendUtils from "../FrontendUtils";
import VehicleInfoContents from "../VehicleInfoContents";

import FlexibleImage from "./FlexibleImage";
import FreeCancel from "./FreeCancel";

const styles = theme => ({
  // https://stackoverflow.com/questions/3047337/does-overflowhidden-applied-to-body-work-on-iphone-safari
  // Annoying though that it scrolls back up.
  mainAppModalOpen: {
    //position: "fixed",
    //overflow: "hidden",
  },

  vehicleArea: {
    // https://stackoverflow.com/questions/3875195/google-chrome-textboxes-yellow-border-when-active
    outline: 0,

    // https://www.w3schools.com/css/css3_flexbox.asp#flex-wrap
    justifyContent: "center",
    //alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    //padding: 25,

    backgroundColor: theme.palette.background.standard,
  },

  vehicle: {
    position: "relative",
    maxWidth: Utils.isMobile() ? 480 : 480,
    width: "100%",
    margin: Utils.isMobile() ? 20 : 15,
    paddingBottom: 15,
    marginBottom: 25,
  },

  vehicleUnavailable: {
    opacity: 0.4,
  },

  vehicleMenu: {
    margin: 20,
    marginBottom: 0,
  },

  price: {
    position: "absolute",
    margin: 10,
    //borderBottomLeftRadius: 20,
    borderRadius: 10,
    padding: 10,

    top: "0%",
    right: "0%",
    //transform: "translate(0%, -50%)",
    color: "white",
    //backgroundColor: "white",
    backgroundColor: theme.palette.primary.dark, //background.standard, //theme.palette.primary,
  },

  priceTotal: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",

    position: "absolute",
    margin: 10,
    padding: 5,

    top: "0%",
    right: "0%",
    //transform: "translate(0%, -50%)",
    backgroundColor: theme.palette.primary.dark, //background.standard, //theme.palette.primary,
  },
});

class VehicleGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: this.props.booking,
      selectedVehicle: null,
      vehicles: null,
    };
  }

  // Might have other ways but...
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
  static getDerivedStateFromProps(props, prevState) {
    let state = {};
    FrontendUtils.prop2state(state, prevState, "booking", props.booking);
    return state;
  }

  componentDidMount() {
    Analytics.pageview("/vehiclegallery");
    this.componentDidUpdate(null, null);
  }

  // Not called on inital load.
  componentDidUpdate(prevProps, prevState) {
    Utils.log("componentDidUpdate");

    if (this.props.focusAfterLoad && this.vehicleArea) {
      this.vehicleArea.focus();
    }

    if (prevProps !== this.props) {
      //Utils.log("Different props: ", prevProps, this.props);

      Api.searchVehicles(
          this.state.booking.location, null /* vehicleType */, this.state.booking.startDate, this.state.booking.endDate, this.state.booking.bookingCode,
          vehicles => this.setState({vehicles: VehicleInfoContents.flattenAndDecorate(vehicles, this.props.addComingSoon)}));
    }
  }

  render() {
    const { classes } = this.props;

    let vehicleArea = null;
    if (this.state.vehicles === null) {
      vehicleArea = <CircularProgress />;
    } else if (this.state.vehicles) {
      if (this.state.vehicles.length === 0) {
        vehicleArea = <p>No vehicles found for requested dates</p>;
      } else if (this.state.booking.startDate && this.state.booking.endDate) {
        var vehicles = [];
        vehicles.push(this.addVehicles(
            this.state.vehicles, this.state.booking.startDate, this.state.booking.endDate, this.state.selectedVehicle));
        // https://stackoverflow.com/questions/3656467/is-it-possible-to-focus-on-a-div-using-javascript-focus-function
        vehicleArea = <div className={classes.vehicleArea} tabIndex="0" ref={input => this.vehicleArea = input}>
          {vehicles}
        </div>;
      }
    }

    return <div className={this.state.selectedVehicle ? classes.mainAppModalOpen : ""}>
      {vehicleArea}
    </div>;
  }

  addVehicles(vehicles, startDate, endDate, selectedVehicle) {
    var numNights = endDate.diff(startDate, "days");
    Utils.assert(numNights > 0, "Invalid date selection");

    var renderedVehicles = [];
    for (var i in vehicles) {
      var isSelected = false;
      if (selectedVehicle && vehicles[i].vehicleType !== selectedVehicle) {
        //continue;
      }
      if (selectedVehicle && vehicles[i].vehicleType === selectedVehicle) {
        isSelected = true;
      }
      //Utils.log(vehicles);
      let renderedVehicle = this.renderVehicle(i, vehicles[i], numNights, isSelected);
      renderedVehicles.push(renderedVehicle);
    }
    return renderedVehicles;
  }

  getVariant(variant) {
    switch (variant) {
      case "": return Utils.isMobile() ? null : "subtitle1";
      case "body": return Utils.isMobile() ? "caption" : "body2";
      case "subtitle1": return Utils.isMobile() ? "subtitle1" : "h6";
      default:
        throw new Error("Unsupported variant: ", variant);
    }
  }

  renderVehicle(i, vehicleInfo, numNights, isSelected) {
    const { classes } = this.props;

    let order = new Order({info: vehicleInfo}, this.state.booking, null /* previousOrder */, null /* previousBooking */);

    let dailyPrice = order.info.pricing ? (order.vehiclePrice(true) / order.numDays()) : 0;
    let dailyPriceDiscounted = dailyPrice ? (order.vehiclePrice() / order.numDays()) : 0;
    let discountRate = dailyPrice && order.info.pricing.discount && order.info.pricing.discount.rate ? Utils.rate2percent(order.info.pricing.discount.rate) : null;
    let unavailable = vehicleInfo.comingSoon || !dailyPrice;

    return (
        <Paper key={i} className={classes.vehicle + " " + (unavailable ? classes.vehicleUnavailable : "pointerOnHover")}
               onClick={unavailable ? null : () => this.props.onSelect(vehicleInfo)}>
        <div align="center">
          <FlexibleImage desktopImg={vehicleInfo.photoUrl} mobileImg={vehicleInfo.photoUrlMobile} alt={vehicleInfo.vehicleName} />
          <div className={classes.price}>
            <div style={{display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
              <Typography variant={this.getVariant("subtitle1")} color="inherit">
                {vehicleInfo.comingSoon || unavailable || !dailyPrice
                  ? (Utils.prettyMoney(VehicleInfoContents.getLowerPrice(vehicleInfo.vehicleType)) + " - " +
                     Utils.prettyMoney(VehicleInfoContents.getHigherPrice(vehicleInfo.vehicleType)))
                  : Utils.prettyMoney(dailyPriceDiscounted)}
              </Typography>
              <Typography variant={this.getVariant("")} color="inherit">
                &nbsp;/ day
              </Typography>
            </div>
            {discountRate &&
                <div style={{display:"flex", justifyContent:"flex-end"}}>
                  <Typography variant={this.getVariant("body")} color="inherit" style={{textDecoration:"line-through"}}>
                    {Utils.prettyMoney(dailyPrice, false, true)}
                  </Typography>
                  <Typography variant={this.getVariant("body")} color="inherit">
                    &nbsp;({discountRate} off for {order.info.pricing.discount.threshold}+ days)
                  </Typography>
                </div>}
          </div>
          {/* !unavailable */false &&
              <div className={classes.priceTotal}>
                <Typography variant={this.getVariant("subtitle1")} style={{color:"white"}}>
                  {Utils.prettyMoney(order.vehiclePrice())}
                </Typography>
                <Typography variant={this.getVariant("")} style={{color:"white"}}>
                  &nbsp;for
                </Typography>
                <Typography variant={this.getVariant("subtitle1")} style={{color:"white"}}>
                  &nbsp;{numNights}&nbsp;
                </Typography>
                <Typography variant={this.getVariant("")} style={{color:"white"}}>
                  nights
                </Typography>
              </div>}

          <div className={classes.vehicleMenu} align={Utils.isMobile() ? "center" : "left"}>
            <Typography variant="h6">
              {vehicleInfo.vehicleName + (unavailable ? " -  Not available" : "")}
            </Typography>
          </div>
          <Typography align="left" className={"gradientTransparent"} style={{maxHeight:40, margin:20}}>
            {vehicleInfo.description.join(" ")}
          </Typography>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginLeft: 10, marginRight:10}}>
            <Button variant="contained" color={"primary"} size="large" disabled={unavailable}>
              SELECT
            </Button>
            <br/>
            <div style={{visibility: (unavailable ? "hidden" : "visible")}}>
            <FreeCancel startDate={this.state.booking.startDate} />
            </div>
          </div>
        </div>
        </Paper>);
  }

  createCheckoutParams(vehicleInfo) {
    return "start=" + Utils.date2param(this.state.booking.startDate) +
           "&end=" + Utils.date2param(this.state.booking.endDate) +
           "&location=" + this.state.booking.location +
           "&vehicle=" + vehicleInfo.vehicleType;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(VehicleGallery));
