import React from "react";

import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import FrontendUtils from "../FrontendUtils";

const styles = theme => ({
});

class BookNow extends React.Component {
  redirect(path) {
    if (path === this.props.history.location.pathname) {
      FrontendUtils.scrollTop();
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return <Button color="primary" variant="contained" size="large" onClick={() => this.redirect("/")}>Book Now</Button>
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(BookNow));
