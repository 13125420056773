import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import Api from "../Api";
import Analytics from "../Analytics";

import AlertDialog from "./AlertDialog";
import TextFieldValidated from "./TextFieldValidated";

import HocUtils from "../hoc/HocUtils";
import Utils from "../common/Utils";

const styles = theme => ({
  classSelect: {
    width: 100,
    margin: 10,
    marginLeft: Utils.isMobile() ? 10 : 30,
  },

  primary: {
    color: theme.palette.primary.dark,
  },
});

const CLASS_A_MAX = 1744000;
const CLASS_B_MAX = 1826000;
const CLASS_C_MAX = 1613000;

function estimateRevenue(year, rvClass) {
  Utils.log("estimateRevenue: ", year, rvClass);
  // Under 2004: 70%
  // 2005: 75%
  // 2006: 80%
  // 2007: 85%
  // 2008: 90%
  // 2009: 95%
  // 2010+: 100%
  let percentage = .7;
  if (year > 2004) {
    switch (year) {
      case 2005: percentage = .75; break;
      case 2006: percentage = .80; break;
      case 2007: percentage = .85; break;
      case 2008: percentage = .90; break;
      case 2009: percentage = .95; break;
      default: percentage = 1; break;
    }
  }

  let base = CLASS_C_MAX;
  switch (rvClass) {
    case "Class A":
      base = CLASS_A_MAX; break;
    case "Class B":
      base = CLASS_B_MAX; break;
    case "Class C":
    default:
      base = CLASS_C_MAX; break;
  };

  return Math.round(percentage * base, 0);
}

function isValidModel(model) {
  // Exists.
  return Boolean(model);
}

function isValidYear(year) {
  year = parseInt(year);
  if (!year || year < 0 || year > 2020) {
    return false;
  }
  return year < 100 || (year > 1950 && year);
}

function isValidLength(length) {
  length = parseInt(length);
  return length && length > 0 && length < 60;
}

function isValidStreetAddress(streetAddress) {
  // Exists.
  return Boolean(streetAddress);
}

function initialValues() {
  return {
    make: "",
    model: "",
    year: "",
    length: "",
    rvClass: "",
    streetAddress: "",
    city: "",
    state: "CA",
    zip: "",
    name: "",
    email: "",
    phone: "",
  };
}

class ListVehicleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      errorMessageEstimate: "",
      errorMessageSubmit: "",
      values: initialValues(),
      estimatedRevenue: null,
    };

    this.skipValidation = true;
  }

  isValid(field, formName) {
    if (this.skipValidation) {
      return true;
    }

    // Ignore validation for the wrong form.
    if (!formName) {
      // Validation only used for coloring. Use the state we're in.
      formName = this.state.estimatedRevenue ? "submit" : "estimate";
    }
    const fieldForm = (field === "email" || field === "phone") ? "submit" : "estimate";
    if (fieldForm !== formName) {
      return true;
    }

    let value = this.state.values[field];
    if (!value) {
      return false;
    }

    switch (field) {
      case "make":
        return Utils.isValidName(value);
      case "model":
        return isValidModel(value);
      case "year":
        return isValidYear(value);
      case "length":
        return isValidLength(value);
      case "rvClass":
        return Boolean(value);
      case "streetAddress":
        return isValidStreetAddress(value);
      case "city":
        return Utils.isValidName(value);
      case "state":
        return Boolean(value);
      case "zip":
        return Utils.isValidZip(value);
      case "name":
        return Utils.isValidName(value);
      case "email":
        return Utils.isValidEmail(value);
      case "phone":
        return Utils.isValidPhone(value);
      default:
        throw new Error("Invalid field: " + field);
    }
  }

  handleChange(key, value) {
    let json = {};
    json[key] = value;
    let values = Utils.updateImmutable(this.state.values, json);
    this.setState({values, errorMessageEstimate: null, errorMessageSubmit: null});
  }

  validate(formName) {
    if (!this.isValid("make", formName) ||
        !this.isValid("model", formName) ||
        !this.isValid("year", formName) ||
        //!this.isValid("length", formName) ||
        !this.isValid("rvClass", formName) ||
        //!this.isValid("streetAddress", formName) ||
        //!this.isValid("city", formName) ||
        //!this.isValid("state", formName) ||
        !this.isValid("zip", formName) ||
        !this.isValid("email", formName)) {
        //!this.isValid("phone", formName)) {
      return false;
    }

    return true;
  }

  handleEstimate() {
    this.skipValidation = false;
    this.setState({estimatedRevenue: null});
    if (!this.validate("estimate")) {
      this.setState({errorMessageEstimate: "At least one input is invalid"});
      return false;
    }

    // Note: Email is not yet known.
    Api.submitListVehicleRequest(this.state.values)
        .then(result => {
          Analytics.pageview("/estimaterv-req");
          this.setState({errorMessageEstimate: "", errorMessageSubmit: "", estimating: true});
          this.skipValidation = true;
          const estimatedRevenue = estimateRevenue(this.state.values["year"], this.state.values["rvClass"]);
          Utils.callWithDelay(1500, () => this.setState({estimatedRevenue, estimating: false}));
          return true;
        })
        .catch(error => {
          this.setState({errorMessageEstimate: "Error sending request to server"});
          return false;
        });
  }

  handleSubmit() {
    this.skipValidation = false;
    if (!this.validate("submit")) {
      this.setState({errorMessageSubmit: "At least one input is invalid"});
      return false;
    }

    Api.submitListVehicleRequest(this.state.values)
        .then(result => {
          Analytics.pageview("/listrv-req");
          this.setState({modalOpen: false, dialogOpen: true, errorMessageSubmit: ""});
          this.skipValidation = true;
          this.handleChange("email", "");
          this.handleChange("phone", "");
          return true;
        })
        .catch(error => {
          this.setState({errorMessageSubmit: "Error sending request to server"});
          return false;
        });
  }

  renderTextField(id, label) {
    return <TextFieldValidated id={id} label={label} value={this.state.values[id]} isValid={() => this.isValid(id)} onChange={value => this.handleChange(id, value)} />;
  }

  render() {
    const { classes } = this.props;

    return <div style={{minWidth: 250}}>
      {(this.state.estimating || this.state.estimatedRevenue)
        ? null
        : <form style={{marginBottom:20}}
                // eslint-disable-next-line no-script-url
                action="javascript:void(0);">
            <Typography color="textPrimary" variant="h6" style={{margin:10}}>
              <b>SEE HOW MUCH YOUR RV CAN MAKE IN 2019</b>
              <br/>
            </Typography>
            <div align="center">
              {/*this.renderTextField("length", "Length (ft)")*/}
              <div align={Utils.isMobile() ? "center" : "center"}>
                <FormControl className={classes.classSelect}>
                  <InputLabel htmlFor="rv-class">Class</InputLabel>
                  <Select
                      value={this.state.values["rvClass"]}
                      {...(this.isValid("rvClass") ? {} : {error: true})}
                      onChange={event => this.handleChange("rvClass", event.target.value)}
                      inputProps={{name: 'Class', id: "rv-class"}}
                  >
                    <MenuItem value={"Class A"}>Class A</MenuItem>
                    <MenuItem value={"Class B"}>Class B</MenuItem>
                    <MenuItem value={"Class C"}>Class C</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {this.renderTextField("make", "Make")}
              {this.renderTextField("model", "Model")}
              <br/>
              {/*this.renderTextField("streetAddress", "Street Address when Parked")*/}
              {/*<br/>*/}
              {/*this.renderTextField("city", "City")*/}
              {/*this.renderTextField("state", "State")*/}
              {/*<div align={Utils.isMobile() ? "center" : "left"}>*/}
              {this.renderTextField("year", "Year")}
              {this.renderTextField("zip", "Zip Code")}
              {/*</div>*/}
            </div>
            <br/>
            <Typography variant="body1" color="error">
              {this.state.errorMessageEstimate}
            </Typography>
            <Button type="submit" variant="contained" color="primary" size="large"
                    onClick={() => this.handleEstimate()}>
              Get estimate
            </Button>
          </form>}
      {this.state.estimating && <div style={{marginTop: 60, marginBottom: 40}}><CircularProgress /></div>}
      {this.state.estimatedRevenue &&
        <div>
          <Typography color="textSecondary" variant="h6">
            Estimated revenue
          </Typography>
          <Typography color="textSecondary" variant="h4">
            <b className={this.props.classes.primary}>{Utils.prettyMoney(this.state.estimatedRevenue)}</b>
          </Typography>
          <br/>
          <Typography color={Utils.isMobile() ? "textPrimary" : "textSecondary"} variant={Utils.isMobile() ? "subtitle1" : "h6"}>
            Sounds good?
            <br/>
            Leave your contact information so we can reach you
          </Typography>
          <br/>
          <form
              // eslint-disable-next-line no-script-url
              action="javascript:void(0);">
            <div align="center">
              {this.renderTextField("email", "Email")}
              {this.renderTextField("phone", "Phone")}
            </div>
              <br/>
            <Typography variant="body1" color="error">
              {this.state.errorMessageSubmit}
            </Typography>
            <Button type="submit" variant="contained" color="primary" size="large"
                    onClick={() => this.handleSubmit()}>
              Submit
            </Button>
          </form>
        </div>}
      <AlertDialog
          open={this.state.dialogOpen}
          onClose={() => this.setState({dialogOpen: false})}
          text={"Your information was sent. You will receive a response in a couple of days"}
      />
    </div>;
  }
}

export default HocUtils.withRefreshOnResize(withRouter(withStyles(styles, { withTheme: true })(ListVehicleForm)));
