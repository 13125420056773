import React from "react";

let oldWidth = window.innerWidth;
let oldHeight = window.innerHeight;

// Originally for maps: https://github.com/tomchentw/react-google-maps/issues/392
// http://www.logeshpaul.com/blog/handling-browser-window-resize-in-reactjs/
export default function withRefreshOnResize(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    onResize() {
      if (window.innerWidth === oldWidth && window.innerHeight === oldHeight) {
        return;
      }
      oldWidth = window.innerWidth;
      oldHeight = window.innerHeight;

      this.setState(this.state);
    }

    componentDidMount() {
      window.addEventListener("resize", () => this.onResize());
    }

    componentWillUnmount() {
      window.removeEventListener("resize", () => this.onResize());
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
