import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import HocUtils from "../hoc/HocUtils";

const styles = theme => ({
});

class Tips extends Component {
  render() {
    return (
        <div align="left">
          <p>How to put stuff, how to boondock, other advice I may blog</p>
        </div>);
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Tips));

