import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookProvider, { Like } from 'react-facebook';
//import { SocialIcon } from 'react-social-icons';
import "typeface-roboto";

import Config from "../Config";

import Utils from "../common/Utils";

// https://css-tricks.com/snippets/css/a-guide-to-flexbox/
const styles = theme => ({
  container: {
    //background: theme.palette.background.standard,
  },

  footerContainer: {
    flexDirection: Utils.isMobile() ? "column" : "row",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 30,
    paddingBottom: 20,
  },

  icon: {
    maxWidth: Utils.isMobile() ? 32 : 40,
    maxHeight: Utils.isMobile() ? 32 : 40,
    borderRadius: "50%",
    marginRight: 5,
  },

  image: {
    maxWidth: "100%",
    height: "auto",
    //maxWidth: 800,
  },

  footerItem: {
    margin: 10, //Utils.isMobile() ? 10 : 10,
    //flexBasis: Utils.isMobile() ? "100%" : "33%",
    //flexGrow: 0,
  },

  termsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
});

class Footer extends Component {
  constructor(props) {
    super(props);

    this.previousHistoryLength = 0;

    this.state = {
    };
  }

  render() {
    const { classes } = this.props;

    // https://stackoverflow.com/questions/37712038/material-ui-change-the-height-of-the-drawer
    // https://stackoverflow.com/questions/36651583/dynamically-add-child-components-in-react

    // TODO: This is slower than simple react redirect, try that instead?
    return <div className={classes.container}>
        <div align="center" className={classes.footerContainer}>

          <div className={classes.footerItem}>
            <Typography variant="subtitle1" color="textSecondary">
              <a style={{color:"inherit"}} href="/">Book now</a>
              <br/>
              <br/>
              <a style={{color:"inherit"}} href="how-it-works">How it works</a>
              <br/>
              <br/>
              <a style={{color:"inherit"}} href="policies">Policies</a>
            </Typography>
          </div>

          <div className={classes.footerItem}>
            <Typography variant="subtitle1" color="textSecondary">
              <a style={{color:"inherit"}} href="about">About us</a>
              <br/>
              <br/>
              <a style={{color:"inherit"}} href="vehicles">Our vans</a>
              <br/>
              <br/>
              {Config.get("OWNER_MARKETING_MODE")
                  ? <a style={{color:"inherit"}} href="list-your-rv">List Your RV</a>
                  : <a style={{color:"inherit"}} href="dashboard">RV Owner Dash</a>}
              <br/>
            </Typography>
          </div>

          <div className={classes.footerItem}>
            {this.renderContact()}
          </div>
        </div>
        <Typography variant="caption" color="textSecondary" className={classes.termsContainer}>
          <img src={"/img/favicon3-128x128.png"} className={classes.icon} alt="Refresh Campers Icon"/>
          <div style={{margin:5}}>
            &copy; 2018 Refresh Campers
          </div>
          <div style={{margin:5}}>
            <a style={{color:"inherit"}} href="Terms.pdf">Terms</a>
          </div>
          <div style={{margin:5}}>
            <a style={{color:"inherit"}} href="Privacy.pdf">Privacy</a>
          </div>
          <div style={{margin:5}}>
            <a style={{color:"inherit"}} href="/list-your-rv">List Your RV</a>
          </div>
          <div style={{margin:5}}>
            <a style={{color:"inherit"}} href="/admin">Admin</a>
          </div>
        </Typography>
      </div>;
  }

  renderContact() {
    return <div align="center">
          <div>
            {Utils.isMobile() ? <div><Divider /><br/></div> : null}
            <Typography variant="subtitle1" color="textSecondary"  style={{userSelect: "text"}} align="center">
              Contact
            </Typography>
            <table>
              <tbody>
                <tr>
                  <td>
                    <a style={{color:"inherit"}} href="tel:+1 (510) 906-1665">
                      <IconButton>
                        <PhoneIcon />
                      </IconButton>
                    </a>
                  </td>
                  <td>
                    <a style={{color:"inherit"}} href="tel:+1 (510) 906-1665">
                      <Typography color="textSecondary"  style={{userSelect: "text"}}>
                        +1 (510) 906-1665
                      </Typography>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a style={{color:"inherit"}} href="mailto:office@refreshcampers.com">
                      <IconButton>
                        <EmailIcon />
                      </IconButton>
                    </a>
                  </td>
                  <td>
                    <a style={{color:"inherit"}} href="mailto:office@refreshcampers.com">
                      <Typography color="textSecondary"  style={{userSelect: "text"}}>
                        office@refreshcampers.com
                      </Typography>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* too wide for mobile, and does not align well on desktop */}
            {true || Utils.isMobile()
              ? ""
              : <FacebookProvider appId="refreshcampers">
                  <Like href="http://www.facebook.com/refreshcampers" colorScheme="dark" showFaces share />
                </FacebookProvider>}
            {/*TODO: Put back in after I get some likes / followers
             <div align="center" >
                <SocialIcon url="https://www.instagram.com/refreshcampers/" style={{marginRight:10}} />
                <SocialIcon url="https://www.facebook.com/refreshcampers/" style={{marginLeft:10}} />
            </div>*/}
          </div>
        </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Footer));

