import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import Utils from "../common/Utils";

import HocUtils from "../hoc/HocUtils";

const styles = theme => ({
  form: {
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

const states = [
  ["AL", "Alabama"],
  ["AK", "Alaska"],
  ["AZ", "Arizona"],
];

class Drivers extends Component {
  constructor(props) {
    super(props);

    //Utils.log("CONSTRUCTOR PROPS ", props);

    //let queryParams = queryString.parse(this.props.location.search);
    //let lookupPromise = null;
/*
    if (queryParams.val) {
      let decodedParams = queryString.parse(Utils.deobfuscateParams(queryParams.val));

      Utils.log("Decoded params: ", decodedParams);
      let booking = new Booking({
        location: decodedParams.location,
        vehicleType: decodedParams.vehicle,
        startDate: decodedParams.start,
        endDate: decodedParams.end,
        info: {
          extras: [],
        }
      });
      lookupPromise = Promise.resolve({booking, order: null});
    } else {
      lookupPromise = Api.lookupBooking(queryParams.bookingCode);
    }*/

    this.state = {
      drivers: [],

      currentDriver: {
        firstName: "",

        lastName: "",

        dateOfBirth: "",

        driversLicenseNumber: "",

        driversLicenseState: "",
      },
    };
  }

  handleChange(key, value) {
    let json = {};
    json[key] = value;
    Utils.log("XXX: ", key, value);
    let currentDriver = Utils.updateImmutable(this.state.currentDriver, json);
    Utils.log("yYYY: ", currentDriver);
    this.setState({currentDriver});
  }

  render() {
    const { classes } = this.props;

    return <div>
      <form className={classes.form} noValidate autoComplete="on">
        <TextField
          id="firstName"
          label="First Name"
          className={classes.textField}
          value={this.state.currentDriver.firstName}
          onChange={event => this.handleChange("firstName", event.target.value)}
          margin="normal"
        />
        <br/>

        <TextField
          id="lastName"
          label="Last Name"
          className={classes.textField}
          value={this.state.currentDriver.lastName}
          onChange={event => this.handleChange("lastName", event.target.value)}
          margin="normal"
        />
        <br/>

        <TextField
          id="dateOfBirth"
          label="Date of Birth"
          className={classes.textField}
          value={this.state.currentDriver.dateOfBitch}
          onChange={event => this.handleChange("dateOfBitch", event.target.value)}
          margin="normal"
        />
        <br/>

        <TextField
          id="driversLicenseNumber"
          label="Driver's License Number"
          className={classes.textField}
          value={this.state.currentDriver.driversLicenseNumber}
          onChange={event => this.handleChange("driversLicenseNumber", event.target.value)}
          margin="normal"
        />
        <br/>

        <TextField
          id="driversLicenseState"
          label="Driver's License State"
          select
          className={classes.textField}
          value={this.state.currentDriver.driversLicenseState}
          onChange={event => this.handleChange("driversLicenseState", event.target.value)}
          margin="normal">
          {states.map((x, i) => <MenuItem key={i} value={x[0]}>{x[1]}</MenuItem>)}
        </TextField>
        <br/>

        <TextField
          id="email"
          label="Email Address"
          className={classes.textField}
          value={this.state.currentDriver.email}
          margin="normal"
        />
      </form>

      <Button>
        Back
      </Button>
      <br/>

      <Button>
        Add another driver
      </Button>
      <br/>

      <Button>
        Next
      </Button>
      <br/>

      <Typography>
        WARNING: YOU ARE ONLY COVERED BY INSURANCE IF DRIVER IS REGISTERED
      </Typography>
    </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Drivers));

