import Utils from "./Utils";

export default class VehicleEvent {
  static fromJson(json) {
    const vehicleEvent = Utils.deepCopyJson(json);

    // Add extra empty fields, if they don't exist.
    if (!vehicleEvent.info) {
      vehicleEvent.info = {};
    }

    return Utils.param2timeJson(vehicleEvent, json, ["created", "updated"]);
  }

  toMime64() {
    return Utils.obfuscateParams(JSON.stringify(this.toJson()));
  }

  static fromMime64(str) {
    return new VehicleEvent(VehicleEvent.fromJson(JSON.parse(Utils.deobfuscateParams(str))));
  }

  toJson() {
    let json = Utils.deepCopyJson(this);

    return Utils.time2paramJson(json, ["created", "updated"]);
  }

  constructor(json) {
    Object.assign(this, VehicleEvent.fromJson(json));
  }

  clone() {
    return new VehicleEvent(this.toJson());
  }

  isCompleted() {
    return Utils.now() >= this.created;
  }

  prettyType() {
    switch (this.info.type) {
      case "TRIP_START": return "Trip Started";
      case "TRIP_FINISH": return "Trip Finished";
      case "OWNER_PICKUP": return "Picked Up from Owner";
      case "OWNER_DROPOFF": return "Dropped Off to Owner";
      case "GPS": return "GPS Waypoint";
      case "LAST_GPS": return "Last GPS Waypoint";

      case "MAINTENANCE": return "Maintenance";
      case "REPAIRS": return "Repairs";
      default:
        throw new Error("Invalid type: " + this.info.type);
    }
  }
};
