import Place from "./Place";
import Utils from "./Utils";

// Class for serializing / deserializing Booking / Order rows for REST / DB.
export default class Booking {
  static fromJson(json) {
    let booking = Utils.deepCopyJson(json);

    // Add extra empty fields, if they don't exist.
    if (!booking.info) {
      booking.info = {};
    }
    if (!booking.info.extras) {
      booking.info.extras = [];
    }
    if (!booking.info.drivers) {
      booking.info.drivers = [];
    }

    return Utils.param2dateJson(booking, json, ["startDate", "endDate", "created", "updated"]);
  }

  toJson() {
    let tmpVehicle = this.info.vehicle;
    this.info.vehicle = undefined;

    let tmpRenter = this.info.renter;
    this.info.renter = undefined;

    let json = Utils.deepCopyJson(this);

    this.info.vehicle = tmpVehicle;
    this.info.renter = tmpRenter;

    return Utils.date2paramJson(json, ["startDate", "endDate", "created", "updated"]);
  }

  deepCopy() {
    return Booking.fromJson(this.toJson());
  }

  constructor(json) {
    Object.assign(this, Booking.fromJson(json));
    if (this.info.place) {
      this.info.place = new Place(this.info.place);
    }
  }

  clone() {
    // TODO: This does 2 deepCopy invocations instead of one.
    let newBooking = new Booking(this.toJson());
    if (this.info.vehicle) {
      newBooking.info.vehicle = this.info.vehicle;
    }
    if (this.info.renter) {
      newBooking.info.renter = this.info.renter;
    }
    return newBooking;
  }

  printDateRange() {
    return Utils.date2param(this.startDate) + " TO " + Utils.date2param(this.endDate);
  }

  numDays() {
    return this.endDate.diff(this.startDate, "days");
  }

  getState() {
    if (!this.info.state) {
      return "CONFIRMED";
    }

    switch (this.info.state) {
      case "CONFIRMED":
      case "PREPARING":
      case "STARTED":
      case "FINISHED":
      case "RETURNED":
      case "CANCELED":
        return this.info.state;
      default:
        throw new Error("Invalid state: " + this.info.state);
    }
  }

  prevState() {
    switch (this.getState()) {
      case "CONFIRMED": return null;
      case "PREPARING": return "CONFIRMED";
      case "STARTED": return "PREPARING";
      case "FINISHED": return "STARTED";
      case "RETURNED": return "FINISHED";
      case "CANCELED": return null;
      default:
        throw new Error("Invalid state: " + this.getState());
    }
  }

  nextState() {
    switch (this.getState()) {
      case "CONFIRMED": return "PREPARING";
      case "PREPARING": return "STARTED";
      case "STARTED": return "FINISHED";
      case "FINISHED": return "RETURNED";
      case "RETURNED": return null;
      case "CANCELED": return null;
      default:
        throw new Error("Invalid state: " + this.getState());
    }
  }

  static prettyState(state) {
    switch (state) {
      case "CONFIRMED": return "Upcoming";
      case "PREPARING": return "Preparing";
      case "STARTED": return "Ongoing";
      case "FINISHED": return "Finished";
      case "RETURNED": return "Returned";
      case "CANCELED": return "Canceled";
      default:
        throw new Error("Invalid state: " + state);
    }
  }

  isUpcoming() {
    return this.getState() === "CONFIRMED";
  }
  isPreparing() {
    return this.getState() === "PREPARING";
  }
  isOngoing() {
    return this.getState() === "STARTED";
  }
  isFinished() {
    return this.getState() === "FINISHED";
  }
  isReturned() {
    return this.getState() === "RETURNED";
  }
  isCanceled() {
    return this.info.state === "CANCELED";
  }
}
