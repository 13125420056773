import React from "react";

// Fake Okta user provider for owner demo.
export default function withHeader(WrappedComponent) {
  return class extends React.Component {
    render() {
      const auth = {
        login: () => new Promise((resolve, reject) => resolve()),
        getUser: () => new Promise((resolve, reject) => resolve({
          user: "test-user",
          preferred_username: "test-user",
          given_name: "Demo User",
          name: "Demo User",
          email: "test@aa.co",
        })),
        getIdToken: () => new Promise((resolve, reject) => resolve("test-auth-token")),
        isAuthenticated: () => new Promise((resolve, reject) => resolve(true)),
      };

      return <WrappedComponent auth={auth} isDemoUser={true} {...this.props} />
    }
  };
}
