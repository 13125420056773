import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import PersonIcon from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { withAuth } from '@okta/okta-react';

import Utils from "../common/Utils";

const styles = theme => ({
});

// https://developer.okta.com/quickstart/#/react/nodejs/express
// Okta tutorial is slighly broken: https://github.com/okta/okta.github.io/issues/2253
// See both Login and AutoLogin, they are v similar.
class LoginButton extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: null };
    this.checkAuthentication();
  }

  setAuthenticated(result) {
    if (result !== this.state.authenticated) {
      this.setState({authenticated: result});
    }
  }

  checkAuthentication() {
    this.props.auth.isAuthenticated()
    .then(result => this.setAuthenticated(result))
    .catch(error => {
      Utils.log("AutoLogin error: ", error);
      this.setAuthenticated(false);
    });
  }

  // Listens to updates.
  componentDidUpdate() {
    this.checkAuthentication();
  }

  onButtonClick() {
    if (this.state.authenticated) {
      this.props.auth.logout(this.props.logoutRedirect).then(() => this.checkAuthentication());
    } else {
      this.props.auth.login(this.props.loginRedirect).then(() => this.checkAuthentication())
    }
  }

  render() {
    if (this.state.authenticated === null) {
      // No status yet.
      return null;
    }

    if (this.props.hideLogout && this.state.authenticated) {
      return null;
    }

    return <ListItem button onClick={() => this.onButtonClick()}>
      <ListItemIcon>{this.state.authenticated ? <ExitToApp /> : <PersonIcon />}</ListItemIcon>
      <ListItemText primary={<span style={{color:"#555"}}>{this.state.authenticated ? "Logout" : "RV Owner Login"}</span>} />
    </ListItem>
  }
}

export default withAuth(withRouter(withStyles(styles, { withTheme: true })(LoginButton)));
