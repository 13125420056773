import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import HocUtils from "../hoc/HocUtils";

import FlexibleImage from "../components/FlexibleImage";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  image: {
    objectFit: "cover",
    //minHeight: 200,
    maxHeight: 475,
    width: "100%",
    //maxWidth: "100%",
    //height: "auto",
    //maxWidth: 800,
  },

  manifesto: {
    margin: 30,
    marginTop: 30,
    marginBottom: 30,
    textAlign: "left",
    //maxWidth: 800,
  },

  teamArea: {
    backgroundColor: theme.palette.background.standard,
    width: "100%",
  },

  team: {
    //maxWidth: 800,
    padding: 20,
    paddingTop: 30,
    paddingBottom: 50,
  },

  teamContainer: {
    display: "flex",
    flexDirection: Utils.isMobile() ? "column" : "row",
    justifyContent: "space-around",
  },

  teamMember: {
    paddingTop: 0,
    padding: 20,
  },

  teamImage1: {
    maxWidth: 240,
    borderRadius: 0,
  },

  teamImage2: {
    maxWidth: 240,
    borderRadius: 50,
  },

});

const manifesto1 = [
    "Refresh Campers was started in 2018 out of a necessity for affordable and convenient camping. ",
    "We are noticing change in the world of recreational vehicles that has been happening for a few years. "];

const manifesto2 = [
    "We, our friends, and friends of friends, are looking for something more -- or, we might say, something less -- ",
    "simplicity, minimalism, independence. ",
    "RVs are getting smaller, but their purpose is getting bigger. ",
    "More people use them more frequently, are working in them, are even stealth camping in urban areas. ",
    "More and more people are seeing the benefits of van life, and we want to bring it to as many people as possible"];

class About extends Component {
  render() {
    const { classes } = this.props;

    return <div className={classes.container}>
      <FlexibleImage desktopImg={"/img/clouds-1920x640.jpg"} mobileImg={"/img/clouds-500x334.jpg"} alt="Van in desert" />
      <div className={classes.manifesto}>
        <div align="center">
          <Typography variant="h6" color="textSecondary">
            Philosophy
            <br/>
            <br/>
          </Typography>
        </div>
        <Typography variant="subtitle1" color="textSecondary">
          {manifesto1}
          <br/>
          <br/>
          {manifesto2}
        </Typography>
      </div>
      <div className={classes.teamArea} align="center">
        <div className={classes.manifesto}>
          <Typography variant="h6" color="textSecondary" align="center">
            Who we are
            <br/>
            <br/>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" align="left">
            Traian started building his first van in the summer of 2016, and passed the flame to Meghan who moved to a Sprinter in 2017.
            <br/>
            <br/>
            In 2018 they started to build and rent mini campers through the RV sharing website Outdoorsy.
            <b> <a style={{color:"inherit"}} href="https://www.outdoorsy.com/users/274173"> Customers loved us</a> </b>
            and they gave us new ideas, which are surfacing through Refresh Campers.
            The goal is simple: offer the best rental experience for the lowest cost.
          </Typography>
        </div>

        <div className={classes.team}>
          <div className={classes.teamContainer}>
            <div className={classes.teamMember}>
              <img className={classes.teamImage2} src={"/img/profile-traian.jpg"} alt="Traian" />
              <Typography variant="subtitle1" color="default">
                <br/>
                Traian Stancescu
                <br/>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Lived in a van for 2 years
                <br/>
              </Typography>
            </div>

            <div className={classes.teamMember}>
              <img className={classes.teamImage2} src={"/img/profile-meghan.jpg"} alt="Meghan" />
              <Typography variant="subtitle1" color="default">
                <br/>
                Meghan Sykora
                <br/>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Lived in a van for 1 year
                <br/>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(About));

