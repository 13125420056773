import React, { Component } from "react";

import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import Utils from "../common/Utils";

const styles = theme => ({
});

class FreeCancel extends Component {

  hasFreeCancel() {
    return this.freeCancelDate() > moment();
  }

  freeCancelDate() {
    return Utils.computeGracePeriodDeadline(moment(), this.props.startDate, true /* strict */);
  }

  render() {
    if (!this.props.startDate || !this.hasFreeCancel()) {
      return false;
    }

    return <div style={{display:"flex", justifyContent:"center", flexWrap: "wrap"}}>
      <Typography color="primary">
        FREE cancelation until&nbsp;
      </Typography>
      <Typography color="primary">
        <b>{Utils.prettyTime(this.freeCancelDate())}</b>
      </Typography>
      {/*
      <span style={{display:"flex", justifyContent:"center", flexWrap: "wrap"}}>
        <Typography color="primary">
          &nbsp;(see&nbsp;
        </Typography>
        <Typography color="textSecondary">
          <a style={{color: "#666666"}} href="policies#grace" target="_blank">details</a>
        </Typography>
        <Typography color="primary">
          )
        </Typography>
      </span>*/}
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(FreeCancel));
