import Cookies from 'universal-cookie';
import queryString from "query-string";
import md5 from 'md5';

import Utils from "./common/Utils";

const USER_ID_KEY = "RefreshCampersUserId";
const USER_ID_ADMIN_MD5 = "9cf591f215702aa8ba6d5b4466df9566";

const cookies = new Cookies();

export default class LocalUtils {

  // Copy field from proto, if different.
  static prop2state(state, prevState, stateName, propValue) {
    if (propValue !== prevState[stateName]) {
      state[stateName] = propValue;
    }
  }

  static parseProps(props) {
    Utils.log("parseProps: ", props);

    let queryParams = queryString.parse(props.location.search);

    if (queryParams.userId) {
      cookies.set(USER_ID_KEY, queryParams.userId);
    }

    return queryParams;
  }

  static isAdmin() {
    let userId = cookies.get("RefreshCampersUserId");
    if (!userId) {
      return false;
    }

    return md5(userId) === USER_ID_ADMIN_MD5;
  }

  static aspectRatio() {
    return window.innerWidth / window.innerHeight;
  }

  // Asynchronously scrolls to top.
  // Replace of scrollTo, which doesn't work because html and body heights are set to 100%
  // https://stackoverflow.com/questions/21124874/how-to-scrolltop-when-html-body-is-set-to-height-100
  static scrollTop() {
    window.scrollTo(0, 0);
    // The complicated asyns scroll is no longer necessary, since height: 100% was removed: it broke momentum scroll.
    /*
    Utils.callWithDelay(0, () => {
      document.body.style.height = "auto";
      window.scrollTo(0, 0);
      Utils.callWithDelay(200, () => document.body.style.height = "");  // MAJOR HACK, since scrollTo has no callback.
    });
    */
  }
}
