import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import HocUtils from "../hoc/HocUtils";

import ExpPanel from "../components/ExpPanel";
import FlexibleImage from "../components/FlexibleImage";

const styles = theme => ({

  container: {
    background: theme.palette.background.standardDouble,
  },

  policies: {
    display: "flex",
    flexDirection: Utils.isMobile() ? "column" : "row",
    //justifyContent: "space-between",
  },

  // https://stackoverflow.com/questions/25066214/flexbox-not-giving-equal-width-to-elements
  section: {
    flexBasis: Utils.isMobile() ? "100%" : "50%",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    //justifyContent: "space-between",
  },
});

// When picking up
//   Booking
//   Change / cancellation
//   Delivery
// During the trip
//   Extensions / Late return
// Returning
//   Drop off: Fuel / Cleaning / Drop off parking
//   Extra payments

// Cannot place div, because it's under Typography which generates <p>...
// WARNING: When adding to this list, don't forget to update anchor indexes in constructor.
const reservationsInfo = [
  [
    "Booking a van",
    <span>Details about how the booking process works are <a color="inherit" href="https://www.refreshcampers.com/how-it-works">here</a>.</span>,
  ],

  [
    "Who can rent this van",
    <span>Anyone who is 25 or older with a valid driver's license can rent and drive this van. Non-USA renters need an international driver's license to drive our vans.
    <br/>
    <br/>
    Up to 2 additional drivers can be added, free of charge.
    </span>
  ],

  [
    "Minimum rental period",
    <span>Our vans can be rented for 3 nights or longer.</span>,
  ],

  [
    <span id="insurance">Insurance included</span>,
    <span>All our vehicles comes with complimentary liability insurance in all 50 states, and Comprehensive & Collision insurance with a deductible of $1000.
    <br/>
    <br/>
    Registration and proof of insurance documents will be provided and should remain inside the vehicle at all times.
    </span>
  ],

  [
    <span id="cancel">Change & cancellation policy</span>,
    <span>
      Our change and cancelation policy is very flexible:
      <br/>
      <br/>
      The vehicle type can be changed at any time, subject to availability.
      <br/>
      <br/>
      Days can be added to the beginning or end of the trip at any time for free, subject to availability.
      <br/>
      <br/>
      When canceling or shortening the trip, each day subtracted from the reservation is treated separately:
      <br/>
      <br/>
      - For canceling days more than 2 weeks (14 days) in advance, you get a <b>full refund</b>.
      <br/>
      - For canceling days more than 1 week (7 days) but less than 2 weeks (14 days) away from today, you get a <b>50% refund</b>.
      <br/>
      - For canceling days less than 1 week (7 days) away from today, you get <b>no refund</b>.
      <br/>
    </span>,
  ],

  [
    <span id="grace">Grace periods</span>,
    <span>
      If you booked a van by accident, we offer reasonable grace periods in which the trip can be changed or canceled free of charge:
      <br/>
      <br/>
      - Trips that start more than 2 weeks (14 days) in advance can be canceled at any time free of charge.
      <br/>
      - Trips that start less than 2 weeks (14 days) in advance can be canceled free of charge ONLY within 24 hours after reservation.
    </span>
  ],

  [
    "Picking up / Delivery",
    <span>
      Refresh Campers will contact you a few days before starting the trip to confirm delivery details. We will meet you in person, introduce you to the van, and hand off the keys.
    </span>
  ],

  [
    <span id="extension">Trip extensions</span>,
    <span>
      Unless our vans are fully booked, days can be added to the beginning or end of the trip FOR FREE and AT ANY TIME (including during the trip).
      <br/>
      <br/>
      Unauthorized extensions cost 4x the daily rate for the first day, and 2x daily rate for every day afterwards! Please bring the van in time for the next guests to enjoy.
    </span>
  ],

  [
    "Returning a van",
    <span>
      This is what you need to do when returning a van:
      <br/>
      <br/>
      - Fill up the gas tank before dropping it off. You will be charged any refuel costs with a $20 penalty.
      <br/>
      - The vehicle doesn't need to be clean, but if it's returned unreasonably dirty, additional cleaning fees will be charged.
      No need to worry about emptying greywater or refilling freshwater tanks -- we've got it.
      <br/>
      - Park the van in a spot that is legal to park for at least 24 hours -- we need a little time to pick it up.
      <br/>
      - Make sure there are no meters, color restrictions, parking permits, street cleaning, etc.
      You will be charged with any illegal parking consequences that happen within the next 24 hours (i.e. the vehicle is ticketed or towed).
    </span>
  ],

  [
    "Payment of extra charges",
    <span>
      Any extra charges (fees, fines, extensions, etc.) will be charged to the credit card that was used for payment when booking.
    </span>
  ],
];

// Extra miles
// Tickets
// Tolls
// Tows
// Accidents

// Canada / Mexico
// Pets
// Smoking
// Festivals
// Off-road
// Other drivers

// WARNING: When adding to this list, don't forget to update anchor indexes in constructor.
const duringTheTrip = [
  [
    "How many miles are included in a rental?",
    <span>Unlimited miles are included in your trip.</span>
  ],
    //<span>100 miles per day are included. For example, if you book a 6 day trip you can travel up to 600 miles for free. Unlimited mile packages are also available, and the price per extra mile is shown at booking time.</span>,

  [
    "Who is responsible for paying fees, tolls, or tickets during the rental period?",
    <span>
      The renter is responsible for paying any expenses that are incurred during the rental period. This includes, but is not limited to: road and bridge tolls, parking tickets, tows.
      <br/>
      An administrative fee of $20 is added to any fees that are owed (with the exception of Fastrak tolls, for which you only pay what you use).
    </span>
  ],

  [
    "Tow and impound fees",
    <span>
      If a rental van is towed to an impound lot, we will provide the renter with all the information necessary to retrieve the van.
      <br/>
      The renter is responsible for paying all tow and impound fees.
    </span>
  ],

  [
    "What should I do during an accident",
    <span>
      Notify Refresh Campers immediately if an incident occurs -- we will take appropiate measures depending on the severity of the incident.
      <br/>
      For very serious accidents, you should also call emergency services.
    </span>
  ],

  [
    "Where can I drive",
    <span>You can drive in USA in any of the 50 states.
    <br/>
    <br/>
    You are NOT covered by insurance when driving in either Canada or Mexico.
    Driving on unpaved roads, driving with snow chains, and off-road use are prohibited.</span>
  ],

  [
    "Pets",
    <span>No pets are allowed in the van with the exception of service dogs.</span>,
  ],

  [
    "Festivals",
    <span>
      Our vans are festival friendly, and no extra charge is added for camping at festivals.
      The only exception is Burning Man -- for which we have an increased price to offset the damage done to the vehicle
    </span>,
  ],
];

class Policies extends Component {
  constructor(props) {
    super(props);

    for (let elem of reservationsInfo) {
      elem.push(false);
    }
    for (let elem of duringTheTrip) {
      elem.push(false);
    }

    if (props.location.hash === "#insurance") {
      reservationsInfo[3][2] = true;
    }
    if (props.location.hash === "#cancel") {
      reservationsInfo[4][2] = true;
    }
    if (props.location.hash === "#grace") {
      reservationsInfo[5][2] = true;
    }
    if (props.location.hash === "#extension") {
      reservationsInfo[7][2] = true;
    }
  }

  render() {
    const { classes } = this.props;

    return <div className={classes.container}>
      <div align="center">
        <Typography variant="h6" color="default">
          <br/>
          <br/>
          Rental policies
          <br/>
          <br/>
        </Typography>
      </div>
      <div className={classes.policies}>
        <div className={classes.section}>
          <ExpPanel title="Reservations" contents={reservationsInfo} />
        </div>
        <div className={classes.section}>
          <ExpPanel title="During the trip" contents={duringTheTrip} />
        </div>
      </div>
      <br/>
      <FlexibleImage desktopImg={"/img/tree-1920x800.jpg"} mobileImg={"/img/tree-500x500.jpg"} alt="Van under tree" isBookNow={true} />
    </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Policies));

