import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

const styles = theme => ({
  reports: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  report: {
    paddingLeft: Utils.isMobile() ? 0 : 10,
    paddingRight: Utils.isMobile() ? 0 : 10,
  },
});

function computeTotalExpenses(vehicleEvents, startDate, includeUpcoming) {
  let expenses = 0;
  let events = 0;
  for (let vehicleEvent of vehicleEvents.filter(x => ["MAINTENANCE", "REPAIRS"].includes(x.info.type))) {
    if (vehicleEvent.info.expenseAmount &&
        vehicleEvent.created.clone().startOf("day") >= startDate &&
        (includeUpcoming || vehicleEvent.created.clone().startOf("day") < Utils.now())) {
      expenses += vehicleEvent.info.expenseAmount;
      events++;
    }
  }
  return {amount: expenses, events};
}

function computeTotalEarnings(orders, startDate, isAdminView, includeUpcoming) {
  let earnings = 0;
  let nights = 0;
  let trips = 0;
  for (let order of orders) {
    if (order.booking.startDate.clone().startOf("day") >= startDate &&
        (includeUpcoming || order.booking.startDate.clone().startOf("day") < Utils.now())) {
      earnings += (isAdminView ? order.computeTotal() : order.computeOwnerAmount());
      nights += order.numDays();
      trips++;
    }
  }
  return {amount: earnings, nights, events: trips};
}

class VehicleReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
    };
  }

  renderTotals(intervalType, isExpense) {
    const now = Utils.now().startOf("day");

    let str = "";
    let startDate = null;
    switch (intervalType) {
      case "UPCOMING":
        startDate = now;
        str = "Upcoming";
        break;
      case "LAST_30_DAYS":
        startDate = now.subtract(30, "days");
        str = "Last 30 days";
        break;
      case "LAST_3_MONTHS":
        startDate = now.subtract(12, "months");
        str = "Last 3 months";
        break;
      case "YEAR_TO_DATE":
        startDate = now.startOf("year");
        str = "Year to date";
        break;
      case "ALL_TIME":
        startDate = Utils.param2date("2018-01-01");
        str = "All time";
        break;
      default:
        throw new Error("Invalid interval type: ", intervalType);
    }

    let details = null;
    let res = null;
    if (isExpense) {
      res = computeTotalExpenses(this.props.vehicleEvents, startDate, intervalType === "UPCOMING");
      details = <span>{res["events"]} events</span>;
    } else {
      res = computeTotalEarnings(this.props.orders, startDate, this.props.isAdminView, intervalType === "UPCOMING");
      details = <span>{res["nights"]} nights in {res["events"]} trips</span>;
    }

    return <tr>
      <td><Typography color="textSecondary">{str}</Typography></td>
      <td align="right"><Typography color="textSecondary"><b>{Utils.prettyMoney(res["amount"])}</b> ({details})</Typography></td>
    </tr>;
  }

  render() {
    const { classes } = this.props;

    return <div className={classes.reports} style={{flexDirection: (Utils.isMobile() || Utils.isMediumScreen()) ? "column" : "row"}} align="center">
      {this.props.type === "bookings" && <div className={classes.report}>
        <Typography variant="subtitle1">
          <br/>
          <b>Earnings</b>
        </Typography>
        <hr/>
        <br/>
        <table>
          <tbody>
            {this.renderTotals("UPCOMING")}
            {this.renderTotals("LAST_30_DAYS")}
            {this.renderTotals("LAST_3_MONTHS")}
            {this.renderTotals("YEAR_TO_DATE")}
            {this.renderTotals("ALL_TIME")}
          </tbody>
        </table>
      </div>}

      {this.props.type === "expenses" && <div className={classes.report}>
        <Typography variant="subtitle1">
          <br/>
          <b>Expenses</b>
        </Typography>
        <hr/>
        <br/>
        <table>
          <tbody>
            {this.renderTotals("UPCOMING", true)}
            {this.renderTotals("LAST_30_DAYS", true)}
            {this.renderTotals("LAST_3_MONTHS", true)}
            {this.renderTotals("YEAR_TO_DATE", true)}
            {this.renderTotals("ALL_TIME", true)}
          </tbody>
        </table>
      </div>}
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(VehicleReport));
