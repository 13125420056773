import React from "react";

// https://app.intercom.io/a/apps/wndjy98c/platform/guide/setup_messenger/install_messenger
export default function withIntercom(WrappedComponent) {
  return class extends React.Component {
    /*
    constructor(props) {
      super(props);

      window.Intercom("boot", {app_id: "wndjy98c"});
    }
    */

    render() {
      /*
      const user = this.props.user ? this.props.user : {};

      window.intercomSettings = {
        app_id: "wndjy98c",
        user_id: user.preferred_username,
        email: user.email,
        name: user.name,
      };

      window.Intercom("update", window.intercomSettings);
      */

      return <div>
        <WrappedComponent {...this.props} />
      </div>;
    }
  };
}
