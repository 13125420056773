import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Utils from "../common/Utils";

import FlexibleImage from "./FlexibleImage";

const styles = theme => ({
  big: {
    // 2x / 3x + margins
    width: ThumbImage.bigThumbRatio() * (ThumbImage.width() + 2) - 2,
    height: ThumbImage.bigThumbRatio() * (ThumbImage.height() + 2) - 2,
    margin: 1,
    overflow: "hidden",
  },

  square: {
    // 500x375 mobile (aspect ratio 4/3)
    // 800x500 desktop (aspect ratio 16/10)
    width: ThumbImage.width(),
    height: ThumbImage.height(),
    margin: 1,
  },
});

const IDEAL_MOBILE_WIDTH = 158;  // + 2 border = 320 (screen size for iPhone 5)
// TODO: Add 3 more pics to gallery (9 instead of 6) an it should look perfect with bigThumbnailRatio of 2!
const IDEAL_DESKTOP_WIDTH = 256;  // 384 if bigThumbRatio is 2 for Desktop

class ThumbImage extends Component {

  static bigThumbRatio() {
    return (Utils.isMobile() ? 2 : 2);
  }

  static height() {
    return Math.ceil(ThumbImage.width() / (Utils.isMobile() ? (4/3) : (16/10)));
  }

  static width() {
    if (window.innerWidth) {
      let numColumns = Utils.isMobile()
          ? 2
          : Math.ceil(window.innerWidth / IDEAL_DESKTOP_WIDTH);
      return Math.floor(window.innerWidth / numColumns - 2);
    } else {
      return Utils.isMobile() ? IDEAL_MOBILE_WIDTH : IDEAL_DESKTOP_WIDTH;
    }
  }

  render() {
    return <div className={"pointerOnHover " + (this.props.big ? this.props.classes.big : this.props.classes.square)}>
        <FlexibleImage desktopImg={this.props.photo} mobileImg={this.props.photoMobile} alt="Vehicle Photo" />
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(ThumbImage));

