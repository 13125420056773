import React from 'react';

import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Place from "../common/Place";
import Utils from "../common/Utils";

import AlertDialog from "./AlertDialog";

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const styles = theme => ({

  searchBarContainer: {
    width: 316,
    maxWidth: "500px",
    margin: "0 auto 0",
  },

  searchBarContainerSmall: {
    width: 231.33,
  },

  searchInputContainer: {
    position: "relative",
    //height: Utils.isMobile() ? 34 : 48,
  },

  // Match the style in calendar input.
  searchInput: {
    //boxShadow: "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)",
    border: "transparent",
    width: "100%",
    color: "#484848",
    fontWeight: 200,
    //borderRadius: "2px",
    //padding: "16px",
    //outline: "none",

    padding: 13, //14.5,
    fontSize: 19,
  },

  searchInputSmall: {
    padding: 8, //9.5,
    fontSize: 15,
  },

  searchInputDisabled: {
    backgroundColor: "lightGrey",
  },

  // Actual button that is pressed.
  clearButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 999,

    right: "4px",
  },

  clearButtonSmall: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 999,

    right: "0px",
  },

  // Here just for square background white.
  clearButtonBackground: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: "white",
    color: "white",
    border: "none",
    outline: "none",
    fontWeight: "600",
    fontSize: 22,
    //color: "#999",

    width: 40,
    right: "5px",
    padding: 8.5 // 10,
  },

  clearButtonBackgroundSmall: {
    width: 32,
    right: "5px",
    padding: 1.5 // 3,
  },

  clearButtonBackgroundDisabled: {
    backgroundColor: "lightGrey",
    color: "lightGrey",
  },

  autocompleteContainer: {
    zIndex: 999,
    backgroundColor: "#fff",
    position: "absolute",
    marginRight: 20,
    borderBottom: "honeydew",
    borderLeft: "honeydew",
    borderRight: "honeydew",
    borderTop: "1px solid #e6e6e6",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    borderRadius: "0 0 2px 2px",
  },

  suggestionItem: {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#fff",
    cursor: "pointer",
  },

  suggestionItemActive: {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#fafafa",
    cursor: "pointer",
  },

  dropdownFooter: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "4px",
  },

  dropdownFooterImage: {
    display: "inlineBlock",
    width: "150px",
  },

/*
.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.Demo__error-message {
  color: red;
}

.Demo__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .alert {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    -webkit-animation: fadein .75s;
    -moz-animation: fadein .75s;
    -ms-animation: fadein .75s;
    -o-animation: fadein .75s;
    animation: fadein .75s;
  }

  .btn {
    margin-top: 1.5rem;
  }

  .form-group {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    width: 50%;
  }
}

*/

});

// https://github.com/hibiken/react-places-autocomplete
class AddressSearch extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      address: props.address ? props.address : '',
      tooFarDialogOpen: false,
      sholdGenerateDefaultSuggestions: false,
    };
  }

  handleChangeWithCb = address => {
    this.setState({address});
    if (this.props.onAddressChange) {
      this.props.onAddressChange(null);
    }
  };

  handleChange = address => {
    this.setState({address});
  };


  handleSelect = selected => {
    let result = null;
    this.setState({ address: selected });
    geocodeByAddress(selected)
      .then(res => { result = res[0]; return getLatLng(res[0]) })
      .then(({ lat, lng }) => {
        let place = Place.fromSearchResult(result, lat, lng);
        if (!place.isCovered) {
          this.setState({tooFarDialogOpen: true});
        } else if (this.props.onAddressChange) {
          this.props.onAddressChange(place);
        }
      })
      .catch(error => {
        Utils.log('error', error); // eslint-disable-line no-console
      });
  };

  generateDefaultSuggestions = () => {
    // Loaded from suggestions on Oct 18, 2018.

    let suggestions = [
      {
        "id":"9c9fb107d7a776c3f6a26791b07982d7db079113",
        "description":"San Francisco International Airport (SFO), San Francisco, CA, USA",
        "placeId":"ChIJVVVVVYx3j4ARP-3NGldc8qQ",
        "active":false,
        "index":0,
        "formattedSuggestion":{"mainText":"San Francisco International Airport (SFO)",
        "secondaryText":"San Francisco, CA, USA"},
        "matchedSubstrings":[{"length":3,"offset":37}],
        "terms":[
          {"offset":0,"value":"San Francisco International Airport (SFO)"},
          {"offset":43,"value":"San Francisco"},
          {"offset":58,"value":"CA"},
          {"offset":62,"value":"USA"}],
        "types":["establishment"]
      },

      {
        "id":"b5aa752b1fa3db2e57c8706cf847e67e06c24d3b",
        "description":"Oakland International Airport (OAK), Airport Drive, Oakland, CA, USA",
        "placeId":"ChIJQabAAlSEj4ARYHQBAw8MY7A",
        "active":true,
        "index":1,
        "formattedSuggestion":{"mainText":"Oakland International Airport (OAK)","secondaryText":"Airport Drive, Oakland, CA, USA"},
        "matchedSubstrings":[{"length":13,"offset":0}],
        "terms":[
          {"offset":0,"value":"Oakland International Airport (OAK)"},
          {"offset":37,"value":"Airport Drive"},
          {"offset":52,"value":"Oakland"},
          {"offset":61,"value":"CA"},
          {"offset":65,"value":"USA"}
        ],
        "types":["establishment"]
      },
/*
      {
        "id":"f13e48e534d35ebac72aa9a824bcefd13c8a17ee",
        "description":"Oakland International Airport, Oakland, CA, USA",
        "placeId":"ChIJu0En3QSFj4ARx_cFCHMV6z0",
        "active":true,
        "index":1,
        "formattedSuggestion":{"mainText":"Oakland International Airport","secondaryText":"Oakland, CA, USA"},
        "matchedSubstrings":[{"length":13,"offset":0}],
        "terms":[
          {"offset":0,"value":"Oakland International Airport"},
          {"offset":31,"value":"Oakland"},
          {"offset":40,"value":"CA"},
          {"offset":44,"value":"USA"}
        ],
        "types":["transit_station","point_of_interest","establishment","geocode"]
      },
*/
      {
        "id":"2f215636bac933e493adba23bfef4d54fd6d2fc5","description":"Norman Y. Mineta San Jose International Airport (SJC), Airport Boulevard, San Jose, CA, USA",
        "placeId":"ChIJm8Wz-sPLj4ARPn72bT9E-rw",
        "active":true,
        "index":2,
        "formattedSuggestion":{"mainText":"Norman Y. Mineta San Jose International Airport (SJC)","secondaryText":"Airport Boulevard, San Jose, CA, USA"},
        "matchedSubstrings":[{"length":53,"offset":0}],
        "terms":[
          {"offset":0,"value":"Norman Y. Mineta San Jose International Airport (SJC)"},
          {"offset":55,"value":"Airport Boulevard"},
          {"offset":74,"value":"San Jose"},
          {"offset":84,"value":"CA"},
          {"offset":88,"value":"USA"}],
        "types":["establishment"]
      },

      {
        "id":"1b9ea3c094d3ac23c9a3afa8cd4d8a41f05de50a",
        "description":"San Francisco, CA, USA",
        "placeId":"ChIJIQBpAG2ahYAR_6128GcTUEo",
        "active":true,
        "index":3,
        "formattedSuggestion":{"mainText":"San Francisco","secondaryText":"CA, USA"},
        "matchedSubstrings":[{"length":3,"offset":0}],
        "terms":[
          {"offset":0,"value":"San Francisco"},
          {"offset":15,"value":"CA"},
          {"offset":19,"value":"USA"}],
        "types":["locality","political","geocode"]
      },

    ];

    if (this.props.city) {
      return [
      {
        id: 123,
        placeId: 123,
        description: this.props.city,
        formattedSuggestion: {mainText: this.props.city, secondaryText: "current location"},
        index: 5,
      }].concat(suggestions);
    } else {
      return suggestions;
    }
  };

  handleInputBlur = () => {
    //Utils.log("Blur");
    this.setState({shouldGenerateDefaultSuggestions: false});
  };

  handleInputFocus = () => {
    // Ignore if just closed dialog.
    if (this.state.justClosedDialog) {
      this.setState({justClosedDialog: false});
      return;
    }
    //Utils.log("Focus");
    this.setState({shouldGenerateDefaultSuggestions: true});
  };

  handleError = (status, clearSuggestions) => {
    Utils.log('Error from Google Maps API', status); // eslint-disable-line no-console
    clearSuggestions();
  };

  renderSuggestions = (suggestions, classes, getSuggestionItemProps) => {
    return <div className={classes.autocompleteContainer}>
        {suggestions.map(suggestion => {
          const className = suggestion.active ? classes.suggestionItemActive : classes.suggestionItem;

          //Utils.log("Suggestion: " + JSON.stringify(suggestion));

          return (
            /* eslint-disable react/jsx-key */
            <div
              {...getSuggestionItemProps(suggestion, { className })}
            >
              <strong>
                {suggestion.formattedSuggestion.mainText}
              </strong>{' '}
              <small>
                {suggestion.formattedSuggestion.secondaryText}
              </small>
            </div>
          );
          /* eslint-enable react/jsx-key */
        })}
        <div className={classes.dropdownFooter}>
          <div>
            <img
              src={'img/powered_by_google_default.png'} alt="Powered by Google"
              className={classes.dropdownFooterImage}
            />
          </div>
        </div>
      </div>;
  };

  render() {
    const { classes } = this.props;
    const {
      address,
    } = this.state;

    // TODO: Do this with material Input and InputAdornment.
    // https://material-ui.com/demos/text-fields/
    return (
      <div>
        <AlertDialog
            open={this.state.tooFarDialogOpen}
            text={"Delivery location is too far"}
            onClose={() => {
              this.setState({
                  tooFarDialogOpen: false,
                  shouldGenerateDefaultSuggestions: false,
                  justClosedDialog: true});
              this.handleChangeWithCb("");
            }}
        />
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length >= 0}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className={classes.searchBarContainer + " " + (this.props.isSmall ? classes.searchBarContainerSmall : "")}>
                <div className={classes.searchInputContainer}>
                  <input
                    {...getInputProps({
                      placeholder: "Enter city, airport, or place...",
                      className: classes.searchInput + " " + (this.props.isSmall ? classes.searchInputSmall : "") + " " + (this.props.disabled ? classes.searchInputDisabled : ""),
                    })}
                    disabled={this.props.disabled}
                    ref={this.props.setInputRef}
                    onFocus={() => this.handleInputFocus()}
                    onBlur={() => {this.handleInputBlur(); getInputProps({}).onBlur()}}
                  />
                  {this.state.address.length > 0 && (
                    <div>
                    <IconButton
                      className={classes.clearButton + " " + (this.props.isSmall ? classes.clearButtonSmall : "")}
                      onClick={() =>  this.handleChangeWithCb("")}
                    >
                      {<CloseIcon
                      iconstyle={this.props.isSmall ? {width: 16, height: 16} : {width: 20, height: 20}}
                      style={this.props.isSmall ? {width: 16, height: 16} : {width: 20, height: 20}}
                      />}
                    </IconButton>
                    <button
                        className={
                          classes.clearButtonBackground + " " +
                          (this.props.isSmall ? classes.clearButtonBackgroundSmall : "") + " " +
                          (this.props.disabled ? classes.clearButtonBackgroundDisabled : "")}>
                      x
                    </button>
                    </div>
                  )}
                </div>
                {(this.state.shouldGenerateDefaultSuggestions && suggestions.length === 0 && this.state.address.length === 0)
                   ? (this.renderSuggestions(this.generateDefaultSuggestions(), classes, getSuggestionItemProps))
                   : (suggestions.length > 0 && this.renderSuggestions(suggestions, classes, getSuggestionItemProps))}
              </div>
            );
          }}
        </PlacesAutocomplete>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(AddressSearch));
