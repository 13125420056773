import React from "react";

import Utils from "../common/Utils";

// https://developer.okta.com/quickstart/#/react/nodejs/express
// Okta tutorial is slighly broken: https://github.com/okta/okta.github.io/issues/2253
export default function withAutoLogin(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authenticated: null,
        user: null,
        authToken: null,
      };
      this.checkAuthentication();
    }

    setAuthenticated(result) {
      if (result !== this.state.authenticated) {
        this.setState(
          {authenticated: result},
          () => {
            if (!this.state.authenticated && this.authRedirect()) {
              this.props.auth.login(this.authRedirect()).then(() => this.checkAuthentication())
            }
          });

        if (result) {
          this.props.auth.getUser().then(user => {
            this.setState({user})
          });
        } else {
          this.setState({user: null});
        }

        if (result) {
          this.props.auth.getIdToken().then(authToken => {
            this.setState({authToken})
          });
        } else {
          this.setState({authToken: null});
        }
      }
    }

    checkAuthentication() {
      this.props.auth.isAuthenticated()
      .then(result => this.setAuthenticated(result))
      .catch(error => {
        Utils.log("AutoLogin error: ", error);
        this.setAuthenticated(false);
      });
    }

    // Listens to updates.
    componentDidUpdate() {
      this.checkAuthentication();
    }

    authRedirect() {
      switch (this.props.history.location.pathname) {
        case "/dashboard": return "/dashboard";
        default: return null;
      }
    }

    render() {
      if (this.authRedirect() && !this.state.user && !this.state.authToken) {
        // Will redirect.
        return null;
      }

      return <WrappedComponent {...this.props} user={this.state.user} authToken={this.state.authToken} refreshAuth={() => this.checkAuthentication()} dashboardLink={"/dashboard"} />;
    }
  }
}
