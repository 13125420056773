import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import HocUtils from "../hoc/HocUtils";

import Utils from "../common/Utils";

import FlexibleImage from "../components/FlexibleImage";
import Squares from "../components/Squares";

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.background.standard,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  image: {
    maxWidth: "100%",
    height: "auto",
    //maxWidth: 800,
  },

  manifesto: {
    margin: 15,
    marginTop: 30,
    marginBottom: 30,
    textAlign: "left",
    maxWidth: 800,
  },

  step: {
    marginLeft: 30,
    marginRight: 30,
  },
});

class HowItWorks extends Component {
  render() {
    const { classes } = this.props;

    return <div className={classes.container}>
      <Typography variant="h6" color="default">
        <br/>
        <br/>
        How It Works
        <br/>
        <br/>
      </Typography>
      <br/>
      <Squares>
        <FlexibleImage desktopImg={"/img/howto-book-800x800.jpg"} mobileImg={"/img/howto-book-800x800.jpg"} alt="Booking page" />
        <div style={{margin:20, marginBottom:Utils.isMobile() ? 0 : 20}}>
          <br/>
          <Typography variant="subtitle1" color="default" className={classes.step} align="center">
            1. Book a van
            <br/>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.step} align="center">
            Indicate the delivery location (can be approximate, like San Francisco), dates, and answer the questions regarding driver's license.
            No account signup is required.
          </Typography>
        </div>

        <FlexibleImage desktopImg={"/img/howto-phone-800x800.jpg"} mobileImg={"/img/howto-phone-800x800.jpg"} alt="Phone" />
        <div style={{margin:20, marginBottom:Utils.isMobile() ? 0 : 20}}>
          <br/>
          <Typography variant="subtitle1" color="default" className={classes.step} align="center">
            2. Confirm delivery
            <br/>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.step} align="center">
            We will contact you via email / text / phone to confirm the delivery time and location (and flight number, if you are flying in).
          </Typography>
        </div>

        <FlexibleImage desktopImg={"/img/howto-keys-800x800.jpg"} mobileImg={"/img/howto-keys-800x800.jpg"} alt="Phone" />
        <div style={{margin:20, marginBottom:Utils.isMobile() ? 0 : 20}}>
          <br/>
          <Typography variant="subtitle1" color="default" className={classes.step} align="center">
            3. Exchange keys
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.step} align="center">
            We will bring the vehicle to your location or, if you prefer, park it nearby, and simply hand you the keys.
          </Typography>
        </div>

        <FlexibleImage desktopImg={"/img/howto-halfdome-800x800.jpg"} mobileImg={"/img/howto-halfdome-800x800.jpg"} alt="Phone" />
        <div style={{margin:20, marginBottom:Utils.isMobile() ? 0 : 20}}>
          <br/>
          <Typography variant="subtitle1" color="default" className={classes.step} align="center">
            4. Drive away
            <br/>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.step} align="center">
            Have a wonderful camping trip!
          </Typography>
        </div>

        <FlexibleImage desktopImg={"/img/howto-return-800x800.jpg"} mobileImg={"/img/howto-return-800x800.jpg"} alt="Phone" />
        <div style={{margin:20, marginBottom:Utils.isMobile() ? 0 : 20}}>
          <br/>
          <Typography variant="subtitle1" color="default" className={classes.step} align="center">
            5. Return the van
            <br/>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.step} align="left">
            Park the car close to your destination, and someone will come pick it up. If you have to go, lock the keys in the car -- we have spares.
          </Typography>
        </div>

      </Squares>
      <br/>
      <br/>
      <br/>
      <br/>
      <FlexibleImage desktopImg={"/img/forest-1920x800.jpg"} mobileImg={"/img/forest-500x500.jpg"} alt="Van in forest" isBookNow={true} />
    </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(HowItWorks));

