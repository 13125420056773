import Utils from "./Utils";

export default class User {
  static fromJson(json) {
    return Utils.deepCopyJson(json);
  }

  toMime64() {
    return Utils.obfuscateParams(JSON.stringify(this.toJson()));
  }

  static fromMime64(str) {
    return new User(User.fromJson(JSON.parse(Utils.deobfuscateParams(str))));
  }

  toJson() {
    return Utils.deepCopyJson(this);
  }

  constructor(json) {
    // Contact info.
    // TODO: Is employer information truly needed?
    Object.assign(this, User.fromJson(json));
  }
};
