import Config from "./Config";

import Utils from "./common/Utils";

const MINIVAN_INFO = {
  "description": [
    "If you are looking for something way more COMFORTABLE than camping but don't want the stress & cost of driving a big van or RV, this is for you!",
    "A perfect campervan for two people.",
  ],
  "engine": "3.6L V6 gasoline",
  "features": [
    "6ft by 4ft double bed with linen, pillows, and duvet",
    "4ft kitchen counter with sink and dinnerware",
    "Butane stove, pot, pan, kettle and cooking ustensils",
    "110V AC power inverter, USB cables, Bluetooth receiver",
    "Camping table, camping chairs, lantern",
    "360 degree privacy curtains"
  ],
  "mpg": "18 mpg, 20 gal tank",
  "numPassengers": 5,
  "numSleeps": "2 (one double bed)",
  "photos": [
    "/img/minivan-out2-800x500.jpg",
    "/img/minivan-in1-800x500.jpg",
    "/img/minivan-in5-800x500.jpg",
    "/img/minivan-out1-800x500.jpg",
    "/img/minivan-out3-800x500.jpg",
    "/img/minivan-in4-800x500.jpg",
    "/img/minivan-out5.jpg",
    "/img/minivan-in6.jpg",
  ],
  "photosMobile": [
    "/img/minivan-out2-500x375.jpg",
    "/img/minivan-in1-500x375.jpg",
    "/img/minivan-in5-500x375.jpg",
    "/img/minivan-out1-500x375.jpg",
    "/img/minivan-out3-500x375.jpg",
    "/img/minivan-in4-500x375.jpg",
    "/img/minivan-out5-small.jpg",
    "/img/minivan-in6-small.jpg",
  ],
  "photoUrl": "/img/minivan-out2-800x500.jpg",
  "photoUrlMobile": "/img/minivan-out2-500x375.jpg",
  "vehicleType": "MINIVAN",
  "vehicleLength": "16.8 ft",
  "vehicleModels": [
    "Dodge Grand Caravan",
    "Chrysler Town & Country"
  ],
  "vehicleName": Utils.vehicleName("MINIVAN"),
  "vehicleYear": "2010 or later"
};

const SMALL_VAN_INFO = {
  "description": [
    "For those looking for a comfortable sleep without the stress & cost driving a full sized van or RV.",
    "A fully loaded campervan at the price of a basic rental car. Everything is included: lots of camping gear, 150 free miles, and insurance (mileage and insurance upgrades are available).",
    "Other van rental companies offer the same cars at double the price.",
    "Festival friendly. Available for Burning Man (fixed price). Not pet friendly (except for service animals, cleaning fee applies)."
  ],
  "engine": "3.6L V6 gasoline",
  "features": [
    "6ft by 4ft double bed with linen, pillows, and duvet",
    "4ft kitchen counter with sink and dinnerware",
    "Stove and cooking ustensils",
    "110V AC power inverter, USB cables, Bluetooth receiver",
    "Camping table, camping chairs, lantern",
    "360 degree privacy curtains",
  ],
  "mpg": "18 mpg, 20 gal tank",
  "numPassengers": 2,
  "numSleeps": "2 (one double bed)",
  "photos": [
    "/img/minivan0-1800x720.jpg",
  ],
  "photosMobile": [
    "/img/minivan0-420x300.jpg",
  ],
  "photoUrl": "/img/thumb-minivan0-800x500.jpg",
  "photoUrlMobile": "/img/thumb-minivan0-800x600.jpg",
  "vehicleType": "SMALL_VAN",
  "vehicleLength": "15.5 ft",
  "vehicleModels": [
    "Ram ProMaster City"
  ],
  "vehicleName": Utils.vehicleName("SMALL_VAN"),
  "vehicleYear": "2010 or later",
};

const LARGE_VAN_INFO = {
  "description": [
    "A full-size class B motorhome that sleeps 2 to 4.",
    "Includes all the amneties you expect from an RV: full bed, kitchen (sink and stove), bathroom with hot water shower, heating, fridge, microwave.",
    "Vehicles in this category include: Winnebago Travato, Hymer Aktiv, Roadtrek Simplicity / Zion.",
  ],
  "engine": "3.6L V6 280HP gasoline",
  "features": [
    "6ft by 4ft double bed with linen, pillows, and duvet",
    "Gasoline generator",
    "Air Conditioning",
    "Kitchen with sink, propane stove, dinnerware, cooking gear",
    "120V AC power, USB plugs and cables",
    "Awning, camping table & chairs",
  ],
  "mpg": "18-22 mpg",
  "numPassengers": 2,
  "numSleeps": "2 (one double bed)",
  "photos": [
    "/img/travato-out1-800x500.jpg",
    "/img/travato-in1-800x500.jpg",
    //"/img/travato-out2-800x500.jpg", -> kinda same as out1
    "/img/travato-in2-800x500.jpg",
    "/img/travato-out3-800x500.jpg",
    "/img/travato-in3-800x500.jpg",
    "/img/travato-in4.jpg",
    "/img/travato-in5.jpg",
    "/img/travato-out4.jpg",
    "/img/travato-floor-800x500.jpg",
  ],
  "photosMobile": [
    "/img/travato-out1-500x375.jpg",
    "/img/travato-in1-500x375.jpg",
    //"/img/travato-out2-500x375.jpg",
    "/img/travato-in2-500x375.jpg",
    "/img/travato-out3-500x375.jpg",
    "/img/travato-in3-500x375.jpg",
    "/img/travato-in4-small.jpg",
    "/img/travato-in5-small.jpg",
    "/img/travato-out4-small.jpg",
    "/img/travato-floor-500x375.jpg",
  ],
  "photoUrl": "/img/travato-out1-800x500.jpg",
  "photoUrlMobile": "/img/travato-out1-500x375.jpg",
  "vehicleType": "VINTAGE_VAN",
  "vehicleLength": "21 ft",
  "vehicleModels": [
    "Ram ProMaster 2500"
  ],
  "vehicleName": Utils.vehicleName("VINTAGE_VAN"),
  "vehicleYear": "2014 or later",
};

const VINTAGE_VAN_INFO = {
  "description": [
    "A full-size class B motorhome that sleeps 2 to 4.",
    "Includes all the amneties you expect from an RV: full bed, kitchen (sink and stove), bathroom with hot water shower, heating, fridge, microwave.",
    "Vehicles in this category include: Winnebago Travato, Hymer Aktiv, Roadtrek Simplicity / Zion.",
  ],
  "engine": "3.6L V6 280HP gasoline",
  "features": [
    "48\" x 75\" double bed with linen, pillows, and duvet",
    "Gasoline generator",
    "Air Conditioning",
    "Kitchen with sink, propane stove, dinnerware, cooking gear",
    "120V AC power, USB plugs and cables",
    "Awning, camping table & chairs",
  ],
  "mpg": "18-22 mpg",
  "numPassengers": 2,
  "numSleeps": "2 (one double bed)",
  "photos": [
    "/img/travato-out1-800x500.jpg",
    "/img/travato-in1-800x500.jpg",
    //"/img/travato-out2-800x500.jpg", -> kinda same as out1
    "/img/travato-in2-800x500.jpg",
    "/img/travato-out3-800x500.jpg",
    "/img/travato-in3-800x500.jpg",
    "/img/travato-in4.jpg",
    "/img/travato-in5.jpg",
    "/img/travato-out4.jpg",
    "/img/travato-floor-800x500.jpg",
  ],
  "photosMobile": [
    "/img/travato-out1-500x375.jpg",
    "/img/travato-in1-500x375.jpg",
    //"/img/travato-out2-500x375.jpg",
    "/img/travato-in2-500x375.jpg",
    "/img/travato-out3-500x375.jpg",
    "/img/travato-in3-500x375.jpg",
    "/img/travato-in4-small.jpg",
    "/img/travato-in5-small.jpg",
    "/img/travato-out4-small.jpg",
    "/img/travato-floor-500x375.jpg",
  ],
  "photoUrl": "/img/travato-out1-800x500.jpg",
  "photoUrlMobile": "/img/travato-out1-500x375.jpg",
  "vehicleType": "LARGE_VAN",
  "vehicleLength": "21 ft",
  "vehicleModels": [
    "Ram ProMaster 2500"
  ],
  "vehicleName": Utils.vehicleName("LARGE_VAN"),
  "vehicleYear": "2014 or later",
};

const CLASS_B_INFO = {
  "description": [
    "A full-size class B motorhome that sleeps 2 to 4.",
    "Includes all the amneties you expect from an RV: full bed, kitchen (sink and stove), bathroom with hot water shower, heating, fridge, microwave.",
    "Vehicles in this category include: Winnebago Travato, Hymer Aktiv, Roadtrek Simplicity / Zion.",
  ],
  "engine": "3.6L V6 280HP gasoline",
  "features": [
    "46\"x 77\" double bed with linen, pillows, and duvet",
    "Gasoline generator",
    "Air Conditioning",
    "Kitchen with sink, fridge, propane stove, dinnerware, cooking gear",
    "Wet bath with hot water & shower",
    "120V AC power, USB plugs and cables",
    "Awning, camping table & chairs",
  ],
  "mpg": "18-22 mpg",
  "numPassengers": 4,
  "numSleeps": "4",
  "photos": [
    "/img/travato-out1-800x500.jpg",
    "/img/travato-in1-800x500.jpg",
    //"/img/travato-out2-800x500.jpg", -> kinda same as out1
    "/img/travato-in2-800x500.jpg",
    "/img/travato-out3-800x500.jpg",
    "/img/travato-in3-800x500.jpg",
    "/img/travato-in4.jpg",
    "/img/travato-in5.jpg",
    "/img/travato-out4.jpg",
    "/img/travato-floor-800x500.jpg",
  ],
  "photosMobile": [
    "/img/travato-out1-500x375.jpg",
    "/img/travato-in1-500x375.jpg",
    //"/img/travato-out2-500x375.jpg",
    "/img/travato-in2-500x375.jpg",
    "/img/travato-out3-500x375.jpg",
    "/img/travato-in3-500x375.jpg",
    "/img/travato-in4-small.jpg",
    "/img/travato-in5-small.jpg",
    "/img/travato-out4-small.jpg",
    "/img/travato-floor-500x375.jpg",
  ],
  "photoUrl": "/img/travato-out1-800x500.jpg",
  "photoUrlMobile": "/img/travato-out1-500x375.jpg",
  "vehicleType": "CLASS_B",
  "vehicleLength": "21 ft",
  "vehicleModels": [
    "Winnebago Travato",
    "Hymer Aktiv",
    "Roadtrek Simplicity",
  ],
  "chassisModels": [
    "Ram ProMaster 2500"
  ],
  "vehicleName": Utils.vehicleName("CLASS_B"),
  "vehicleYear": "2014 or later",
};

const CLASS_C_INFO = {
  "description": [
    "A compact, easy to drive RV for you and your family. Sleeps 5-6.",
    "Includes all the amneties you expect from a Class C RV: queen bed, bunk bed over cab, kitchen area (sink and stove), dinette, bathroom with hot water shower, heating, fridge, microwave.",
    "Vehicles in this category include: Winnebago Minnie Winnie, Thor Four Winds, Coachment Freelander.",
  ],
  "engine": [
    "6.0L V8 323HP",
    "6.8L V10 305HP",
  ],
  "features": [
    "60\" x 80\" in queen bed with linen, pillows, and duvet",
    "Gasoline generator",
    "Air Conditioning",
    "Kitchen with sink, fridge, propane stove, dinnerware, cooking gear",
    "Dry bath with hot water & shower",
    "120V AC power, USB plugs and cables",
    "Awning, camping table & chairs",
  ],
  "mpg": "10-15 mpg",
  "numPassengers": 5,
  "numSleeps": "5 - 6",
  "photos": [
    "/img/spirit-out1.jpg",
    "/img/spirit-out2.jpg",
    "/img/spirit-out3.jpg",
    "/img/spirit-in1.jpg",
    "/img/spirit-in2.jpg",
    "/img/spirit-in3.jpg",
    "/img/spirit-in4.jpg",
    "/img/spirit-in5.jpg",
    "/img/spirit-in6.jpg",
    "/img/spirit-in7.jpg",
    "/img/spirit-floorplan.jpg",
  ],
  "photosMobile": [
    "/img/spirit-out1-small.jpg",
    "/img/spirit-out2-small.jpg",
    "/img/spirit-out3-small.jpg",
    "/img/spirit-in1-small.jpg",
    "/img/spirit-in2-small.jpg",
    "/img/spirit-in3-small.jpg",
    "/img/spirit-in4-small.jpg",
    "/img/spirit-in5-small.jpg",
    "/img/spirit-in6-small.jpg",
    "/img/spirit-in7-small.jpg",
    "/img/spirit-floorplan-small.jpg",
  ],
  "photoUrl": "/img/spirit-out1.jpg",
  "photoUrlMobile": "/img/spirit-out1-small.jpg",
  "vehicleType": "CLASS_C",
  "vehicleLength": "24 ft",
  "vehicleModels": [
    "Winnebago Minnie Winnie",
    "Thor Four Winds",
    "Coachmen Freelander",
  ],
  "chassisModels": [
    "Ford E350",
    "Chevy 4500",
  ],
  "vehicleName": Utils.vehicleName("CLASS_C"),
  "vehicleYear": "2010 or later",
};

function getVehicleInfo(vehicleType) {
  switch (vehicleType) {
    case "MINIVAN": return MINIVAN_INFO;
    case "SMALL_VAN": return SMALL_VAN_INFO;
    case "LARGE_VAN": return LARGE_VAN_INFO;
    case "VINTAGE_VAN": return VINTAGE_VAN_INFO;
    case "CLASS_B": return CLASS_B_INFO;
    case "CLASS_C": return CLASS_C_INFO;
    default: throw new Error("Invalid vehicle type: " + vehicleType);
  }
}

function pricedVehicles() {
  return availableVehicles();
}

function availableVehicles() {
  //return Config.get("MARKETING_MODE") ? ["MINIVAN", "SMALL_VAN", "LARGE_VAN", "VINTAGE_VAN", "CLASS_B", "CLASS_C"] : ["MINIVAN"];
  return Config.get("MARKETING_MODE") ? ["CLASS_B", "CLASS_C", "MINIVAN"] : ["MINIVAN"];
}

function comingSoonVehicles() {
  return [];
  //return ["LARGE_VAN"];
}

function isAvailable(vehicleType) {
  return availableVehicles().indexOf(vehicleType) > -1;
}

function isPriced(vehicleType) {
  return pricedVehicles().indexOf(vehicleType) > -1;
}

export default class VehicleInfoContents {
  static availableVehicles() {
    return availableVehicles();
  }

  static availableVehicleInfos() {
    return availableVehicles().map(vehicleType => getVehicleInfo(vehicleType));
  }

  static decorateVehicleInfo(vehicleType, priceInfo) {
    return Object.assign({}, priceInfo, getVehicleInfo(vehicleType));
  }

  static flattenAndDecorate(response, addComingSoon) {
    let vehicles = [];
    for (let location in response) {
      for (let vehicleType in response[location]) {
        if (!isPriced(vehicleType)) {
          continue;
        }

        let vehicleInfo = VehicleInfoContents.decorateVehicleInfo(vehicleType, response[location][vehicleType]);

        if (!isAvailable(vehicleType)) {
          vehicleInfo.comingSoon = true;
        }

        vehicles.push(vehicleInfo);
      }

      for (let vehicleType of availableVehicles()) {
        if (vehicles.find(info => info.vehicleType === vehicleType)) {
          continue;
        }
        let vehicleInfo = getVehicleInfo(vehicleType);
        vehicles.push(vehicleInfo);
      }

      if (addComingSoon) {
        for (let vehicleType of comingSoonVehicles()) {
          if (vehicles.find(info => info.vehicleType === vehicleType)) {
            continue;
          }
          let vehicleInfo = getVehicleInfo(vehicleType);
          vehicleInfo.comingSoon = true;
          vehicles.push(vehicleInfo);
        }
      }
    }
    return vehicles;
  }

  static getLowerPrice(vehicleType) {
    switch (vehicleType) {
      case "MINIVAN": return 3900;
      case "SMALL_VAN": return 5900;
      case "VINTAGE_VAN": return 7900;
      case "LARGE_VAN": return 14900;
      case "CLASS_B": return 16900;
      case "CLASS_C": return 14900;
      default:
        throw new Error("Unsupported type: " + vehicleType);
    }
  }

  static getHigherPrice(vehicleType) {
    switch (vehicleType) {
      case "MINIVAN": return 10000;
      case "SMALL_VAN": return 12000;
      case "VINTAGE_VAN": return 15000;
      case "LARGE_VAN": return 25000;
      case "CLASS_B": return 27000;
      case "CLASS_C": return 25000;
      default:
        throw new Error("Unsupported type: " + vehicleType);
    }
  }
}

