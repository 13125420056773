import React from "react";

import OrmPool from "../common/OrmPool";

import Api from "../Api";
import FrontendUtils from "../FrontendUtils";

export default function withOwnerOrm(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        orm: null,
        username: null,
      };

      this.refresh();
    }

    componentDidUpdate() {
      if (!this.state.orm) {
        this.refresh();
      }
    }

    refresh() {
      if (this.props.user && this.props.authToken) {
        let username = FrontendUtils.parseProps(this.props).user;
        if (username) {
          if (username === "RESET") {
            username = this.props.user.preferred_username; // Reset to admin.
          } else {
            // Keep new username.
          }
        } else {
          // Keep old username.
          username = this.state.username ? this.state.username : this.props.user.preferred_username;
        }
        Api.loadOwnerDashboard(this.props.authToken, username).then(jsonPool => {
          let orm = OrmPool.create(jsonPool);
          this.setState({orm, username});
        });
      }
    }

    render() {
      if (!this.state.orm) {
        return null;
      }

      return <WrappedComponent {...this.props} orm={this.state.orm} refreshOrm={() => this.refresh()} username={this.state.username} />;
    }
  };
}
