import { withRouter } from "react-router-dom";

import { withAuth } from "@okta/okta-react";

import withAutoLogin from "./withAutoLogin";
import withAdminOrm from "./withAdminOrm";
import withDemoAuth from "./withDemoAuth";
import withDemoOwnerOrm from "./withDemoOwnerOrm";
import withIntercom from "./withIntercom";
import withHeader from "./withHeader";
import withOwnerOrm from "./withOwnerOrm";
import withRefreshOnResize from "./withRefreshOnResize";

export default class HocUtils {

  static withRenter(x) {
    return withAuth(withAutoLogin(withIntercom(withHeader(withRouter(x)))));
  }

  static withOwner(x) {
    return withAuth(withAutoLogin(withOwnerOrm(withIntercom(withHeader(withRouter(x))))));
  }

  static withDemoOwner(x) {
    return withDemoAuth(withAutoLogin(withDemoOwnerOrm(withIntercom(withHeader(withRouter(x))))));
  }

  static withAdmin(x) {
    return withAuth(withAutoLogin(withAdminOrm(withHeader(withRouter(x)))));
  }

  static withRefreshOnResize(x) {
    return withRefreshOnResize(x);
  }
}

