import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import LocationMap from "./LocationMap";
import ProgressBar from "./ProgressBar";
import OrderInfo from "./OrderInfo";
import VehicleEventInfo from "./VehicleEventInfo";

import Utils from "../common/Utils";
import FrontendUtils from "../FrontendUtils";

const MIN_WIDTH_LEFT = 290;

function mapWidth() {
  if (Utils.isMobile()) {
    return window.innerWidth;
  }
  
  if (window.innerWidth < MIN_WIDTH_LEFT + 40) {
    return 0;
  }

  return window.innerWidth - MIN_WIDTH_LEFT - 40;
}

function eventsHeight() {
  if (Utils.isMobile()) {
    return null;
  }

  const mapHeight = mapWidth() / FrontendUtils.aspectRatio();
  return mapHeight;
}

const styles = theme => ({
  events: {
    overflowY: Utils.isMobile() ? null : "scroll",
  },

  flexReverse: {
    display: "flex",
    flexDirection: Utils.isMobile() ? "column-reverse" : "row",
  },
});

class TripReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedEvent: null,
    };
  }
 
  createModel() {
    const order = this.props.order;
    const booking = order.booking;
    const bookingEvents = this.props.orm.vehicleEvents.filter(x => x.info.bookingCode === booking.bookingCode);

    const ownerPickupEvent = bookingEvents.find(x => x.info.type === "OWNER_PICKUP");
    const ownerDropoffEvent = bookingEvents.find(x => x.info.type === "OWNER_DROPOFF");
    const startTripEvent = bookingEvents.find(x => x.info.type === "TRIP_START");
    const finishTripEvent = bookingEvents.find(x => x.info.type === "TRIP_FINISH");
    const tripEvents = bookingEvents.filter(x => (ownerPickupEvent && x.created >= ownerPickupEvent.created) && (!ownerDropoffEvent || x.created <= ownerDropoffEvent.created));
    const gpsEvents = tripEvents.filter(x => x.info.type === "GPS");
    const visibleEvents = bookingEvents.filter(x => x.info.type !== "GPS").reverse();

    let lastEvent = tripEvents.length ? tripEvents[tripEvents.length - 1] : null;
    if (lastEvent && lastEvent.info.type === "GPS") {
      lastEvent = lastEvent.clone();
      lastEvent.info.type = "LAST_GPS";
      visibleEvents.unshift(lastEvent);
    }

    const lastKnownOdometer = (x => x ? x.info.odometer : null)(bookingEvents.reverse().find(x => x.info.odometer));
    const now = Utils.now();

    const totalTime = ownerPickupEvent ? (ownerDropoffEvent ? ownerDropoffEvent.created : now).diff(ownerPickupEvent.created, "minute") : null;
    const totalDistance = ownerPickupEvent ? (ownerDropoffEvent ? ownerDropoffEvent.info.odometer : lastKnownOdometer) - ownerPickupEvent.info.odometer : null;
    const tripTime = startTripEvent ? (finishTripEvent ? finishTripEvent.created : now).diff(startTripEvent.created, "minute") : null;
    const tripDistance = startTripEvent ? (finishTripEvent ? finishTripEvent.info.odometer : lastKnownOdometer) - startTripEvent.info.odometer : null;

    //const milesIncluded = booking.info.extras.find(x => x === "UNLIMITED_MILES") ? null : order.computeMilesCount();
    const milesIncluded = 1200;
    const timeIncluded = order.numDays() * 24 * 60;
    const amount = order.computeOwnerAmount();

    const incomePerMile = totalDistance ? amount / totalDistance : null;

    const percentTime = finishTripEvent ? 1 : (timeIncluded ? (tripTime ? tripTime : 0) / timeIncluded : null);
    const percentMiles = milesIncluded ? (tripDistance ? tripDistance : 0) / milesIncluded : null;

    // TODO: Create middle trip event.
    const middleTripEvent = null;

    const toCoords = x => x.map(y => Utils.array2coords(y.info.coords));
    const coords = toCoords(gpsEvents);

    const segments = [
      {
        coords: toCoords(gpsEvents.filter(x => (ownerPickupEvent && x.created >= ownerPickupEvent.created) && (!startTripEvent || x.created <= startTripEvent.created))),
        strokeColor: "#555",
        strokeWeight: 3,
      },
      {
        coords: toCoords(gpsEvents.filter(x => (startTripEvent && x.created >= startTripEvent.created) && (!finishTripEvent || x.created <= finishTripEvent.created))),
        strokeColor: "#3B82CB",
        strokeWeight: 4,
      },
      {
        coords: toCoords(gpsEvents.filter(x => (finishTripEvent && x.created >= finishTripEvent.created) && (!ownerDropoffEvent || x.created <= ownerDropoffEvent.created))),
        strokeColor: "#555",
        strokeWeight: 3,
      },
    ];

    return {
      booking,
      order,
      bookingEvents,
      ownerPickupEvent,
      ownerDropoffEvent,
      startTripEvent,
      finishTripEvent,
      tripEvents,
      gpsEvents,
      visibleEvents,
      lastKnownOdometer,
      now,
      totalTime,
      totalDistance,
      tripTime,
      tripDistance,
      milesIncluded,
      timeIncluded,
      percentMiles,
      percentTime,
      amount,
      incomePerMile,
      middleTripEvent,
      coords,
      segments,
    };
  }
 
  renderEvent(event, i) {
    return <div key={event.id}>
      {i === 0 || <hr style={{marginLeft:20, marginRight:20}} />}
      <div style={{margin:20, cursor:"pointer"}} onClick={() => this.setState({focusedEvent: event})} align="left">
        <VehicleEventInfo vehicleEvent={event} darken={i === 0} isClickable={true} />
      </div>
    </div>;
  }

  render() {
    const { classes } = this.props;

    if (!this.props.order) {
      return <div align="center">
        <Typography color="textSecondary" variant="subtitle1">
          This vehicle is not currently on a trip.
        </Typography>
      </div>;
    }

    const model = this.createModel();

    //Utils.log("MODEL: ", model);

    return <div>
      <div style={{marginLeft:20, marginRight:20}}>
        <Typography variant="body2" color="textSecondary" align="left">
          Progress: {Utils.rate2percent(model.percentTime, 0)} of {Utils.prettyMinutes(model.timeIncluded)}
          {model.percentMiles
            ? ", " + Utils.rate2percent(model.percentMiles, 0) + " of " + model.milesIncluded + " miles"
            : null}
        </Typography>
        <div style={{marginTop:10, marginBottom:10}}>
          <ProgressBar progress={model.percentTime} />
        </div>
        <br/>
      </div>

      <div className={classes.flexReverse} style={{width:"100%"}}>
        <div className={classes.events} style={{background: "white", minWidth: MIN_WIDTH_LEFT, height:eventsHeight()}}>
          <Typography variant="body2" color="textSecondary" style={{opacity:0.7, margin:10}} align="left">
            Trip Details
          </Typography>

          <div style={{margin:20}}>
            <OrderInfo order={model.order} />
            <br/>
          </div>

          <Typography variant="body2" color="textSecondary" style={{opacity:0.7, margin:10}} align="left">
            Updates
          </Typography>
          {model.visibleEvents.length === 0 &&
              <div style={{margin:20}} align="left">
                <Typography variant="body2" color="textSecondary" align="center">
                  No updates yet
                </Typography>
              </div>}

          {model.visibleEvents.map((x, i) => this.renderEvent(x, i))}
        </div>

        <div style={{margin:Utils.isMobile() ? 10 : 0}} />

        <div style={{background: "white", width:"100%"}}>
          <Paper className={Utils.isMobile() ? null : "gradientMap"}>
            <LocationMap coords={model.coords} segments={model.segments}
                        focusedCoords={this.state.focusedEvent ? Utils.array2coords(this.state.focusedEvent.info.coords) : null} />
          </Paper>
        </div>
      </div>
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(TripReport);
