import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import ClosableModal from "./ClosableModal";

const styles = theme => ({
  vehicle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    justifyContent: "flex-start",
  },
});

class VehicleSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  renderVehicleDetails(selectedVehicle, vehicle) {
    return <div key={vehicle.id} align="left" className={this.props.classes.vehicle} style={{marginBottom:20}}>
      <Radio
        checked={selectedVehicle === vehicle}
        onChange={() => { this.setState({open: false}); this.props.onSelect(vehicle)}}
        value={"" + vehicle.id}
        aria-label="B"
      />
      <Typography color="textSecondary">
        Vehicle Type: {Utils.vehicleName(vehicle.vehicleType)}
        <br/>
        Model: {vehicle.info.year} {vehicle.info.make} {vehicle.info.model}
        <br/>
        VIN: {vehicle.info.vin}
        <br/>
        License Plate: {vehicle.info.plate} {vehicle.info.state}
      </Typography>
      <br/>
    </div>;
  }

  render() {
    let { selectedVehicle } = this.props;
    if (this.props.autoSelect && !selectedVehicle && this.props.vehicles.length) {
      selectedVehicle = this.props.vehicles[0];
    }

    return <div align="center" style={Utils.isMobile() ? {} : {display: "flex", alignItems: "center"}}>
      <Typography variant="caption" color="textSecondary" style={{marginRight: Utils.isMobile() ? 0 : 10}}>
        Vehicle
      </Typography>
      <Button variant="outlined" color="secondary" size="small" onClick={() => this.setState({open: true})}>
        <span style={{whiteSpace: "nowrap"}}>{selectedVehicle ? selectedVehicle.info.plate + String.fromCharCode(160) + selectedVehicle.info.state : "Select Vehicle"}</span>
      </Button>
      <ClosableModal title="Select Vehicle" open={this.state.open} onClose={() => this.setState({open: false})}>
        <div>
          {this.props.vehicles.map(x => this.renderVehicleDetails(selectedVehicle, x))}
        </div>
      </ClosableModal>
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(VehicleSelector));
