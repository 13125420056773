import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

const styles = theme => ({
  // Centerd, max 120, scale image afterwards.
  // https://stackoverflow.com/questions/3029422/how-do-i-auto-resize-an-image-to-fit-a-div-container
  // TODO: Add pic or remove this.
  pic: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: 50,
    margin: 15,
    marginLeft: 0,
  },
});

class OrderInfo extends Component {
  dashboardLink() {
    return this.props.history.location.pathname.startsWith("/dashboard-demo") ? "/dashboard-demo" : "/dashboard";
  }

  render() {
    const { link, order, isAdminView } = this.props;
    const milesIncluded = order.booking.info.extras.find(x => x === "UNLIMITED_MILES") ? null : order.computeMilesCount();

    // Utils.log("OrderInfo: ", order);

    const title = order.booking.info.contact && order.booking.info.contact.destination
            ? "Trip to " + order.booking.info.contact.destination
            : "Trip around California";

    return <div align="left">
      {link && order.booking.info.vehicle
          ? <div className={"bluelink"}>
              <Typography color="inherit" variant="subtitle1">
                <Link
                    to={this.dashboardLink() + "?type=trip&trip=" + Utils.scramble(order.booking.bookingCode) +
                        (isAdminView ? "&user=" + order.booking.info.vehicle.owner.username : "")}>
                  {title}
                </Link>
              </Typography>
            </div>
          : <Typography color="textSecondary" variant="body2">
              <b>{title}</b>
            </Typography>}
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <Typography color="textSecondary" variant="body2">
          <span>{Utils.prettyDate(order.booking.startDate)} - {Utils.prettyDate(order.booking.endDate)}</span>
        </Typography>
        <span style={{margin:10}} />
        <Typography color="textSecondary" variant="body2">
          <span>{Utils.prettyMoney(order.computeOwnerAmount())}</span>
        </Typography>
        {/*<b>{renter.info.fbInfo.name}</b>*/}
        {/*renter.info.fbInfo.email*/}
      </div>
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <Typography color="textSecondary" variant="body2" style={{marginRight:10, opacity:0.7}}>
          {order.numDays()} nights
        </Typography>
        <Typography color="textSecondary" variant="body2" style={{opacity:0.7}}>
          {milesIncluded ? Utils.prettyMiles(milesIncluded) + "les included" : "Unlimited Miles Included"}
        </Typography>
      </div>
      <Typography variant="body2" color="textSecondary" style={{marginTop:10}} align="left">
        Delivered to:
        <br/>
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{opacity:0.7}} align="left">
        {order.booking.info.place.address}
      </Typography>
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(OrderInfo));
