import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import FrontendUtils from "../FrontendUtils";

import HocUtils from "../hoc/HocUtils";

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import OwnerCalendar from "../components/OwnerCalendar";
import TripReport from "../components/TripReport";
import VehicleReport from "../components/VehicleReport";
import VehicleReportTotals from "../components/VehicleReportTotals";

const LEFT_HEIGHT_VERTICAL = 968 - 48;
const LEFT_HEIGHT_HORIZONTAL = 676 - 48;

const styles = theme => ({
  container: {
    //backgroundColor: theme.palette.background.standard,
    width: "100%",
    padding: Utils.isMobile() ? 0 : 20,
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  flexReverse: {
    display: "flex",
    flexDirection: Utils.isMobile() ? "column-reverse" : "row",
  },

  report: {
    paddingLeft: Utils.isMobile() ? 0 : 10,
    paddingRight: Utils.isMobile() ? 0 : 10,
  },
});

class OwnerDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
    }
  }

  render() {
    const { classes, orm } = this.props;
    const vehicle = orm.vehicles.find(x => x.plate() === this.props.vehiclePlate);

     // Utils.log("PROPS: ", this.props);

    if (!orm) {
      return <CircularProgress />
    }

    if (!vehicle) {
      return <div>
        <Typography color="textSecondary">
          Vehicle Not Found.
        </Typography>
      </div>;
    }

    const orders = orm.orders.filter(x => x.booking.info.vehicle_id === vehicle.id);
    const vehicleEvents = orm.vehicleEvents.filter(x => x.vehicle_id === vehicle.id);
    const ongoingOrder = orders.find(x => x.booking.isPreparing() || x.booking.isOngoing() || x.booking.isFinished());

    const queryParams = FrontendUtils.parseProps(this.props);

    let view = null;
    switch (queryParams.type) {
      case "calendar":
        view = this.renderCalendarView(orders, vehicleEvents, vehicle);
        break;
      case "trip":
        if (!queryParams.trip) {
          view = this.renderTripView(ongoingOrder);
        } else {
          const bookingCode = Utils.unscramble(queryParams.trip);
          const order = orders.find(x => x.booking.bookingCode === bookingCode);
          if (order) {
            view = this.renderTripView(order);
          } else {
            view = this.renderCalendarView(orders, vehicleEvents, vehicle)
          }
        }
        break;
      default:
        if (ongoingOrder) {
          view = this.renderCalendarView(orders, vehicleEvents, vehicle)
          //view = this.renderTripView(ongoingOrder);
        } else {
          view = this.renderCalendarView(orders, vehicleEvents, vehicle)
        }
        break;
    }

    return <div className={classes.container}>
      {view}
    </div>;
  }

  renderTripView(order) {
    return <div style={{width:"100%"}}>
      {/*TODO: Change when we can have current trips! this.state.orders.filter(x => x.booking.isOngoing()).map(*/}
      <TripReport orm={this.props.orm} order={order} />
    </div>;
  }

  renderTabs(orders, vehicleEvents) {
    const isVertical = Utils.isMobile() || Utils.isMediumScreen();
    const height = Utils.isMobile() ? null : (isVertical ? LEFT_HEIGHT_VERTICAL : LEFT_HEIGHT_HORIZONTAL);

    if (Utils.isMobile()) {
      return <div>
        <VehicleReportTotals orders={orders} vehicleEvents={vehicleEvents} type="bookings"/>
        <VehicleReport orders={orders} vehicleEvents={vehicleEvents} type="bookings"/>
        <VehicleReportTotals orders={orders} vehicleEvents={vehicleEvents} type="expenses"/>
        <VehicleReport orders={orders} vehicleEvents={vehicleEvents} type="expenses"/>
      </div>;
    } else {
      return <div style={{width: Utils.isMobile() ? "100%" : 320}}>
        <AppBar position="static" color="inherit" style={{zIndex:0, background:"white" /* Otherwise it doesn't get greyed on calendar modal */}}>
          <Tabs value={this.state.tabValue} onChange={(event, value) => this.setState({tabValue: value})} fullWidth>
            <Tab label="Bookings" />
            <Tab label="Expenses" />
          </Tabs>
        </AppBar>
        {this.state.tabValue === 0 && <div style={{height, overflowY: "scroll"}}><VehicleReport orders={orders} vehicleEvents={vehicleEvents} type="bookings"/></div>}
        {this.state.tabValue === 1 && <div style={{height, overflowY: "scroll"}}><VehicleReport orders={orders} vehicleEvents={vehicleEvents} type="expenses"/></div>}
      </div>
    }
  }

  renderCalendarView(orders, vehicleEvents, vehicle) {
    const isVertical = Utils.isMobile() || Utils.isMediumScreen();

    return <div className={this.props.classes.flexReverse}>
      {this.renderTabs(orders, vehicleEvents)}
      <div style={{margin:10}} />
      <div>
        <OwnerCalendar vehicleEvents={vehicleEvents} orders={orders} refreshOrm={this.props.refreshOrm} vehicle={vehicle}
                       authToken={this.props.authToken} username={this.props.username} />
        {Utils.isMobile()
          ? null
          : <div style={{display:"flex", justifyContent:"space-around", flexDirection: isVertical ? "column" : "row"}}>
              <VehicleReportTotals orders={orders} vehicleEvents={vehicleEvents} type="bookings"/>
              <div style={{margin:10}} />
              <VehicleReportTotals orders={orders} vehicleEvents={vehicleEvents} type="expenses"/>
            </div>}
      </div>
    </div>;
  }
}

export default HocUtils.withRefreshOnResize(HocUtils.withOwner(withStyles(styles, { withTheme: true })(OwnerDashboard)));
export const DemoOwnerDashboard = HocUtils.withRefreshOnResize(HocUtils.withDemoOwner(withStyles(styles, { withTheme: true })(OwnerDashboard)));

