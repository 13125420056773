import axios from "axios";
import Utils from "./common/Utils";
import Order from "./common/Order";
import Booking from "./common/Booking";

import Config from "./Config";

const SERVER_URL = Config.get("SERVER_URL");

export default class Api {
  // TODO: DECORATE????
  // TODO: Maybe merge searchVehicles and getAllBusyDays in one single API call?
  // That returns the availability (and maybe prices too) for given filter params: location, dates, vehicle type.
  // I stored a data cute and I only need one method to query it!
  // cb returns one parameter: an array of vehicle jsons
  static async searchVehicles(location, vehicleType, startDate, endDate, bookingCode, cb) {
    let start = Utils.date2param(startDate);
    let end = Utils.date2param(endDate);

    let params = {start, end, location, vehicleType, bookingCode};

    Utils.log("/vehicle/search request: ", params);
    axios.get(SERVER_URL + "/vehicle/search", {params})
    .then(response => {
      Utils.log("/vehicle/search response: ", response);
      let pricings = response.data.pricings;
      for (location in pricings) {
        for (vehicleType in pricings[location]) {
          pricings[location][vehicleType].pricing = Order.pricingFromJson(pricings[location][vehicleType].pricing);
        }
      }
      cb(pricings);
    })
    .catch(error => {
      Utils.log("/vehicle/search error: ", error);
    });
  }

  static async lookupBooking(bookingCode) {
    try {
      Utils.log("/vehicle/booking request: ", bookingCode);
      let response = await axios.get(
          SERVER_URL + "/vehicle/booking", {params: {bookingCode}});
      Utils.log("/vehicle/booking response: ", response.data);
      response.data.booking = new Booking(response.data.booking);
      response.data.order = response.data.booking ? new Order(response.data.order, response.data.booking) : null;
      return response.data;
    } catch (error) {
      Utils.log("/vehicle/booking error: ", error);
      throw new Error("Booking not found: " + bookingCode);
    }
  }

  static async getAllBusyDays(vehicleTypes, previousBookingCode, cb) {
    let params = {vehicleTypes: vehicleTypes.join(), previousBookingCode};

    Utils.log("/vehicle/busy-days request: ", params);
    axios.get(SERVER_URL + "/vehicle/busy-days", {params})
    .then(response => {
      const busyDays = {};
      Utils.locations().map(x => busyDays[x] = {});
      for (let location in response.data.busyDays) {
        for (let date of response.data.busyDays[location]) {
          busyDays[location][date] = true;
        }
      }
      Utils.log("/vehicle/busy-days response: ", busyDays);
      cb(busyDays);
    })
    .catch(error => {
      Utils.log("/vehicle/busy-days error: ", error);
    });
  }

  static async change(booking, penalties, tokenId, stripeChargeReq) {
    try {
      booking = booking.toJson();
      penalties.dailyPenalties = Order.penaltiesToJson(penalties.dailyPenalties);
      Utils.log("/payment/checkokut request: ", booking, penalties, tokenId, stripeChargeReq);
      let response = await axios.post(SERVER_URL + "/payment/checkout", {booking, penalties, tokenId, stripeChargeReq});
      Utils.log("/payment/checkout response: ", response);
      return response;
    } catch (error) {
      Utils.log("/payment/checkout error: ", error);
      throw new Error("Could not change booking: " + booking.bookingCode);
    }
  }

  static async addEmail(bookingCode, newEmail) {
    try {
      Utils.log("/payment/add-email request: ", bookingCode, newEmail);
      let response = await axios.post(SERVER_URL + "/payment/add-email", {bookingCode, newEmail});
      Utils.log("/payment/add-email response: ", response);
      return response;
    } catch (error) {
      Utils.log("/payment/add-email error: ", error);
      throw new Error("Could not add email " + newEmail + " to booking " + bookingCode);
    }
  }

  static async setBookingContact(bookingCode, contact) {
     try {
      Utils.log("/vehicle/set-booking-contact request: ", bookingCode, contact);
      let response = await axios.post(SERVER_URL + "/vehicle/set-booking-contact", {bookingCode, contact});
      Utils.log("/vehicle/set-booking-contact response: ", response);
      return response;
    } catch (error) {
      Utils.log("/vehicle/set-booking-contact error: ", error);
      throw new Error("Could not submit setBookingContact request for " + bookingCode + ": " + contact);
    }
  }

  static async setDrivers(bookingCode, drivers) {
    try {
      Utils.log("/vehicle/set-drivers request: ", bookingCode, drivers);
      let response = await axios.post(SERVER_URL + "/vehicle/set-drivers", {bookingCode, drivers});
      Utils.log("/vehicle/set-drivers response: ", response);
      return response;
    } catch (error) {
      Utils.log("/vehicle/set-drivers error: ", error);
      throw new Error("Could not submit setDrivers request for " + bookingCode + ": " + drivers);
    }
  }

  static async uploadFile(file) {
     try {
      Utils.log("/vehicle/upload-file request: ", file);
      // https://stackoverflow.com/questions/43013858/ajax-post-a-file-from-a-form-with-axios
      const formData = new FormData();
      formData.append("image", file);
      formData.append("preview", file.preview);
      let response = await axios.post(SERVER_URL + "/vehicle/upload-file",
          formData,
          { headers: {"Content-Type": "multipart/form-data"} });
      Utils.log("/vehicle/upload-file response: ", response);
      return response.data;
    } catch (error) {
      Utils.log("/vehicle/upload-file error: ", error);
      throw new Error("Could not submit uploadFile request for " + file.name);
    }
  }

  static async isPaymentConfirmed(tokenId) {
    try {
      Utils.log("/payment/is-confirmed request: ", tokenId);
      let response = await axios.get(
          SERVER_URL + "/payment/is-confirmed", {params: {tokenId}});
      Utils.log("/payment/is-confirmed response: ", response.data);
      return response.data;
    } catch (error) {
      Utils.log("/payment/is-confirmed error: ", error);
      throw new Error("Payment not confirmed: " + tokenId);
    }
  }

  static async getCity() {
    try {
      Utils.log("getCity request");
      let response1 = await axios.get("https://api.ipify.org", {});
      let response2 = await axios.get(
          SERVER_URL + "/vehicle/city", {params: {ip: response1.data}});
      Utils.log("getCity response: ", response2.data.city);
      return response2.data.city;
    } catch (error) {
      Utils.log("getCity error: ", error);
      throw new Error("getCity error");
    }
  }

  static async loadOwnerDashboard(authToken, username) {
    try {
      Utils.log("loadOwnerDashboard request: ", username);
      let response = await axios.get(
          SERVER_URL + "/vehicle/owner-dashboard", { params: { username }, headers: { Authorization: "Bearer " + authToken } })
      Utils.log("loadOwnerDashboard response: ", response.data);
      return response.data;
    } catch (error) {
      Utils.log("loadOwnerDashboard error: ", error);
      throw new Error("loadOwnerDashboard error");
    }
  }

  static async loadAdminDashboard(authToken) {
    try {
      Utils.log("loadAdminDashboard request");
      let response = await axios.get(
          SERVER_URL + "/admin/dashboard", { headers: { Authorization: "Bearer " + authToken } });
      Utils.log("loadAdminDashboard response: ", response.data);
      return response.data;
    } catch (error) {
      Utils.log("loadAdminDashboard error: ", error);
      throw new Error("loadAdminDashboard error");
    }
  }

  static async downloadContract(authToken, bookingCode) {
     try {
      Utils.log("/admin/fill-contract request: ", bookingCode);
      let response = await axios.get(SERVER_URL + "/admin/fill-contract", { params: { bookingCode }, headers: { Authorization: "Bearer " + authToken} });
      Utils.log("/admin/fill-contract response: ", response);
      return response.data;
    } catch (error) {
      Utils.log("/admin/fill-contract error: ", bookingCode);
      throw new Error("Could not submi downloadContract request for: " + bookingCode);
    }
  }

  static async changeVehicle(authToken, username, vehicle) {
    try {
      Utils.log("/vehicle/change-vehicle request: ", username, vehicle);
      let response = await axios.post(
          SERVER_URL + "/vehicle/change-vehicle", {vehicle: vehicle.toJson(), username, authorization: "Bearer " + authToken });
      Utils.log("/vehicle/change-vehicle response: ", response);
      return response;
    } catch (error) {
      Utils.log("/vehicle/change-vehicle error: ", error);
      throw new Error("Could not change vehicle " + vehicle.id);
    }
  }

  static async assignVehicle(authToken, bookingCode, vehicleId) {
    try {
      Utils.log("/admin/assign-vehicle request: ", bookingCode, vehicleId);
      let response = await axios.post(
          SERVER_URL + "/admin/assign-vehicle", { bookingCode, vehicleId, authorization: "Bearer " + authToken });
      Utils.log("/admin/assign-vehicle response: ", response);
      return response;
    } catch (error) {
      Utils.log("/admin/assign-vehicle error: ", error);
      throw new Error("Could not assign vehicle " + vehicleId + " to booking " + bookingCode);
    }
  }

  static async changeBookingState(authToken, bookingCode, newState) {
    try {
      Utils.log("/admin/change-booking-state request: ", bookingCode, newState);
      let response = await axios.post(
          SERVER_URL + "/admin/change-booking-state", { bookingCode, newState, authorization: "Bearer " + authToken });
      Utils.log("/admin/change-booking-state response: ", response);
      return response;
    } catch (error) {
      Utils.log("/admin/change-booking-state error: ", error);
      throw new Error("Could not change to state " + newState + " to booking " + bookingCode);
    }
  }

  static async submitListVehicleRequest(fields) {
     try {
      Utils.log("/vehicle/submit-list-vehicle-request request: ", fields);
      let response = await axios.post(SERVER_URL + "/vehicle/submit-list-vehicle-request", {fields});
      Utils.log("/vehicle/submit-list-vehicle-request response: ", response);
      return response;
    } catch (error) {
      Utils.log("/vehicle/submit-list-vehicle-request error: ", error);
      throw new Error("Could not submit List Vehicle request with fields: " + fields);
    }
  }
}
