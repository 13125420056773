import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import AlertDialog from "./AlertDialog";
import OutsidePopper from "./OutsidePopper";

import Api from "../Api";
import VehicleInfoContents from "../VehicleInfoContents";

import Utils from "../common/Utils";

const styles = theme => ({
  legend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }
});

const MIN_NIGHTS = 3;
const MAX_NIGHTS = 30;

function isBadRange(startDate, endDate, location, busyDays, hideBlockedDates) {
  if (!startDate || !endDate) {
    throw new Error("Both start and end must be defined");
  }

  //Utils.log("isGoodDate: ", startDate, endDate, endDate.clone().subtract(MIN_NIGHTS, "days"));

  // PAY ATTENTION: MUTATE THE END DATE!
  if (endDate < startDate.clone().add(MIN_NIGHTS, "days")) {
    return "Minimum booking period is " + MIN_NIGHTS + " nights";
  }
  if (endDate > startDate.clone().add(MAX_NIGHTS, "days")) {
    return "Maximum booking period is " + MAX_NIGHTS + " nights";
  }

  if (hideBlockedDates) {
    return null;
  }

  for (let date = startDate.clone(); date <= endDate; date = date.add(1, 'days')) {
    if (busyDays[location].hasOwnProperty(Utils.date2param(date))) {
      return "All vans are fully booked during your dates (" + Utils.prettyDate(startDate) + " to " + Utils.prettyDate(endDate) + ")";
    }
  }
  return null;
}

function emptyBusyDays() {
  let dict = {};
  for (let l in Utils.locations()) {
    dict[l] = [];
  }
  return dict;
}

function isPast(date) {
  date.startOf("day");
  const today = Utils.now().startOf("day");

  if (date.diff(today, "days") < 0) {
    return true;
  }

  return false;
}

function isTooSoon(date) {
  date.startOf("day");
  const today = Utils.now().startOf("day");

  if (date.diff(today, "days") <= 1) { // OUTSIDE of range: today and tomorrow.
    return true;
  }

  return false;
}

function isTooLate(date) {
  date.startOf("day");
  const today = Utils.now().startOf("day");

  if (date.diff(today, "days") > 365) {
    return true;
  }

  return false;
}

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.previousHistoryLength = 0;

    this.open = false;

    this.state = {
      startDate: props.booking.startDate,
      endDate: props.booking.endDate,
      focusedInput: props.focusedInput,
      onDatesChange: props.onDatesChange,
      onFocusChange: props.onFocusChange,
      busyDays: emptyBusyDays(),
      location: props.booking.location,
      vehicleType: props.booking.vehicleType,
    };

    //Utils.log("State: ", this.state);
  }

  generateInfoText() {
    if (this.state.focusedInput === "startDate") {
      if (this.state.endDate) {
        return "Select pick up date";
      } else {
        return "Select pick up & drop off";
      }
    } else if (this.state.focusedInput === "endDate") {
      if (this.state.startDate) {
        return "Select drop off date";
      } else {
        return "Select pick up & drop off";
      }
    }
  }

  isBadRange(start, end) {
    return isBadRange(start, end, this.state.location, this.state.busyDays, this.props.hideBlockedDates);
  }

  componentDidMount() {
    //Utils.log("componentDidMount");

    this.rebuildBusyDays(this.state.vehicleType, this.state.location);
  }

  rebuildBusyDays(vehicleType, location) {
    let vehicleTypes = vehicleType ? [vehicleType] : VehicleInfoContents.availableVehicles();
    Api.getAllBusyDays(vehicleTypes, this.props.previousBookingCode,
        busyDays => this.setState({busyDays}));
  }

  componentWillReceiveProps(props) {
    //Utils.log("componentWillReceiveProps");
    this.setState({
      focusedInput: props.focusedInput,
      booking: props.booking,
    });

    if (props.isHidden) {
      this.maybeSetOriginalState(props);
    }

    if (props.booking.location !== this.state.location ||
        props.booking.vehicleType !== this.state.vehicleType) {
      this.rebuildBusyDays(props.booking.vehicleType, props.booking.location);
    }
  }

  maybeSetOriginalState(props) {
    if (!this.open) {
      this.open = true;
      //Utils.log("Just opened Calendar ", Utils.date2param(props.booking.startDate), Utils.date2param(props.booking.endDate));
      this.originalStartDate = props.booking.startDate ? props.booking.startDate.clone() : null;
      this.originalEndDate = props.booking.endDate ? props.booking.endDate.clone() : null;
    }
  }

  isDayBlocked(date) {
    date.startOf("day");

    if (this.isOutsideRange(date)) {
      return false;
    }

    if (this.props.hideBlockedDates) {
      return false;
    }

    if (!this.state.busyDays.hasOwnProperty(this.state.location)) {
      return false;
    }
    const busyDays = this.state.busyDays[this.state.location];

    return busyDays.hasOwnProperty(Utils.date2param(date));
  }

  isOutsideRange(date) {
    return isTooSoon(date) || isTooLate(date);
  }

  renderDayContents(date) {
    date.startOf("day");

    const dateParam = Utils.date2param(date);

    let className = "calendar-date" + (this.isDayBlocked(date) ? " calendar-blocked-date-full" : "");

    // See whether it is compatible with other date.
    if (this.state.focusedInput === "endDate" &&
        this.state.startDate &&
        this.state.startDate !== date &&
        (!this.state.endDate || this.state.endDate < date) &&
        !this.isOutsideRange(date) &&
        !this.isBadRange(this.state.startDate, date)) {
      className += " calendar-compatible";
    }
    if (this.props.isSingleDay &&
        this.state.focusedInput === "startDate" &&
        this.state.endDate &&
        this.state.endDate !== date &&
        (!this.state.startDate || this.state.startDate > date) &&
        !this.isOutsideRange(date) &&
        !this.isBadRange(date, this.state.endDate)) {
      className += " calendar-compatible";
    }

    const popperDate = this.state.prevPopperDate === dateParam ? null : dateParam;

    return <div className={className} onClick={(event) => this.setState({popperDate, prevPopperDate: null, popperAnchor: event.currentTarget})}>
      {date.format("DD")}
    </div>;
  }

  onDatesChange(startDate, endDate) {
    // Dates are set to noon. Instead, set then to midnight... otherwise comparisons with things coming from param2date will be fucked.
    if (startDate) {
      startDate.startOf("day");
    }
    if (endDate) {
      endDate.startOf("day");
    }

    //Utils.log("onDatesChangeParam: ", Utils.date2param(startDate) + " to " + Utils.date2param(endDate), this.state.focusedInput);
    //Utils.log("onDatesChangeState: ", Utils.date2param(this.state.startDate) + " to " + Utils.date2param(this.state.endDate), this.state.focusedInput);

    // NOTE:
    // We also do the onFocusChange work in this method -- since we can't get the correct dates in
    // onFocusChange, we ignore it when focus switches to null (otherwise we must close the calendar before
    // we know the dates are valid).

    // Don't reopen calendar on clear dates button.
    if (!startDate && !endDate) {
      this.setState({startDate: null, endDate: null, focusedInput: null});
      this.state.onDatesChange(null, null);
      this.state.onFocusChange(null);
      return;
    }

    // If no end date is selected, switch to selecting end date.
    if (!endDate) {
      this.setState({startDate, endDate: null, focusedInput: "endDate"});
      //this.state.onDatesChange(startDate, null);
      this.state.onFocusChange("endDate");
      return;
    }

    // If only the end date is selected, switch to selecting start date.
    if (!startDate) {
      this.setState({startDate: null, endDate: endDate, focusedInput: "startDate"});
      //this.state.onDatesChange(startDate, null);
      this.state.onFocusChange("startDate");
      return;
    }

    const alertText = this.isBadRange(startDate, endDate);
    if (alertText) {
      if (endDate > startDate) {
        this.setState({alertText});
      }

      //Utils.log("Range not good...");

      // Hey... if this range is not good but switching from start to end helps, do it!
      if (this.state.focusedInput === "startDate" &&
          this.state.startDate &&
          !this.props.isSingleDay &&
          !this.isBadRange(this.state.startDate, startDate)) {
        //Utils.log("Switching from start ", this.state.startDate, startDate);
        this.setState({endDate: startDate, focusedInput: null});
        this.state.onDatesChange(this.state.startDate, startDate);
        this.state.onFocusChange(null);
        this.open = false;
        return;
      }
      if (this.state.focusedInput === "endDate" &&
          this.state.endDate &&
          !this.props.isSingleDay &&
          !this.isBadRange(endDate, this.state.endDate)) {
        //Utils.log("Switching from end ", endDate, this.state.endDate);
        this.setState({startDate: endDate, focusedInput: null});
        this.state.onDatesChange(endDate, this.state.endDate);
        this.state.onFocusChange(null);
        this.open = false;
        return;
      }

      // Start from scratch.
      if (!this.props.isSingleDay) {
        this.setState({startDate: null, endDate: null});
        this.state.onFocusChange("startDate");
      }

      this.open = true;
      return;

      /*
      // If this is a start date, try removing the end date.
      // Example: 13-16 is selected, 5-8 is canceled. Select 1 should remove the end date.
      if (this.state.focusedInput === "startDate") {
        //Utils.log("Clearing end...");
        this.setState({startDate, endDate: null, focusedInput: "endDate"});
        //this.state.onDatesChange(startDate, null);
        this.state.onFocusChange("endDate");
        return;
      }

      // If this is an end date, select it as start date and try again with end date.
      // Example: 1-4 is selected, 5-8 is canceled. Select 1 again, and clicking 9 should reselect start date.
      if (this.state.focusedInput === "endDate") {
        //Utils.log("Position to start again...");
        this.setState({startDate: endDate, endDate: null, focusedInput: "endDate"});
        //this.state.onDatesChange(endDate, null);
        this.state.onFocusChange("endDate");
        return;
      }

      throw new Error("Unreachable");
      */
    }

    // If the range is good make sure end date is also selected.
    // Unless this calendar only picks the start / end date.
    if (this.state.focusedInput === "startDate" && !this.props.isSingleDay) {
      this.setState({startDate, endDate: null, focusedInput: "endDate"});
      //this.state.onDatesChange(startDate, null);
      this.state.onFocusChange("endDate");
      return;
    }

    //Utils.log("Good range selected!");

    //let newFocus = this.state.focusedInput === "startDate" ? "endDate" : null;
    this.setState({startDate, endDate, focusedInput: null});
    this.state.onDatesChange(startDate, endDate);
    this.state.onFocusChange(null);
    this.open = false;
  }

  onFocusChange(focusedInput) {
    //Utils.log("onFocusChange: ", focusedInput, this.state.focusedInput, Utils.date2param(this.state.startDate), Utils.date2param(this.state.endDate), this.open);

    // This only exists to open calendar when clicked on.
    if (!this.props.isHidden && !this.open) {
      this.maybeSetOriginalState(this.props);
      this.setState({focusedInput});
      this.state.onFocusChange(focusedInput);
    }

    // Fuck this method, as we don't know the current start and end dates. Swtich focusing in onDatesChange instead.
  }

  // NOTE: This seems to fire way too often when a blocked date was selected!
  // So make sure that dates are not set to a value that will be blocked!
  onClose() {
    //Utils.log("onClose: ", this.state.focusedInput, Utils.date2param(this.originalStartDate), Utils.date2param(this.originalEndDate));

    // TODO: Not exactly right but fix this! It fires only on end date selection and fucks everything up!
    this.open = false;

    // Revert to original params.
    this.setState({startDate: this.originalStartDate, endDate: this.originalEndDate, focusedInput: null});
    // No need to fire any date changes.
    //this.state.onDatesChange(this.originalStartDate, this.originalEndDate);
    this.state.onFocusChange(null);
  }

  renderCalendarInfo() {
    if (Utils.isMobile()) {
      // Not enough space. Don't render anything.
      return null;
    }

    return <div align="center" style={{paddingTop:20}}>
      {!this.props.hideBlockedDates &&
          <div className={this.props.classes.legend}>
            <div className={this.props.classes.legend}>
              <div style={{width: 10, height: 10, marginRight: 10}} className={"vehicle-calendar-unavailable"} />
              <Typography color="textSecondary"> Fully Booked</Typography>
            </div>
          </div>}
      {this.state.focusedInput ? <Typography color="error"><br/>{this.generateInfoText()}</Typography> : null}
    </div>;
  }

  renderPopper() {
    if (!this.state.popperDate) {
      return null;
    }

    const date = Utils.param2date(this.state.popperDate);
    let text = null;

    if (isTooSoon(date)) {
      text = Utils.prettyDateShort(date) + (isPast(date) ? " is past" : " is too soon");
    } else if (isTooLate(date)) {
      text = Utils.prettyDate(date) + " is too late";
    } else if (this.isDayBlocked(date)) {
      text = Utils.prettyDateShort(date) + " is fully booked";
    } else {
      return null;
    }

    return <OutsidePopper
      id="simple-popper"
      open={Boolean(this.state.popperDate)}
      anchorEl={this.state.popperAnchor}
      onClose={() => this.setState({popperDate: null, prevPopperDate: this.state.popperDate, popperAnchor: null})}
    >
      <div><Typography color="textSecondary">{text}</Typography></div>
      {null}
    </OutsidePopper>;
  }

  render() {
    // https://github.com/airbnb/react-dates
    // 
    // See examples at airbnb storybook:
    // http://airbnb.io/react-dates
    // 
    // Code example:
    // https://github.com/airbnb/react-dates/blob/d9660019c8043349359f1fcf849f23cddbd083e3/stories/DateRangePicker_day.js
    // 
    // CSS:
    // https://github.com/airbnb/react-dates/blob/d9660019c8043349359f1fcf849f23cddbd083e3/css/storybook.scss
    return <div style={this.props.isHidden ? {"display": "none"} : {}}>
        <div style={{width: "100%"}}>
        <AlertDialog open={Boolean(this.state.alertText)} onClose={() => this.setState({alertText: null})} text={this.state.alertText} />
        <DateRangePicker
          startDateId="startDate"
          endDateId="endDate"
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.onFocusChange(focusedInput)}
          onClose={() => this.onClose()}
          minimumNights={0}

          isDayBlocked={date => this.isDayBlocked(date)}
          isOutsideRange={date => this.isOutsideRange(date)}
          renderDayContents={date => this.renderDayContents(date)}

          calendarInfoPosition="top"
          renderCalendarInfo={() => this.renderCalendarInfo()}

          showClearDates={true}
          small={Utils.isMobile()}

          /* Without portal is basically broken with position: relative and opacify elements underneath */
          withPortal={Utils.isMobile() ? false : true}
          withFullScreenPortal={Utils.isMobile() ? true : false}

          orientation={Utils.isMobile() ? "vertical" : "horizontal"}
        />
        </div>
        {this.renderPopper()}
      </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Calendar));
