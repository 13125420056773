import Utils from "./Utils";

export default class Order {
  // Does not copy!
  static pricingFromJson(json) {
    let pricing = json;

    // TODO: Include composed paths in param2dateJson.
    if (pricing.vehiclePrices) {
      for (let price of pricing.vehiclePrices) {
        for (let i in price.dates) {
          price.dates[i] = Utils.param2date(price.dates[i]);
        }
      }
    }
    return pricing;
  }

  // Does not copy!
  static pricingToJson(pricing) {
    let json = pricing;

    if (json.vehiclePrices) {
      for (let price of json.vehiclePrices) {
        for (let i in price.dates) {
          price.dates[i] = Utils.date2param(price.dates[i]);
        }
      }
    }
    return json;
  }

  // Does not copy!
  static penaltiesFromJson(json) {
    for (let penalty of json) {
      penalty.date = Utils.param2date(penalty.date);
    }
    return json;
  }

  // Does not copy!
  static penaltiesToJson(dailyPenalties) {
    for (let penalty of dailyPenalties) {
      penalty.date = Utils.date2param(penalty.date);
    }
    return dailyPenalties;
  }

  static fromJson(json) {
    let order = Utils.deepCopyJson(json);
    if (order.info && order.info.pricing) {
      order.info.pricing = Order.pricingFromJson(order.info.pricing);
    }

    if (order.info && order.info.cart && order.info.cart.items) {
      for (let item of order.info.cart.items) {
        if (item.dailyPenalties) {
          item.dailyPenalties = Order.penaltiesFromJson(item.dailyPenalties);
        }
      }
    }

    return Utils.param2dateJson(order, json, ["created", "updated"]);
  }

  // Minus booking, previousBooking, previousOrder!
  toJson() {
    let tmpBooking = this.booking;
    this.booking = undefined;

    let tmpPreviousOrder = this.previousOrder;
    this.previousOrder = undefined;

    let tmpPreviousBooking = this.previousBooking;
    this.previousBooking = undefined;

    let json = Utils.deepCopyJson(this);

    this.booking = tmpBooking;
    this.previousOrder = tmpPreviousOrder;
    this.previousBooking = tmpPreviousBooking;

    if (json.info && json.info.pricing) {
      json.info.pricing = Order.pricingToJson(json.info.pricing);
    }

    if (json.info && json.info.cart && json.info.cart.items) {
      for (let item of json.info.cart.items) {
        if (item.dailyPenalties) {
          item.dailyPenalties = Order.penaltiesToJson(item.dailyPenalties);
        }
      }
    }

    return Utils.date2paramJson(json, ["created", "updated"]);
  }

  constructor(json, booking, previousOrder, previousBooking) {
    Object.assign(this, Order.fromJson(json));
    this.booking = booking;
    this.previousOrder = previousOrder;
    this.previousBooking = previousBooking;
  }

  // Minus booking, previousBooking, previousOrder!
  clone() {
    return new Order(this.toJson(), this.booking, this.previousOrder, this.previousBooking);
  }

  // Including booking, previousBooking, previousOrder!
  deepCopy() {
    return Order.fromJson(this.toJson());
  }

  datePriceForRefund(date) {
    for (let price of this.info.pricing.vehiclePrices) {
      if (price.dates.findIndex(elem => date.isSame(elem)) > -1) {
        return price.price;
      }
    }
    throw new Error(
        "Order %cid=" + this.id + " date not found in order (" + this.booking.printDateRange() + "): " +
        Utils.date2param(date), "color:blue");
  }

  computePriceMap() {
    let priceMap = {};
    for (let pricing of this.info.pricing.vehiclePrices) {
      for (let date of pricing.dates) {
        priceMap[Utils.date2param(date)] = parseInt(pricing.price, 10 /* base */);
      }
    }
    return priceMap;
  }

  vehiclePrice(skipDiscount) {
    if (this.numDays() === 0 || this.booking.info.state === "CANCELED") {
      return 0;
    }

    let total = 0;
    let priceMap = this.computePriceMap();
    for (let date = this.booking.startDate.clone(); date < this.booking.endDate; date = date.add(1, 'days')) {
      total += priceMap[Utils.date2param(date)];
    }

    if (skipDiscount || !this.info.pricing.discount || !this.info.pricing.discount.rate) {
      return total;
    } else {
      return total * (1 - this.info.pricing.discount.rate);
    }
  }

  // TODO: test.
  // TODO: Return an array of penalty items, one for each change.
  static createPenaltyOrderItem(booking, order, previousBooking, previousOrder, excludePrevious) {
    let nullOrderItem = {
      name: "LATE_CHANGE_PENALTY",
      price: 0,
      count: 1,
      dailyPenalties: [],
    };

    // No previous order.
    if (!previousOrder || !previousBooking) {
      let currentOrderItem =
          order.info.cart && order.info.cart.items && order.info.cart.items.find(elem => elem.name === "LATE_CHANGE_PENALTY");
      if (currentOrderItem) {
        return currentOrderItem;
      } else {
        return nullOrderItem;
      }
    }

    let now = Utils.now();

    let previousOrderIsFirst = previousOrder.info.cart.previousTotal === 0;
    let creationTime = previousOrder.created;
    let gracePeriodDeadline = Utils.computeGracePeriodDeadline(creationTime, booking.startDate);

    // TODO: Restore grace period after we're done testing!
    gracePeriodDeadline = 0;

    // All changes are free during grace period.
    // TODO: Add a message back to user if we are in grace period!
    if (previousOrderIsFirst && now < gracePeriodDeadline) {
      Utils.log("Order %cid=" + order.id + " generatePenaltyOrderItem: order is within grace period");
      return nullOrderItem;
    }

    let dailyPenalties = [];

    let penaltyTotal = 0;

    let oldPenalty = previousOrder.info.cart.items.find(elem => elem.name === "LATE_CHANGE_PENALTY");
    if (oldPenalty && oldPenalty.dailyPenalties && !excludePrevious) {
      penaltyTotal += oldPenalty.price;
      dailyPenalties = dailyPenalties.concat(oldPenalty.dailyPenalties);
    }

    for (let date = previousBooking.startDate.clone(); date < previousBooking.endDate; date = date.add(1, 'days')) {

      let oldPrice = previousOrder.datePriceForRefund(date);

      let rate = Utils.computeCancelPenaltyRate(date, now);
      if (!rate) {
        // No penalty!
        continue;
      }

      let dailyPenalty = {
        date: date.clone(),
        rate,
        old : {
          name: previousBooking.vehicleType,
          price: oldPrice,
        },
      };

      let newPrice = 0;
      // If old date is still in new range, and new price is not lower than before, ignore.
      // Old date is still in new range.
      if (date >= booking.startDate && date < booking.endDate) {
        if (booking.info.state === "CANCELED" && previousBooking.state !== "CANCELED") {
          // Booking was CANCELED! New price should be 0.
          continue;
        }
        if (booking.vehicleType === previousBooking.vehicleType) {
          // Vehicle type did not change, ignore -- new price should be 0.
          continue;
        }
        newPrice = order.datePriceForRefund(date);

        if (newPrice >= dailyPenalty.old.price) {
          // New price is higher, do nothing.
          continue;
        }

        dailyPenalty.new = {
          name: booking.vehicleType,
          price: newPrice,
        };
      }

      penaltyTotal += (oldPrice - newPrice) * rate;

      dailyPenalties.push(dailyPenalty);
    }

    let orderItem = {
      name: "LATE_CHANGE_PENALTY",
      price: penaltyTotal,
      count: 1,
      dailyPenalties,
    };

    //Utils.log("Order %cid=" + order.id + " generatePenaltyOrderItem: ", "color:blue", orderItem);
    return orderItem;
  }

  numDays() {
    return this.booking.numDays();
  }

  computeMilesPrice() {
    return this.info.pricing.extrasPrices.dailyPriceUnlimitedMiles * this.numDays();
  }

  computeMilesCount() {
    return this.info.pricing.extrasPrices.dailyFreeMiles * this.numDays();
  }

  computeLiabilityPrice() {
    return this.info.pricing.extrasPrices.dailyPriceLiability * this.numDays();
  }

  computeCdwPrice() {
    return this.info.pricing.extrasPrices.dailyPriceCdw * this.numDays();
  }

  computeRoadsidePrice() {
    return this.info.pricing.extrasPrices.dailyPriceRoadside * this.numDays();
  }

  getAlreadyPaid() {
    if (this.previousOrder) {
      return this.previousOrder.computeTotal();
    } else if (this.info.cart) {
      return this.info.cart.previousTotal;
    } else {
      return 0;
    }
  }

  computeAllPenalties() {
    return Order.createPenaltyOrderItem(this.booking, this, this.previousBooking, this.previousOrder, false /* excludingPrevious */);
  }

  computeAllPenaltiesPrice() {
    let penalties = this.computeAllPenalties();
    return penalties ? penalties.price : 0;
  }

  computeCurrentPenalties() {
    return Order.createPenaltyOrderItem(this.booking, this, this.previousBooking, this.previousOrder, true /* excludingPrevious */);
  }

  computeCurrentPenaltiesPrice() {
    let penalties = this.computeCurrentPenalties();
    return penalties ? penalties.price : 0;
  }

  computeCurrentPenaltiesTax() {
    return Math.round(this.computeCurrentPenaltiesPrice() * Utils.taxRate(this.booking.location));
  }

  computeTotalBeforeTax() {
    // Jucy, Lost, Escape -- none of these tax insurance premiums.
    return this.computeTaxableTotal() +
        (this.booking.info.extras.includes("LIABILITY") ? this.computeLiabilityPrice() : 0) +
        (this.booking.info.extras.includes("CDW") ? this.computeCdwPrice() : 0) +
        (this.booking.info.extras.includes("ROADSIDE") ? this.computeRoadsidePrice() : 0);
  }

  computeTaxableTotal() {
    let penaltyPrice = this.computeAllPenaltiesPrice();

    return this.vehiclePrice() + penaltyPrice +
        (this.booking.info.extras.includes("UNLIMITED_MILES") ? this.computeMilesPrice() : 0);
  }

  computeTax() {
    return Math.round(this.computeTaxableTotal() * Utils.taxRate(this.booking.location));
  }

  computeTotal() {
    return this.computeTotalBeforeTax() + this.computeTax();
  }

  computeOwnerAmount() {
    if (this.booking.info.vehicle &&
        this.booking.info.vehicle.owner &&
        this.booking.info.vehicle.owner.info) {
      let cut = this.booking.info.vehicle.owner.info.cut;
      if (cut === undefined || cut === null) {
        cut = 0.6;  // Default cut.
      }
      return this.computeTaxableTotal() * cut;
    } else {
      return 0;
    }
  }

}
