import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";

const styles = theme => ({
  gallery: {
    maxWidth: 800,
    overflow: "hidden",
    width: "100%",
  },
  gallery2: {
    overflow: "hidden",
    width: "100%",
  },

  image: {
    objectFit: "cover",
    //minHeight: 200,
    maxHeight: 475,
    width: "100%",

    // https://stackoverflow.com/questions/8828215/css-a-2-pixel-line-appears-below-image-img-element
    verticalAlign: "bottom",
  },

  mobileStepper: {
    backgroundColor: "transparent",
  },
});

class PhotoGallery extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: (props.activeStep ? props.activeStep : 0),
    };
  }

  componentDidMount() {
    if (this.props.autoNextMs) {
      this.autoNext();
    }
  }

  async autoNext() {
    this.handleNext();
    await setTimeout(() => this.autoNext(), this.props.autoNextMs);
  }

  handleNext() {
    this.setState({ activeStep: (this.state.activeStep + 1) % this.props.photos.length });
  };

  handleBack() {
    this.setState({ activeStep: (this.state.activeStep + this.props.photos.length - 1) % this.props.photos.length });
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { classes } = this.props;

    return (
        <div className={this.props.fullSize ? classes.gallery2 : classes.gallery} style={{maxWitdh: 1000}}>
          <SwipeableViews
            index={this.state.activeStep}
            onChangeIndex={this.handleStepChange}
            onClick={() => this.handleNext()}
            enableMouseEvents
          >
          {this.props.photos.map(
              (x, i) => <div style={{witdh: "100%"}} align="center" key={i}>
                <img className={classes.image + " pointerOnHover"} src={x} alt={this.props.photos + " photo " + i} />
              </div>
          )}
          </SwipeableViews>
          <div style={{marginBottom:1}} />
          {this.props.autoNextMs ? "" : 
          <MobileStepper
            steps={this.props.photos.length}
            position="static"
            variant="dots"
            activeStep={this.state.activeStep}
            className={classes.mobileStepper}
            nextButton={
              <Button size="small" onClick={() => this.handleNext()}>
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={() => this.handleBack()}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />}
          </div>);
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(PhotoGallery));

