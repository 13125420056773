import moment from "moment";
import stableStringify from "json-stable-stringify";

import UtilsBase from "../UtilsBase";

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
function numberWithCommas(x) {
   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// https://stackoverflow.com/questions/32790311/how-to-structure-utility-class
export default class Utils {

  // https://stackoverflow.com/a/34228447
  static env() {
    switch (process.env.NODE_ENV) {
      case "":
      case "test":
      case "dev":
      case "development":
        return "dev";
      case "prod":
      case "production":
        return "prod";
      default:
        throw new Error("Invalid env: " + process.env.NODE_ENV);
    }
  }

  static isProd() {
    return Utils.env() === "prod";
  }

  static isDev() {
    return !Utils.isProd();
  }

  static isFrontend() {
    return UtilsBase.isFrontend();
  }

  static isBackend() {
    return UtilsBase.isBackend();
  }

  static log(...params) {
    if (Utils.isFrontend() && Utils.isProd()) {
      // Avoid logging in prod frontend! Not secure.
      // console.log(...params);
    } else {
      console.log(...params);
    }
  }

  static isAdmin(username) {
    return username === "admin@refreshcampers.com" || username === "straian@gmail.com";
  }

  // https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
  static async callWithDelay(ms, cb) {
    //Utils.log("Start await");
    await Utils.sleep(ms);
    //Utils.log("Await done");
    cb();
  }

  static obfuscateParams(str) {
    return btoa(str);
  }

  static deobfuscateParams(str) { 
    return atob(str);
  }

  static scramble(str) {
    const letters = str.split("");
    const len = letters.length - letters.length % 2;
    for (let i = 0; i < len / 4; i++) {
      let tmp = letters[2 * i];
      letters[2 * i] = letters[len - 2 * i - 1];
      letters[len - 2 * i - 1] = tmp;
    }
    return letters.join("");
  }

  static unscramble(str) {
    return Utils.scramble(str);
  }

  static taxRate(location) {
    // Sales Tax & Service Fee: Sometimes tax is lower, in that case it's all transformed into Service Fee. Same as Jucy.
    // https://www.boe.ca.gov/sutax/faqpurch.htm#3
    return 0.095;
    /*
    switch (location) {
      case "OAKLAND": return 0.0925;
      case "SAN_FRANCISCO": return 0.085;
      default: throw new Error("Unknown location: " + location);
    }
    */
  }

  static locations() {
    //return ["OAKLAND", "SAN_FRANCISCO"];
    return ["OAKLAND"];
  }

  static vehicleTypes() {
    return ["MINIVAN", "SMALL_VAN", "VINTAGE_VAN", "LARGE_VAN", "CLASS_C", "CLASS_B"];
  }

  // TODO: Remove (it's used in backend for emailer) or keep in sync with Api.js data
  static vehicleName(vehicleType) {
    switch(vehicleType) {
      case "MINIVAN": return "Mini Campervan";
      case "SMALL_VAN": return "Stealth Camper";
      case "VINTAGE_VAN": return "Volkswagen Classic";
      case "LARGE_VAN": return "Large Van";
      case "CLASS_B": return "Class B Camper";
      case "CLASS_C": return "Class C RV";
      default: throw new Error("Uknown vehicle type: " + vehicleType);
    }
  }

  static deepCopyJson(json) {
    return JSON.parse(JSON.stringify(json));
  }

  static updateImmutable(obj, json) {
    let newObj = Object.assign({}, obj);
    for (let key in json) {
      newObj[key] = json[key];
    }
    return newObj;
  }

  // TODO: test.
  static computeGracePeriodDeadline(bookTime, startDate, strict) {
    //Utils.log("computeGracePeriodDeadline: ", bookTime, startDate);
    let daysFromBookToStart = startDate.diff(bookTime.clone().startOf("day"), "days");

    // Example: assume booking starts on Sat, Aug 21.

    let deadline = null;
    //if (daysFromBookToStart < 7) {
      // On bookings done on Sun, Aug 15 or later -- up to 2 hours to cancel.
      //deadline = bookTime.clone().add(2, "hour");
    //} else if (daysFromBookToStart < 14) {
    if (daysFromBookToStart < 14) {
      // On bookings done on Sun, Aug 8 to Sat, Aug 14 -- up to 24 hours to cancel.
      deadline = bookTime.clone().add(1, "days");
    } else {
      // On bookings done on Sat, Aug 7 or before, you have until Sun, Aug 7 at 11:59pm.
      deadline = startDate.clone().subtract(13, "days");
      deadline = deadline.subtract(1, "minutes");
    }

    // Add one more hour on top of the displayed deadline.
    deadline = deadline.add(strict ? 0 : 1, "hour");

    //Utils.log("computeGracePeriodDeadline result: ", daysFromBookToStart, deadline);

    return deadline;
  }

  // TODO: test.
  static computeCancelPenaltyRate(date, now) {
    //Utils.log("computeCancelPenaltyRate: ", date, now);
    let daysLeft = date.clone().diff(now.clone().startOf("day"), "days");

    // Example: assume we want to cancel Sat, Aug 21.

    let rate = 0;
    if (daysLeft < 7) {
      // Canceling on Sun, Aug 15 or later -- 75% penalty rate.
      rate = 0.75;
    } else if (daysLeft < 14) {
      // Canceling on Sun, Aug 8 to Sat, Aug 14 -- 50% penalty rate.
      rate = 0.5;
    } else if (daysLeft < 21) {
      // Canceling on Sun, Aug 1 to Sat, Aug 7 -- 25% penalty rate.
      rate = 0.25;
    } else {
      rate = 0;
    }

    //Utils.log("computeCancelPenaltyRate: ", daysLeft, rate);

    return rate;
  }

  static coords2array(coords) {
    if (!coords) {
      return null;
    }
    return [coords.lat, coords.lng];
  }
  static array2coords(array) {
    if (!array) {
      return null;
    }
    return {lat: array[0], lng: array[1]};
  }

  // Strings -> moments in objects.
  static param2timeJson(to, json, fields) {
    for (let field of fields) {
      if (json[field]) {
        to[field] = Utils.param2time(json[field]);
      }
    }
    return to;
  }
  static param2dateJson(to, json, fields) {
    for (let field of fields) {
      if (json[field]) {
        to[field] = Utils.param2date(json[field]);
      }
    }
    return to;
  }

  // moments -> Strings in objects.
  static time2paramJson(json, fields) {
    for (let field of fields) {
      if (json[field]) {
        json[field] = Utils.time2param(json[field]);
      }
    }
    return json;
  }
  static date2paramJson(json, fields) {
    for (let field of fields) {
      if (json[field]) {
        json[field] = Utils.date2param(json[field]);
      }
    }
    return json;
  }

  static withLog(promise, name) {
    return promise
        .then(result => {
          Utils.log(name + " result: ", result);
          return result;
        })
        .catch(error => {
          Utils.log(name + " error: ", error);
          throw error;
        });
  }

  static prettyMoneyAndDays(amount, days) {
    return Utils.prettyMoney(amount) + " x " + Utils.prettyNumDays(days);
  }

  static prettyMoney(amount, skipDollarSign, forceDecimals) {
    return (skipDollarSign ? "" : "$") + numberWithCommas(Utils.withTwoDecimals(amount / 100, 2, forceDecimals));
  }

  static withTwoDecimals(number, round = 2, forceDecimals) {
    if (forceDecimals || (Math.floor(number * 100)) % 100) {
      return number.toFixed(round);
    } else {
      return number.toFixed(0);
    }
  }

  static rate2percent(rate, round = 2) {
    return Utils.withTwoDecimals(rate * 100, round) + "%";
  }

  static prettyNumDays(numDays) {
    return numDays + (numDays === 1 ? " day" : " days");
  }

  static prettyDate(date) {
    return date.format(this.now().year() === date.year() ? "ddd MMM Do" : "MMM Do YYYY");
  }

  static prettyDateShort(date) {
    return date.format("MMM Do");
  }

  static prettyTime(date) {
    const diffDays = date.clone().startOf("day").diff(moment().startOf("day"), "days");

    switch (diffDays) {
      case -1: return "yesterday, " + date.format("hh:mm A");
      case 0: return date.format("hh:mm A");
      case 1: return "tomorrow, " + date.format("hh:mm A");
      default: return date.format("MMM Do" + (this.now().year() === date.year() ? "" : " YYYY") + ", hh:mm A");
    }
  }

  static prettyMiles(x) {
    if (x === null || x === undefined) {
      return "??? mi";
    }
    x = x.toFixed(0);
    // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " mi";
  }

  static prettyMinutes(diffMinutes) {
    const minutes = diffMinutes % 60;
    const hours = Math.floor(diffMinutes / 60) % 24;
    const days = Math.floor(diffMinutes / 60 / 24);

    let str = (minutes ? minutes + " minutes" : "");
    str = (hours ? hours + " hours" + (str ? ", " : "") : "") + str;
    str = (days ? days + " days" + (str ? ", " : "") : "") + str;
    return str;
  }

  static prettyField(string) {
    return Utils.capitalizeFirstLetter(string.replace(/([A-Z])/g, ' $1').trim());
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static now() {
    return moment();
  }

  static time2param(date) {
    if (!date) {
      return null;
    }
    // https://momentjs.com/docs/#/parsing/special-formats/
    if (date instanceof moment) {
      return date.format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
    } else {
      return Utils.param2date(date).format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
    }
  }
  static date2param(date) {
    if (!date) {
      return null;
    }
    if (date instanceof moment) {
      return date.format("YYYY-MM-DD");
    } else {
      return Utils.param2date(date).format("YYYY-MM-DD");
    }
  }
  static param2time(param) {
    if (param instanceof moment) {
    }
    return param ? moment(param) : null;
  }
  static param2date(param) {
    if (param instanceof moment) {
    }
    return param ? moment(param) : null;
  }

  static interval2array(startDate, endDate) {
    let days = [];
    for (let date = startDate.clone(); date <= endDate; date = date.add(1, "day")) {
      days.push(date.clone());
    }
    return days;
  }

  static assert(condition, message) {
    if (!condition) {
      throw new Error("Assertion failed: " + message);
    }
  }

  static assertDeepEquals(a, b, message) {
    return Utils.assertEquals(stableStringify(a), stableStringify(b), message);
  }

  static assertEquals(a, b, message) {
    if (a !== b) {
      throw new Error("Equal assertion failed with message: " + message + ": " + a + "\n vs " + b);
    }
  }

  static deepEquals(obj1, obj2) {
    return stableStringify(obj1) === stableStringify(obj2);
  }

  static error(message) {
    throw new Error("Error: " + message);
  }

  // [1, 3] overlaps with [3, 6].
  static overlaps(a, b) {
    let a0 = a[0];
    let b0 = b[0];
    let a1 = a[1];
    let b1 = b[1];

    return (a0 >= b0 && a0 <= b1) ||
           (a1 >= b0 && a1 <= b1) ||
           (b0 >= a0 && b0 <= a1) ||
           (b1 >= a0 && b1 <= a1);
  }

  static range2array(startDate, endDate) {
    const dates = [];
    for (let date = startDate.clone(); date <= endDate; date.add(1, "days")) {
      dates.push(date.clone());
    }
    return dates;
  }

  // https://stackoverflow.com/a/1349426
  // Hash size: one char is 34 options ~5 bits
  //
  // Recommended lengths:
  // http://everydayinternetstuff.com/2015/04/hash-collision-probability-calculator/
  // Booking codes: avoid collisions for active bookings
  // Est max 400 cars w/ 25 reservations each -> 10000 active bookings at once.
  // Prob of collission: 4 * 10^-8
  // Hash size in bits: 40 <- 8 digits
  // 
  // Confirm page tokens: avoid collisions for same day bookings
  // Est max 100 bookings per day (for 400 cars at average 1 reseravtion per 4 days)
  // Hash size in bits: 30
  // Prob of collision: 4 * 10^-9 (adds every day, over 3 years it's 4 * 10^-6)
  static generateId(len) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";  // Remove O and 0, they would suck to copy.
    for (var i = 0; i < len; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static getLocationAddress(vehicleLocation) {
    switch (vehicleLocation) {
      case "OAKLAND": return "6050 Lowell St Oakland, CA 94608";
      case "SAN_FRANCISCO": return "2020 Tutu St San Francisco, CA 94102";
      default: this.error("Unknown location: " + vehicleLocation);
    }
  }

  static getLocationMapsUrl(vehicleLocation) {
    switch (vehicleLocation) {
      case "OAKLAND": return "https://goo.gl/maps/H28eRtUwTm42";
      case "SAN_FRANCISCO": return "https://www.google.com/maps/search/?api=1&query=37.787969, -122.391819";
      default: this.error("Unknown location: " + vehicleLocation);
    }
  }

  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  static isValidEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  // https://stackoverflow.com/questions/160550/zip-code-us-postal-code-validation
  static isValidZip(zip) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
  }

  static isValidPhone(phone) {
    return /^[0-9a-zA-Z*#() -+,.]+$/.test(phone);
  }

  static isValidName(name) {
    if (!name) { // For undefined.
      return false;
    }

    return /^[a-z ,.'-]+$/i.test(name);
  }

  static isMediumScreen() {
    // Tablet in vertical mode.
    return !Utils.isMobile() && window.innerWidth < 1024;
  }

  // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
  // Plus :s/\\-/-/g (to avoid all the unnecessary escape warnings.
  static isMobile() {
    var check = false;
    (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  }
}
