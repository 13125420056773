import Utils from "./Utils";

export default class Vehicle {
  static fromJson(json) {
    const vehicle = Utils.deepCopyJson(json);

    // Add extra init fields, if they don't exist.
    if (!vehicle.info) {
      vehicle.info = {};
    }
    if (!vehicle.info.unavailablePeriods) {
      vehicle.info.unavailablePeriods = [];
    }

    for (let i = 0; i  < vehicle.info.unavailablePeriods.length; ++i) {
      vehicle.info.unavailablePeriods[i] = [
        Utils.param2date(vehicle.info.unavailablePeriods[i][0]),
        Utils.param2date(vehicle.info.unavailablePeriods[i][1]),
      ];
    }

    return vehicle;
  }

  toMime64() {
    return Utils.obfuscateParams(JSON.stringify(this.toJson()));
  }

  static fromMime64(str) {
    return new Vehicle(Vehicle.fromJson(JSON.parse(Utils.deobfuscateParams(str))));
  }

  toJson() {
    let tmpOwner = this.info.owner;
    this.info.owner = undefined;

    let json = Utils.deepCopyJson(this);

    this.info.owner = tmpOwner;

    for (let i = 0; i < json.info.unavailablePeriods.length; ++i) {
      json.info.unavailablePeriods[i] = [
        Utils.date2param(json.info.unavailablePeriods[i][0]),
        Utils.date2param(json.info.unavailablePeriods[i][1]),
      ];
    }

    return json;
  }

  constructor(json) {
    Object.assign(this, Vehicle.fromJson(json));
  }

  plate() {
    return this.info.plate + this.info.state;
  }
};
