import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Booking from "../common/Booking";
import Utils from "../common/Utils";

import OrderInfo from "../components/OrderInfo";
import OrderInfoAdmin from "../components/OrderInfoAdmin";
import VehicleEventInfo from "../components/VehicleEventInfo";

const styles = theme => ({
});

class VehicleReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
    };
  }

  renderTitle(text, children) {
    if (!children.length) {
      return null;
    }
    const notFirst = this.notFirst;
    this.notFirst = true;
    return <div key={text}>
      {notFirst && <hr style={{marginLeft:20, marginRight:20}} />}
      <Typography color="textSecondary" style={{margin:10, marginBottom:0}} variant={"subtitle1"} align="left"><b>{text}</b></Typography>
      <div>
        {children.map((x, i) =>
            <div key={i}>
              <div style={{padding:10, margin:10, marginTop: i === 0 ? 0 : 10, paddingTop: i === 0 ? 0 : 10}}>
                {x}
              </div>
              {(Utils.isMobile()) ? <br/> : (i === children.length - 1 ? null : <hr style={{marginLeft:20, marginRight:20}} />)}
            </div>)}
      </div>
    </div>;
  }

  renderOrder(order) {
    return <div key={order.id}>
      <OrderInfo order={order} isAdminView={this.props.isAdminView} link={true} />
      {this.props.isAdminView &&
          <OrderInfoAdmin order={order} isAdminView={this.props.isAdminView} orm={this.props.orm} onUpdate={this.props.onUpdate} authToken={this.props.authToken} />}
    </div>;
  }

  renderVehicleEvent(vehicleEvent) {
    return <div key={vehicleEvent.id}>
      <VehicleEventInfo vehicleEvent={vehicleEvent} isAdminView={this.props.isAdminView} orm={this.props.orm} />
    </div>;
  }

  render() {
    const filteredEvents = this.props.vehicleEvents.filter(x => ["MAINTENANCE", "REPAIRS"].includes(x.info.type));

    this.notFirst = false;
    let orderInfos = [];
    orderInfos.push(
        this.renderTitle(
            "Unassigned",
            this.props.orders
                .filter(x => !x.booking.info.vehicle)
                .filter(x => !x.booking.isCanceled())
                .sort((x, y) => x.booking.startDate - y.booking.startDate) // Upcoming first.
                .map(x => this.renderOrder(x))));
    orderInfos.push(
        this.renderTitle(
            Booking.prettyState("PREPARING"),
            this.props.orders
                .filter(x => x.booking.info.vehicle)
                .filter(x => x.booking.getState() === "PREPARING")
                .sort((x, y) => x.booking.endDate - y.booking.endDate) // Ending first.
                .map(x => this.renderOrder(x))));
    orderInfos.push(
        this.renderTitle(
            Booking.prettyState("FINISHED"),
            this.props.orders
                .filter(x => x.booking.getState() === "FINISHED")
                .sort((x, y) => x.booking.endDate - y.booking.endDate) // Ending first.
                .map(x => this.renderOrder(x))));
    orderInfos.push(
        this.renderTitle(
            Booking.prettyState("STARTED"),
            this.props.orders
                .filter(x => x.booking.info.vehicle)
                .filter(x => x.booking.getState() === "STARTED")
                .sort((x, y) => x.booking.endDate - y.booking.endDate) // Ending first.
                .map(x => this.renderOrder(x))));
    orderInfos.push(
        this.renderTitle(
            Booking.prettyState("CONFIRMED"),
            this.props.orders
                .filter(x => x.booking.info.vehicle)
                .filter(x => x.booking.getState() === "CONFIRMED")
                .sort((x, y) => x.booking.startDate - y.booking.startDate) // Upcoming first.
                .map(x => this.renderOrder(x))));
    orderInfos.push(
        this.renderTitle(
            Booking.prettyState("RETURNED"),
            this.props.orders
                .filter(x => x.booking.info.vehicle)
                .filter(x => x.booking.getState() === "RETURNED")
                .sort((x, y) => y.booking.endDate - x.booking.endDaate) // Most recently ended first.
                .map(x => this.renderOrder(x))));
    orderInfos.push(
        this.renderTitle(
            Booking.prettyState("CANCELED"),
            this.props.orders
                .filter(x => x.booking.getState() === "CANCELED")
                .sort((x, y) => y.booking.startDate - x.booking.startDate) // Most recently started first.
                .map(x => this.renderOrder(x))));
    if (!this.props.orders.length) {
      orderInfos.push(
        <Typography color="textSecondary" style={{margin:20}} variant={"subtitle1"} align="center">No bookings yet</Typography>);
    }

    let vehicleEventInfos = [];
    vehicleEventInfos.push(
        this.renderTitle("Upcoming",
            filteredEvents.filter(x => !x.isCompleted()).sort((x, y) => x.created - y.created).map(x => this.renderVehicleEvent(x)))); // Upcoming first.
    vehicleEventInfos.push(
        this.renderTitle("Completed",
            filteredEvents.filter(x => x.isCompleted()).sort((x, y) => y.created - x.created).map(x => this.renderVehicleEvent(x)))); // Most recent first.
    if (!filteredEvents.length) {
      vehicleEventInfos.push(
        <Typography color="textSecondary" style={{margin:20}} variant={"subtitle1"} align="center">No expenses yet</Typography>);
    }

    return <div>
      {this.props.type === "bookings" && orderInfos}
      {this.props.type === "expenses" && vehicleEventInfos}
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(VehicleReport);
