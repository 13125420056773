import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  popper: {
    background: theme.palette.background.standardHalf,
  },
});

// Component that alerts if you click outside of it
// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component/41581491
class OutsidePopper extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', event => this.handleClickOutside(event));
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', event => this.handleClickOutside(event));
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClose();
    }
  }

  render() {
    const { classes } = this.props;
    const nonNull = Boolean(this.props.children.find(x => Boolean(x)));

    return <Popper style={{zIndex:1}}
        id="simple-popper"
        open={this.props.open}
        anchorEl={this.props.anchorEl}
        onClose={this.props.onClose}
    >
      <div ref={node => this.wrapperRef = node}>
      {nonNull &&
        <Paper className={classes.popper} style={{color:"white", padding:10, maxWidth: 300}}>
          {this.props.children}
        </Paper>}
      </div>
    </Popper>;
  }
}

export default withStyles(styles, { withTheme: true })(OutsidePopper);
