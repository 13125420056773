import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";
import Driver from "../common/Driver";

import FileUploader from "./FileUploader";
import TextFieldValidated from "./TextFieldValidated";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

function isValidDate(value) {
  if (!value) {
    return false;
  }

  return value > "1900-01-01" && value < "2038-01-01";
}

function isValidDlNumber(dlNumber) {
  return /^[a-z0-9 -,.]+$/i.test(dlNumber);
}

class DriverEditor extends Component {
  constructor(props) {
    super(props);

    if (props.driver) {
      // Initial set up of first / last name.
      // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
      this.state = {
        countryRadio: props.driver.isInternational ? "non-us" : "us",

        values: {
          firstName: props.driver.firstName,
          lastName: props.driver.lastName,
          dateOfBirth: props.driver.dateOfBirth,
          dlAddress: props.driver.dlAddress,
          dlNumber: props.driver.dlNumber,
          dlState: props.driver.dlState,
          dlExpireDate: props.driver.dlExpireDate,

          insuranceCompany: props.driver.insuranceCompany,
          insurancePolicyNumber: props.driver.insurancePolicyNumber,
          insuranceAgent: props.driver.insuranceAgent,
          insurancePhone: props.driver.insurancePhone,

          isMainDriver: props.driver.isMainDriver,
          isApproved: props.driver.isApproved,

          noInsurance: !props.driver.insuranceFile,
          noIdp: !props.driver.idpFile,
        },
        touched: {},

        dlFile: props.driver.dlFile,
        dlBackFile: props.driver.dlBackFile,
        insuranceFile: props.driver.insuranceFile,
        passportFile: props.driver.passportFile,
        idpFile: props.driver.idpFile,
      };
    } else {
      this.state = {
        countryRadio: null,

        values: {},
        touched: {},

        noInsurance: false,
        noIdp: false,
      };
    }
  }

  handleDone() {
    this.props.onDone(new Driver({
      isInternational: this.state.countryRadio === "non-us",

      firstName: this.state.values.firstName,
      lastName: this.state.values.lastName,
      dateOfBirth: this.state.values.dateOfBirth,
      dlAddress: this.state.values.dlAddress,
      dlNumber: this.state.values.dlNumber,
      dlState: this.state.values.dlState,
      dlExpireDate: this.state.values.dlExpireDate,

      insuranceCompany: this.state.values.insuranceCompany,
      insurancePolicyNumber: this.state.values.insurancePolicyNumber,
      insuranceAgent: this.state.values.insuranceAgent,
      insurancePhone: this.state.values.insurancePhone,

      dlFile: this.state.dlFile,
      dlBackFile: this.state.dlBackFile,
      insuranceFile: this.state.noInsurance ? null : this.state.insuranceFile,
      passportFile: this.state.passportFile,
      idpFile: this.state.noIdp ? null : this.state.idpFile,

      isMainDriver: this.state.values.isMainDriver,
      isApproved: this.state.values.isApproved,
    }));
    return true;
  }

  isValid(field, validIfUnset) {
    let value = this.state.values[field];

    if (validIfUnset && !this.state.touched[field]) {
      // Not yet touched.
      return true;
    }

    switch (field) {
      case "firstName":
      case "lastName":
      case "dlState":
        return Utils.isValidName(value);

      case "dateOfBirth":
      case "dlExpireDate":
        return isValidDate(value);

      case "dlAddress":
        return Boolean(value);

      case "insuranceCompany":
      case "insuranceAgent":
        return !value || Utils.isValidName(value);

      case "insurancePolicyNumber":
      case "dlNumber":
        return isValidDlNumber(value);

      case "insurancePhone":
        return Utils.isValidPhone(value);

      default:
        throw new Error("Invalid field: " + field);
    }
  }

  handleChange(key, value) {
    const values = this.state.values;
    values[key] = value;
    const touched = this.state.touched;
    touched[key] = true;
    this.setState({values, touched});
  }

  renderTextField(id, label) {
    return <TextFieldValidated id={id} label={label} value={this.state.values[id]} isValid={() => this.isValid(id, true)} onChange={value => this.handleChange(id, value)} />;
  }

  renderCheckbox(id, label) {
    return <FormControlLabel
      control={
        <Checkbox
            color="primary"
            onChange={() => this.handleChange(id, !this.state.values[id])}
            checked={this.state.values[id]}
      />}
      label={label}
    />;
  }

  renderAdminArea() {
    if (!this.props.user || !Utils.isAdmin(this.props.user.preferred_username)) {
      return null;
    }

    return <div align="center">
      <Typography variant="h6">
        <br/>
        <br/>
        <b>ADMIN AREA</b>
        <br/>
        <br/>
      </Typography>

      {this.renderTextField("dateOfBirth", "Date Of Birth")}
      <br/>
      {this.renderTextField("dlNumber", "DL Number")}
      <br/>
      {this.renderTextField("dlState", "DL State")}
      <br/>
      {this.renderTextField("dlExpireDate", "DL Expire Date")}
      <br/>
      {this.renderTextField("dlAddress", "DL Address")}
      <br/>

      {this.renderTextField("insuranceCompany", "Insurance Company")}
      <br/>
      {this.renderTextField("insurancePolicyNumber", "Insurance Policy #")}
      <br/>
      {this.renderTextField("insuranceAgent", "Insurance Agent")}
      <br/>
      {this.renderTextField("insurancePhone", "Insurance Phone")}
      <br/>
      {this.renderCheckbox("isMainDriver", "Set As Main Driver")}
      <br/>
      {this.renderCheckbox("isApproved", "Approve This Driver")}
      <br/>
      <br/>
      <br/>
    </div>;
  }

  render() {
    //Utils.log("PROPS: ", this.props);
    //Utils.log("STATE: ", this.state);

    // https://stackoverflow.com/questions/1818249/form-with-no-action-and-where-enter-does-not-reload-page
    return <div>
      <form noValidate autoComplete="on"
            // eslint-disable-next-line no-script-url
            action="javascript:void(0);">
        <FormControlLabel
          control={
            <Radio
                color="primary"
                checked={this.state.countryRadio === "us"}
                onChange={event => this.setState({ countryRadio: event.target.value })}
                value="us"
                aria-label="US"
            />}
          label="US driver"
        />
        <FormControlLabel
          control={
            <Radio
                color="primary"
                checked={this.state.countryRadio === "non-us"}
                onChange={event => this.setState({ countryRadio: event.target.value })}
                value="non-us"
                aria-label="non-US"
            />}
          label={"International driver"}
        />

        {this.state.countryRadio &&
          <div>
            {this.renderTextField("firstName", "First Name")}
            {this.renderTextField("lastName", "Last Name")}
            <br/>

            <div style={{display:Utils.isMobile() ? null : "flex"}}>
              <FileUploader title={"Driver's License Front"} file={this.state.dlFile} onUpload={file => this.setState({dlFile: file})} />
              <FileUploader title={"Driver's License Back"} file={this.state.dlBackFile} onUpload={file => this.setState({dlBackFile: file})} />
            </div>
            <br/>

            <FileUploader title={"Proof of Insurance"} file={this.state.insuranceFile} disabled={this.state.noInsurance} onUpload={file => this.setState({insuranceFile: file})} />
            <br/>
          </div>}

        {this.state.countryRadio === "non-us" &&
          <div>
            <div style={{display:Utils.isMobile() ? null : "flex"}}>
              <FileUploader title={"International Driving Permit"} file={this.state.idpFile} disabled={this.state.noIdp} onUpload={file => this.setState({idpFile: file})} />
              <FileUploader title={"Passport"} file={this.state.passportFile} onUpload={file => this.setState({passportFile: file})} />
            </div>
            {this.renderCheckbox("noIdp", "I do not own an International Driving Permit")}
          </div>}

          {this.renderCheckbox(
              "noInsurance",
              "I am not covered by any car insurance policy" + (this.state.countryRadio === "non-us" ? " in my country of residence" : ""))}

        <br/>

        {this.renderAdminArea()}

        {this.state.countryRadio &&
            <Button variant="contained" color="primary"
                disabled={!this.isDoneEnabled()}
                type="submit"
                onClick={() => { return this.handleDone()}}>
              {this.props.driver ? "Update" : "Add"} Driver
            </Button>}
      </form>
      <br/>
    </div>;
  }

  isDoneEnabled() {
    if (!this.state.countryRadio) {
      return false;
    }

    if (!this.state.dlFile) {
      return false;
    }

    if (!this.state.dlBackFile) {
      return false;
    }

    if (!this.state.insuranceFile && !this.state.values["noInsurance"]) {
      return false;
    }

    if (this.state.countryRadio === "non-us") {
      if (!this.state.passportFile) {
        return false;
      }

      if (!this.state.idpFile && !this.state.values["noIdp"]) {
        return false;
      }
    }

    return true;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(DriverEditor));

