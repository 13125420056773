import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Utils from "../common/Utils";

import HocUtils from "../hoc/HocUtils";

import ExpPanel from "../components/ExpPanel";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: Utils.isMobile() ? "column" : "row",
    //justifyContent: "space-between",
  },

  // https://stackoverflow.com/questions/25066214/flexbox-not-giving-equal-width-to-elements
  subContainer: {
    flexBasis: Utils.isMobile() ? "100%" : "50%",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    //justifyContent: "space-between",
  },
});

const instructions = [
  // Bed & bedding, curtain, towels
  // Change tire
  // Jump start
  // Kitchen:
  //  - Water & soap
  //  - Water tanks
  //  - Stove
  //  - Containers
  // Fastrak
  // Refueling (pay before)
  // Automatic transmission
  // Misc: chargers, speaker, lantern, repair kit
  // Extra key

  [
    "What vans do you have available?",
    "As of 24th of June 2018, we offer one type of than: the Mini Campervan. We have plans offer for larger campervans in the future.",
  ],

  [
    "What comes included with the vans?",
    "We offer a list of items that every van comes with <a href='vehicles'>here</a>.",
  ],
];


const travelTips = [
  // Why no toilet? How to toilet in the wild
  // Boondocking
  // National forest / NP parking
  // City parking

  // Common destinations (minimum days recommended):
  //  - 3-4 days: Santa Cruz Monterey, Big Sur. Or Mendocino or short trip to Tahoe or Yosemite.
  //  - 5-6 days: Redwoods NP, Mammoth / June Lakes, Sequoia NP, Santa Barbara / LA.
  //  - 7-9 days: LA or San Diego, via Pacific Highway (Highway 1) or visiting Yosemite / King's Canyon / Sequoia NP.
  //  - 10-14 days: Nevada trip: Yosemite, Highway 395, Death Valley, Vegas, LA.
  //  - 14 days or more: all the way to Grand Canyon. Possible detours via Zion, Bryce, Antelope.

  [
    "Why would I want to rent a camper van?",
    [
      "Whether you need to disconnect for a weekend, tour the West Coast for a week or take a month off work to rediscover the outdoors -- and yoursel --  exploring nature in a campervan in a radically liberating activity.",
      "Stop worrying about booking hotels far in advance, or adhering to a strict day to day schedule. Adapt, play it by ear, and enjoy life.",
    ],
  ],

  [
    "Why should I choose Refresh Campers?",
    "Refresh Campers is a new company that focuses on two things: convenience and price. We have the fastest booking and check-in process and we include everything you need for a great camping trip, at the lowest possible price.",
  ],


  [
    "Where can I camp?",
    "There are two options: paid camping (all our vans fit in both regular campground sites and RV sites) and dispersed camping. TODO: A lot more details about dispersed camping.",
  ],

  [
    "Do these campers include a toilet?",
    "Bigger vans will have a toilet, but mini campervans don't have one. One can use campground facilities. For dispersed camping, guidelines indicate a minimum distance of 200 feet from camp sites, trails, or water sources (more info <a href='https://lnt.org/learn/7-principles'>here</a>). During the day, gas stations and restaurants are very convenient.",
  ],
];

// WARNING: dangerouslySetInnerHTML IS INVOKED ON THESE STRINGS! DO NOT ADD ANYTHING EXCEPT BASIC <a> TAGS!
const faq = [
  [
    "Where is your office?",
    "To keep our costs low, we don't have an office. We keep our cars in multiple garages around Bay Area. Our contact information can be found <a href='contact'>here</a>.",
  ],
];

class Support extends Component {
  render() {
    const { classes } = this.props;
    return <div className={classes.container}>
      <div className={classes.subContainer}>
        <ExpPanel title="Faq" contents={faq} />;
        <ExpPanel title="Faq" contents={travelTips} />;
        <ExpPanel title="Faq" contents={instructions} />;
        <ExpPanel title="Faq" contents={faq} />;
      </div>
      <div className={classes.subContainer}>
        <ExpPanel title="Faq" contents={faq} />;
        <ExpPanel title="Faq" contents={faq} />;
      </div>
    </div>;
  }
}

export default HocUtils.withRenter(withStyles(styles, { withTheme: true })(Support));

