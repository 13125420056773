import React from "react";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class TextFieldValidated extends React.Component {
  render() {
    const { classes, id, label, value, isValid, onChange } = this.props;

    const isMultiline = id === "dlAddress" || id === "streetAddress";
    const isWide = isMultiline || id === "name";
    const isDisabled = id === "state";

    // 2 more divs necessary to avoid outline (fieldset) and legend (small text label) misaligning when on top.
    return <div style={{display:"inline-flex", justifyContent:"center", width: (isWide ? "100%" : null), maxWidth: 425}}>
      <div align="left" style={{display:"inline-flex", width: "100%"}}>
        <TextField
          style={{width: "100%"}}
          variant="outlined"
          disabled={isDisabled}
          id={id}
          margin="dense"
          label={label + " *"}
          {...(isValid() ? {} : {error:true})}
          className={classes.textField}
          value={value}
          multiline={isMultiline}
          rows="3"
          onChange={event => onChange(event.target.value)}
        />
      </div>
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(TextFieldValidated);
