import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import Utils from "../common/Utils";

const styles = theme => ({
  table: {
    borderSpacing: 0,
  },

  square: {
    maxWidth: 400,
    maxHeight: 400,
  },
});

class Squares extends Component {
  renderItem(item, padding, paddingTop, paddingBottom) {
    return <td style={{padding, paddingTop, paddingBottom}} className={this.props.classes.square}>{item}</td>;
  }

  render() {
    let rows = [];

    if (Utils.isMobile()) {
      for (let i = 0; i < this.props.children.length; i++) {
        if (this.props.noPicsOnMobile && i % 2 === 0) {
          continue;
        }
        rows.push(<tr key={i}>{this.renderItem(this.props.children[i], 20, i % 2 ? 0 : 20, 20)}</tr>);
      }
    } else {
      for (let i = 0; i < this.props.children.length; i += 2) {
        if (i % 4) {
          rows.push(<tr key={i}>
            {this.renderItem(this.props.children[i + 1], 20, 20, 20)}
            {this.renderItem(this.props.children[i], 0, 0, 0)}
          </tr>);
        } else {
          rows.push(<tr key={i}>
            {this.renderItem(this.props.children[i], 0, 0, 0)}
            {this.renderItem(this.props.children[i + 1], 20, 20, 20)}
          </tr>);
        }
      }
    }

    return <table className={this.props.classes.table}>
      {Utils.isMobile()
      ? null
      : <colgroup>
          <col style={{width:"50%"}} />
          <col style={{width:"50%"}} />
        </colgroup>}
      <tbody>
        {rows}
      </tbody>
    </table>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Squares));
