import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import TextFieldValidated from "./TextFieldValidated";

import Utils from "../common/Utils";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class ContactEditor extends Component {
  constructor(props) {
    super(props);

    if (props.contact) {
      this.state = {
        values: props.contact,
        touched: {},
      };
    } else {
      this.state = {
        values: {
          phone: "",
          emergencyName: "",
          emergencyPhone: "",
          destination: "",
        },
        touched: {},
      };
    }
  }

  handleChange(key, value) {
    const values = this.state.values;
    values[key] = value;
    const touched = this.state.touched;
    touched[key] = true;
    this.setState({values, touched});
  }

  handleDone() {
    this.props.onDone(this.state.values);
    return true;
  }

  isValid(field, validIfUnset) {
    let value = this.state.values[field];

    if (validIfUnset && !this.state.touched[field]) {
      // Not yet touched.
      // Let them be red.
      //return true;
    }

    switch (field) {
      case "emergencyName":
        return Utils.isValidName(value);

      case "phone":
      case "emergencyPhone":
        return Utils.isValidPhone(value);

      case "destination":
        return Boolean(value);

      default:
        throw new Error("Invalid field: " + field);
    }
  }

  renderTextField(id, label) {
    return <TextFieldValidated id={id} label={label} value={this.state.values[id]} isValid={() => this.isValid(id, true)} onChange={value => this.handleChange(id, value)} />;
  }

  isDoneEnabled() {
    return this.isValid("phone") &&
        this.isValid("emergencyName") &&
        this.isValid("emergencyPhone") &&
        this.isValid("destination");
  }

  render() {
    // Utils.log("STATE: ", this.state);

    // https://stackoverflow.com/questions/1818249/form-with-no-action-and-where-enter-does-not-reload-page
    return <div style={{minWidth: 250}}>
      <form noValidate autoComplete="on"
            // eslint-disable-next-line no-script-url
            action="javascript:void(0);">

        <div>
          {this.renderTextField("phone", "Phone Number")}
          {this.renderTextField("destination", "Furthest Destination")}
        </div>

        <br/>
        <Typography variant="subtitle1">
          Emergency Contact
        </Typography>

        {this.renderTextField("emergencyName", "Emergency Name")}
        {this.renderTextField("emergencyPhone", "Emergency Phone")}
        <br/>
        <br/>

        <Button variant="contained" color="primary"
            disabled={!this.isDoneEnabled()}
            type="submit"
            onClick={() => { return this.handleDone()}}>
          Update
        </Button>
      </form>
      <br/>
    </div>;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(ContactEditor));

