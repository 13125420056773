import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom"

import LinearProgress from "@material-ui/core/LinearProgress";

const styles = theme => ({
});

class BookNow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forwardProgress: 0,
    };
  }


  componentDidMount() {
    /*
    this.timer = setInterval(() => {
      // Pulled to child class since if it's TripReport, I can't scroll around on the map with this on!
      this.setState({forwardProgress: (this.state.forwardProgress + 1) % 9});
    },
    400);
    */
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let progressValue = this.props.progress;
    if (progressValue > 0 && progressValue < 1) {
      progressValue = 97 * this.props.progress + this.state.forwardProgress / 3;
    } else {
      progressValue *= 100;
    }

    return <LinearProgress variant="determinate" value={progressValue} />
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(BookNow));
