export default class Conversion {

  static reportConversion(amount) {
    // Initialized in index.html
    window.gtag(
        'event', 'conversion', {
          'send_to': 'AW-793180189/-2-BCNzqpowBEJ3wm_oC',
          'value': amount / 100,
          'currency': 'USD',
          'transaction_id': '',
          'event_callback': null,
        });
  }
}
