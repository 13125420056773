import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Utils from "../common/Utils";

import VehicleSelector from "./VehicleSelector";

import Api from "../Api";

const styles = theme => ({
});

class OrderInfoAdmin extends Component {
  unassignVehicle() {
    delete this.props.order.booking.info.vehicle;
    delete this.props.order.booking.vehicle_id;

    Api.assignVehicle(this.props.authToken, this.props.order.booking.bookingCode, -1);

    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  assignVehicle(selectedVehicleId) {
    const vehicle = this.props.orm.vehicles.find(x => x.id === selectedVehicleId);
    if (vehicle) {
      this.props.order.booking.info.vehicle = vehicle;
      this.props.order.booking.vehicle_id = vehicle.id;

      // Note: This may fail if changes are done too frequently, as the temp tables don't have to update and backend validation fails.
      Api.assignVehicle(this.props.authToken, this.props.order.booking.bookingCode, vehicle.id)
          .then(() => {
            if (this.props.onUpdate) {
              this.props.onUpdate();
            }
          });
    }
  }

  availableVehicles(order) {
    let bookingPeriod = [order.booking.startDate, order.booking.endDate];
    let vehicles = this.props.orm.vehicles.filter(x => x.vehicleType === order.booking.vehicleType);
    let availableVehicles = [];
    for (let vehicle of vehicles) {
      if (vehicle.info.unavailablePeriods.find(x => Utils.overlaps(x, bookingPeriod))) {
        continue;
      }
      if (this.props.orm.bookings.find(x => x.info.vehicle_id === vehicle.id && Utils.overlaps([x.startDate, x.endDate], bookingPeriod))) {
        continue;
      }
      availableVehicles.push(vehicle);
    }

    return availableVehicles;
  }

  setBookingState(newState) {
    Api.changeBookingState(this.props.authToken, this.props.order.booking.bookingCode, newState)
        .then(() => {
          if (this.props.onUpdate) {
            this.props.onUpdate();
          }
        });
  }

  renderState() {
    const { booking } = this.props.order;

    return <div>
      <Typography variant="body1" color="textSecondary">
        State: {booking.getState()}
      </Typography>

      <div style={{display:"flex"}}>
        <Button variant="contained" disabled={!booking.prevState()} color="primary" style={{margin:10}}
            onClick={() => this.setBookingState(booking.prevState())}>
          prev: {booking.prevState()}
        </Button>

        <Button variant="contained" color="primary" disabled={!booking.nextState()} style={{margin:10}}
            onClick={() => this.setBookingState(booking.nextState())}>
          next: {booking.nextState()}
        </Button>
      </div>
    </div>;
  }

  downloadContract() {
    // https://stackoverflow.com/questions/34507160/how-can-i-handle-an-event-to-open-a-window-in-react-js
    Api.downloadContract(this.props.authToken, this.props.order.bookingCode)
        .then(result => { console.log("RRR: ", result); window.open(result.link); });
  }

  render() {
    const { order } = this.props;
    const vehicle = order.booking.info.vehicle;

    // Utils.log("OrderInfoAdmin: ", order);

    return <div align="left">
      <div>
        <Typography color="textSecondary">
          <br/>
          Vehicle Type: {Utils.vehicleName(order.booking.vehicleType)}
          <br/>
          Total: {Utils.prettyMoney(order.computeTotal())}
          <br/>
          <span className={"link"} onClick={() => this.downloadContract()}>DOWNLOAD CONTRACT</span>
          <br/>
        </Typography>
        {vehicle
          ? <Typography color="textSecondary">
              Username: <Link to={"/dashboard?user=" + vehicle.owner.username}>{vehicle.owner.username}</Link>
              <br/>
              Model: {vehicle.info.year} {vehicle.info.make} {vehicle.info.model}
              <br/>
              VIN: {vehicle.info.vin}
              <br/>
              License Plate: {vehicle.info.plate} {vehicle.info.state}
              <br/>
              <span className={"link"} onClick={() => this.unassignVehicle()}>Release from vehicle</span>
              <br/>
            </Typography>
          : <div style={{display:"flex"}}>
              <VehicleSelector selectedVehicle={null} autoSelect={false}
                               vehicles={this.availableVehicles(order)}
                               onSelect={selectedVehicle => this.assignVehicle(selectedVehicle.id)} />
            </div>}
        <Typography color="textSecondary">
          Booking Code: <Link to={"/book?bookingCode=" + order.bookingCode}>{order.bookingCode}</Link>
          <br/>
        </Typography>
        {this.renderState()}
      </div>
    </div>;
  }
}

export default withStyles(styles, { withTheme: true })(OrderInfoAdmin);
